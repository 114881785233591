import React, { Component } from 'react';
import Select from 'react-select';
import makeAnimated from 'react-select/animated';
import axios from "axios";
import ApiURL from "../../api/ApiURL";

class CoverageArea extends Component {
    constructor(props) {
        super(props);
        const animatedComponents = makeAnimated();
        this.state = {
            AnimatedComponents: animatedComponents,
            CoveragePostCodeData: [],
            AreaCodeData: [],
            FilteredCovPostCodeData: [],
            SelectedPostCodeValues: []
        }
    }


    componentDidMount() {
        //componentDidMount Event       
        window.scroll(0, 0);
        this.getAllProviderDataList();
    }

    getAllProviderDataList = () => {
        var _self = this;
        axios.get(ApiURL.GetAllProviderDataList).then(response => {
            let respData = response.data;
            if (respData.StatusCode === 200) {
                var dataResult = respData.DataResult;
                let covPostCodeData = dataResult.CoveragePostCodes;
                let areaData = this.getAreaDataFromCovPostCodes(covPostCodeData);
                let reactSelectAreaData = this.convertPostCodeAreaToReactSelectItems(areaData);

                _self.setState({
                    CoveragePostCodeData: covPostCodeData,
                    AreaCodeData: reactSelectAreaData,
                })
            }
        })
    }

    convertCoveragePostCodeToReactSelectItems = (covPostCodes) => {
        var myData = [{ value: 0, label: 'All' }];
        for (var i = 0; i < covPostCodes.length; i++) {
            myData.push({
                value: covPostCodes[i].PostcodeDistrictId,
                label: covPostCodes[i].PostCodeDistrict
            })
        }
        return myData;
    }

    convertPostCodeAreaToReactSelectItems = (postCodeAreas) => {
        var myData = [];
        for (var i = 1; i < postCodeAreas.length; i++) {
            myData.push({
                value: postCodeAreas[i].PostCodeArea,
                label: postCodeAreas[i].PostCodeArea
            })
        }
        return myData;
    }

    getAreaDataFromCovPostCodes = (array) => {
        const key = 'PostCodeArea';
        const arrayUniqueByKey = [...new Map(array.map(item =>
            [item[key], item])).values()];
        return arrayUniqueByKey;
    }

    onChangeAreaCode = (selected) => {
        let filteredCoverageData = [];
        let covPostCodes = this.state.CoveragePostCodeData;
        if (selected.length > 0) {
            selected.map(si => {
                let filterKey = si.value;
                filteredCoverageData = filteredCoverageData.concat(covPostCodes.filter((item) => item.PostCodeArea === filterKey));
            });
        }        
        let reactSelectFilteredCoverageData = this.convertCoveragePostCodeToReactSelectItems(filteredCoverageData);
        this.setState({ ...this.state, FilteredCovPostCodeData: reactSelectFilteredCoverageData })
    }

    onChangePostcode = (selected) => {
        var selectedItems = [];
        var coverageData = this.state.FilteredCovPostCodeData;
        if (selected.length > 0) {
            selectedItems = selected;
            //Check For All
            if (selected[selected.length - 1].value === 0) {
                selectedItems = coverageData.filter((item) => item.value !== 0);
            }
        }        
        let areaCodeIds=selectedItems.map((item) => item.value );
        this.props.handleChange(areaCodeIds);
        this.setState({
            SelectedPostCodeValues: selectedItems
        })
    }


    render() {
        return (
            <>
                <section className="provider-reg-sec">
                    <div className="container">
                        <div className="provider-reg-info">
                            <h3>Coverage Area</h3>
                            <p>Please mention the postcodes where you are looking to provide services.</p>
                        </div>
                        <div className="provider-reg-wrap">
                            <div className="row">
                                <div className="col-xl-12">

                                    <div className="form-floating mb-3">
                                        <h6 className="text-white">Area Code</h6>
                                        <Select
                                            name="areacode"
                                            closeMenuOnSelect={true}
                                            components={this.state.AnimatedComponents}
                                            options={this.state.AreaCodeData}
                                            isMulti
                                            onChange={this.onChangeAreaCode}
                                        />
                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-xl-12">
                                    <div className="form-floating mb-3">
                                        <h6 className="text-white">Out-Code</h6>
                                        <Select
                                            name="postcode"
                                            closeMenuOnSelect={true}
                                            components={this.state.AnimatedComponents}
                                            options={this.state.FilteredCovPostCodeData}
                                            isMulti
                                            onChange={this.onChangePostcode}
                                            value={this.state.SelectedPostCodeValues}
                                        />
                                    </div>
                                </div>
                            </div>
                            <div className="col-xl-12">
                                <div className="btn-submit-wrap d-flex justify-content-end">
                                    <input type="button" className="btn-frm-submit" value="Next" onClick={this.props.nextStep} />
                                </div>
                            </div>

                        </div>
                    </div>
                </section>
            </>
        );
    }
}

export default CoverageArea;