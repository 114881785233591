import React, { Component } from 'react';
import ApiURL from "../../api/ApiURL";
import { NavLink, Redirect } from 'react-router-dom';
import axios from "axios";
import cogoToast from 'cogo-toast';
import LocalStorageHelper from '../../LocalStorageHelper/LocalStorageHelper';

class SelectProvider extends Component {
    constructor(props) {
        super(props);
        this.state = {
            ProviderData: [],
            RedirectToLogin: false,
            redirectProviderProfile: false,
        }
    }

    componentDidMount() {

        var userData = JSON.parse(LocalStorageHelper.getUser());
        if (userData !== null && userData.UserId > 0) {
            var svcData = JSON.parse(LocalStorageHelper.getService());
            //console.log('checkUserAuth', userData);
            if (svcData !== null) {
                this.getProviderList(userData.UserId, svcData.ServiceId);
            }
        }
        else {
            let winlocation = window.location.pathname;
            console.log(window.location.href);
            LocalStorageHelper.SetLastRedirectPath(winlocation);
            this.setState({ RedirectToLogin: true })
        }
    }

    getProviderList = (userId, serviceId) => {
        var param = { UserId: userId, ServiceId: serviceId };
        axios.post(ApiURL.GetAllProvider, param).then(response => {
            let respData = response.data;
            if (respData.StatusCode === 200) {
                let dataResult = respData.DataResult;
                console.log(dataResult);
                this.setState({ ProviderData: dataResult })
            }
            else if (respData.StatusCode === 500) {
                cogoToast.error("Internal Server Error ! Please Try Again.", { position: 'bottom-center' });
            }

        }).catch(error => {
            cogoToast.error("Request Failed! Please Try Again.", { position: 'bottom-center' })
        })
    }

    selectProviderHandler = (proData) => {
        LocalStorageHelper.setProvider(JSON.stringify(proData));
        this.setState({ redirectProviderProfile: true })
    }

    pageRedirectToLogin = () => {
        if (this.state.RedirectToLogin === true) {
            return <Redirect to="/login" />
        }
    }

    redirectProviderProfilePage = () => {
        if (this.state.redirectProviderProfile === true) {
            let url = "/provider-profile";
            return <Redirect to={url} />
        }
    }

    render() {

        const sliderList = this.state.ProviderData;
        const MyView = sliderList.length > 0 &&
            (sliderList.map((sliderItem, i) => {
                let profileImage = sliderItem.ProfileImage !== '' ? sliderItem.ProfileImage : "p1.jpg";
                const providerImgURL = ApiURL.ProviderImageURL + "/" + profileImage;
                return <div className="col-lg-3 col-md-6 col-sm-12" key={i}
                    onClick={(e) => this.selectProviderHandler(sliderItem)}>
                    <div className="card-design pro">
                        <div className="card-img-box pro-img">
                            <img src={providerImgURL} alt="..." />
                        </div>
                        <div className="card-info pro-info">
                            <div className="rating">
                                <i className="bx bxs-star"></i>
                                <i className="bx bxs-star"></i>
                                <i className="bx bxs-star"></i>
                                <i className="bx bxs-star"></i>
                                <i className="bx bxs-star"></i>
                                <span>&nbsp; 4.9 </span>
                            </div>
                            <div className="person">{sliderItem.FullName}</div>
                            <div className="pro-card-price">{sliderItem.Rate.toFixed(2)} GBP</div>
                        </div>
                    </div>
                </div>
            })) 


        return (
            <>
                <section className="banner-inner">
                    <div className="container">
                        <nav aria-label="breadcrumb">
                            <ol className="breadcrumb">
                                <li className="breadcrumb-item"><NavLink to="/">Home</NavLink></li>
                                <li className="breadcrumb-item"><NavLink to="/wishlist">Wishlist</NavLink></li>
                                <li className="breadcrumb-item"><NavLink to="/service-delilvery-address">Address</NavLink></li>
                                <li className="breadcrumb-item active" aria-current="page">Provider</li>
                            </ol>
                        </nav>
                    </div>
                </section>               

                <section id="select-providers">
                    <div className="container">
                        <div className="heading">
                            <h3>Service <span>Providers</span></h3>
                            {
                                (this.state.ProviderData.length > 0) ?
                                    <h6 className="section-sub-title"><span>List of service provider(s)</span>, you may select</h6>
                                    :
                                    <h6 className="section-sub-title"><span>Sorry! List is empty.</span> No service provider(s) found.</h6>
                            }
                        </div>
                    </div>
                </section>

                <section className="provider">
                    <div className="container">
                        <div className="row">

                            {MyView}

                        </div>
                    </div>
                </section>
                {this.pageRedirectToLogin()}
                {this.redirectProviderProfilePage()}
            </>
        );
    }
}

export default SelectProvider;