import React, { Component } from 'react';
import { NavLink } from "react-router-dom";
import validation from "../../validation/validation";
import axios from 'axios';
import ApiURL from "../../api/ApiURL";
import cogoToast from 'cogo-toast';


class Contact extends Component {
    constructor(props) {
        super(props);
        this.state = {
            btn: "SEND",
            name: "",
            mobile: "",
            email: "",
            msg: ""
        }
    }

    onChangeHandler = (event) => {
        this.setState({
            [event.target.name]: event.target.value
        })
    }

    onFormSubmit = (event) => {
        event.preventDefault();
        var _self = this;
        var { name, mobile, email, msg } = _self.state;

        if (name.length === 0) {
            cogoToast.error('Name required', { position: 'bottom-center' });
        }
        else if (mobile.length === 0) {
            cogoToast.error('Mobile Number required', { position: 'bottom-center' });
        }
        else if (email.length === 0) {
            cogoToast.error('Email Id required', { position: 'bottom-center' });
        }
        else if (msg.length === 0) {
            cogoToast.error('Message required', { position: 'bottom-center' });
        }
        else if (!(validation.NameRegx).test(name)) {
            cogoToast.error('Invalid Name', { position: 'bottom-center' });
        }
        else if (!(validation.MobileRegx).test(mobile)) {
            cogoToast.error('Invalid Mobile Number', { position: 'bottom-center' });
        }
        else if (!(validation.EmailRegx).test(email)) {
            cogoToast.error('Invalid Email Id', { position: 'bottom-center' });
        }
        else {
            _self.setState({ ..._self.state, btn: 'Sending...' })
            let contactModel = {
                Name: name,
                Mobile: mobile,
                Email: email,
                Message: msg,
            };
            axios.post(ApiURL.ContactUs, contactModel).then(function (response) {
                if (response.status === 200) {
                    cogoToast.success("Customer enquiry details send! Our backend team will contact you soon.", { position: 'bottom-center' })
                    _self.resetFormData();
                }
                else {
                    cogoToast.error('Request Failed! Please try again', { position: 'bottom-center' })
                }

            }).catch(error => {
                cogoToast.error(error, { position: 'bottom-center' });
            })
        }
    }

    resetFormData = () => {
        this.setState({
            btn: "SEND",
            name: "",
            mobile: "",
            email: "",
            msg: ""
        })
    }

    render() {
        return (
            <>
                <section className="banner-inner">
                    <div className="container">
                        <nav aria-label="breadcrumb">
                            <ol className="breadcrumb">
                                <li className="breadcrumb-item"><NavLink to="/">Home</NavLink></li>
                                <li className="breadcrumb-item active" aria-current="page">Contact Us</li>
                            </ol>
                        </nav>
                    </div>
                </section>
                <section className="contact-wrap">
                    <div className="container">
                        <div className="row align-items-center">
                            <div className="col-xl-6">
                                <div className="map-wrap">
                                    {/* <iframe title="GoogleMap" className="GoogleMap map-area" src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2486.1651164038985!2d-0.9943882999999998!3d51.4551248!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x48769ba7719c075f%3A0xd6b57cd83f91b230!2s55%20Connaught%20Rd%2C%20Reading%20RG30%202UD%2C%20UK!5e0!3m2!1sen!2sin!4v1698392610282!5m2!1sen!2sin" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe> */}
                                    <iframe title="GoogleMap" className="GoogleMap map-area" src="https://www.google.com/maps/embed?pb=!1m16!1m12!1m3!1d1243.9058917112568!2d-0.9889786612508994!3d51.4248840154148!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!2m1!1s200%20Brook%20Drive%2C%20Green%20Park%2C%20Reading%2C%20UK%2C%20RG2%206UB!5e0!3m2!1sen!2sin!4v1706949042006!5m2!1sen!2sin" allowFullScreen="" loading="lazy" referrerPolicy="no-referrer-when-downgrade"></iframe>
                                </div>
                            </div>
                            <div className="col-xl-1"></div>
                            <div className="col-xl-5">
                                <div className='mt-4'>
                                    <h4>CONTACT US</h4>
                                    <div className="onboardForm">
                                        <input onChange={this.onChangeHandler} name="name" className="form-control input-text" type="text" placeholder="Your Name" value={this.state.name} />
                                        <input onChange={this.onChangeHandler} name="mobile" className="form-control input-text" type="text" placeholder="Mobile Number" value={this.state.mobile} />
                                        <input onChange={this.onChangeHandler} name="email" className="form-control input-text" type="text" placeholder="Email" value={this.state.email} />
                                        <textarea onChange={this.onChangeHandler} name="msg" className="form-control input-text" row={20} placeholder="Message" value={this.state.msg} />
                                        <button className="btn btn-success" onClick={this.onFormSubmit}>
                                            <i className='fa fa-mail-forward' /> &nbsp;
                                            {this.state.btn}
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </>
        );
    }
}

export default Contact;
