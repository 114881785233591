import React, { Component, Fragment } from 'react';
import { withRouter } from "react-router-dom";
import { Link, NavLink } from 'react-router-dom';
import 'bootstrap/dist/css/bootstrap.min.css';
import LocalStorageHelper from '../../../LocalStorageHelper/LocalStorageHelper';

class MainHeader extends Component {
    constructor(props) {
        super(props);
        this.state = {
            UserId: '',
            GuestId: '',
            DisplayName: '',
            Mobile: '',
            Email: '',
            LoggedIn: false
        }
    }

    componentDidMount() {
        var _self = this;
        /* Check User Authentication */
        var userData = JSON.parse(LocalStorageHelper.getUser());
        if (userData !== null) {
            let userLoggedInState = (userData.UserId > 0) ? true : false;
            _self.setState({
                ..._self.state,
                UserId: userData.UserId,
                GuestId: userData.GuestId,
                DisplayName: userData.DisplayName,
                Mobile: userData.Mobile,
                Email: userData.Email,
                LoggedIn: userLoggedInState
            });
        }

    }

    setGuestUserId = () => {
        var _self = this;
        var userData = JSON.parse(LocalStorageHelper.getUser());
        console.log(userData);
        if (userData !== null) {
            let userLoggedInState = (userData.UserId > 0) ? true : false;
            _self.setState({
                ..._self.state,
                UserId: userData.UserId,
                GuestId: userData.GuestId,
                LoggedIn: userLoggedInState
            }, () => {
                console.log(_self.state);
            })
        }
    }

    signOut = () => {
        LocalStorageHelper.removeGuestId();
        LocalStorageHelper.removeUser();
        LocalStorageHelper.removeService();
        LocalStorageHelper.removeProvider();
        LocalStorageHelper.removeBooking();
        LocalStorageHelper.removeLastRedirectPath();
        this.props.checkUserType('');
        this.props.history.push('/');
    }

    render() {

        var userLoggedInSection = <div></div>
        userLoggedInSection = (this.state.LoggedIn === true) ?
            (
                <div className="btn-group">
                    <button className="btn  btn-sm dropdown-toggle" type="button" data-bs-toggle="dropdown" aria-expanded="false" title="User Login Box">
                        <i className="fa fa-user-o"></i>
                    </button>
                    <ul className="dropdown-menu dropdown-main">
                        <span className="account-manage">This account is manage by MyBoogy</span>
                        <div className="drop-user-info d-flex align-items-center">
                            <div className="user-image-wrap">
                                <span><i className="fa fa-user-o"></i></span>
                            </div>
                            <div className="user-identity-wrap">
                                <h5>{this.state.DisplayName}</h5>
                                <h6>{this.state.Mobile}</h6>
                                <h6>{this.state.Email}</h6>
                            </div>
                        </div>
                        <ul className="list-group user-drop-list">
                            <li className="list-group-item"><i className="fa fa-cog"></i> <Link to={"/profile"} className="manage-txt">Manage Your Profile</Link></li>
                            <li className="list-group-item bdr-none"><i className="fa fa-sign-out" ></i> <Link to={"#"} onClick={this.signOut}>Sign Out</Link></li>
                        </ul>
                    </ul>
                </div>
            ) : <div className="login-btn"><NavLink to="/login">Login</NavLink></div>

        return (
            <Fragment>
                <header>
                    <div className="custom-navbar">
                        <div className="menu-wrap d-flex align-items-center">
                            <div className="brand"><NavLink to={"/provider-home"}>MyBoogy</NavLink></div>
                            <nav>
                                <ul>
                                    <li><Link to={"/provider-home"}><i className="fa fa-home"></i> Home</Link></li>
                                    {/* <li><Link to={"/provider-home"}>About us</Link></li> */}
                                </ul>
                            </nav>
                        </div>

                        <div className="nav-link-btn">
                            <div className="btn-group">
                                <button className="btn  btn-sm dropdown-toggle" type="button" data-bs-toggle="dropdown" aria-expanded="false">
                                    Actions
                                </button>
                                <ul className="dropdown-menu dropdown-main">
                                    <ul className="list-group user-drop-list">
                                        <li className="list-group-item"><i className="fa fa-hand-o-right"></i> <Link to={"/manage-work-area"} className="manage-txt">Manage Work Area</Link></li>
                                        <li className="list-group-item"><i className="fa fa-hand-o-right"></i> <Link to={"/manage-service"} className="manage-txt">Manage Service</Link></li>
                                        <li className="list-group-item"><i className="fa fa-hand-o-right"></i> <Link to={"/manage-booking-history"} className="manage-txt">Track Booking History</Link></li>
                                        <li className="list-group-item"><i className="fa fa-hand-o-right"></i> <Link to={"/provider-slot"} className="manage-txt">Publish Availability</Link></li>
                                        <li className="list-group-item"><i className="fa fa-hand-o-right"></i> <Link to={"/service-provider-rate"} className="manage-txt">Manage Service Rate</Link></li>
                                    </ul>
                                </ul>
                            </div>

                            {userLoggedInSection}

                            <div className="login-btn">
                                <Link to={"#"}>
                                    <i className="fa fa-envelope"></i>{/* <span>30</span> */}
                                </Link>
                            </div>
                        </div>
                        <div className="nav-btn"><i className='bx bx-menu-alt-right' ></i></div>

                    </div>
                </header>
            </Fragment >
        );
    }
}

export default withRouter(MainHeader);
