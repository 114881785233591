import React, { Component } from 'react';
import ApiURL from "../../api/ApiURL";
import { NavLink } from 'react-router-dom';

class MarketPlace extends Component {
    render() {
        const marketplaceImgURL = ApiURL.MarketplaceImageURL + "/services.svg";

        return (
            <>
                <section id="marketplace">
                    <div className="container">
                        <div className="row">
                            <div className="heading align-self-center pt-5">
                                <h2>Benefits</h2>
                                <p>There are a few interesting features that can help you be at top of things. Check out the benefits.</p>
                            </div>
                            <div className="col-lg-12 col-md-12">
                                <div className="row">
                                    <div className="col-lg-3 col-md-3 col-sm-6">
                                        <div className="commitment-box shadow light-violet-bg">
                                            <div className="commitment-icon violet-bg">
                                                <img src={marketplaceImgURL} alt="" />
                                            </div>
                                            <div className="commitment-info">
                                                <div className="title">Area Based Searches</div>
                                                <p>You can choose the service provider near you</p>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="col-lg-3 col-md-3 col-sm-6">
                                        <div className="commitment-box shadow light-magenta-bg">
                                            <div className="commitment-icon magenta-bg">
                                                <img src={marketplaceImgURL} alt="" />
                                            </div>
                                            <div className="commitment-info">
                                                <div className="title">Mark Attendance</div>
                                                <p>OTP regulated digital attendance daily</p>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="col-lg-3 col-md-3 col-sm-6">
                                        <div className="commitment-box shadow light-green-bg">
                                            <div className="commitment-icon green-bg">
                                                <img src={marketplaceImgURL} alt="" />
                                            </div>
                                            <div className="commitment-info">
                                                <div className="title">Share Feedback</div>
                                                <p>Ability to share feedback and rating the provider</p>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="col-lg-3 col-md-3 col-sm-6">
                                        <div className="commitment-box shadow light-pink-bg">
                                            <div className="commitment-icon pink-bg">
                                                <img src={marketplaceImgURL} alt="" />
                                            </div>
                                            <div className="commitment-info">
                                                <div className="title">Flexibility</div>
                                                <p>Book service for one day or one month</p>
                                            </div>
                                        </div>
                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </>
        );
    }
}

export default MarketPlace;