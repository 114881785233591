import React, { Component } from "react";
import FooterDesktop from '../components/common/FooterDesktop';
import MainHeader from '../components/common/MainHeader';
import VerifyOTP from "../components/OTPVerification/VerifyOTP";
import Legal from "../components/common/Legal";
import MobileHeader from "../components/common/MobileHeader";

class VerifyOTPPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      Mobile: props.match.params.mobileNo,
      checkUserType: props.checkUserType
    };
  }

  componentDidMount() {
    window.scroll(0, 0);
  }

  render() {
    return (
      <>

        <div className="desktop">
          <MainHeader />
        </div>
        <div className="mobile">
          <MobileHeader />
        </div>

        <VerifyOTP Mobile={this.state.Mobile} checkUserType={this.state.checkUserType} />

        <div className="desktop">
          <FooterDesktop />
        </div>

        <Legal />

      </>
    );
  }
}

export default VerifyOTPPage;
