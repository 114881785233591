import React, { Component } from 'react';
import SearchService from '../components/ServiceDetails/SearchService';
import axios from "axios";
import ApiURL from "../api/ApiURL";
import cogoToast from 'cogo-toast';
import Legal from '../components/common/Legal';

class SearchServicePage extends Component {
    constructor(props) {
        super(props);
        this.state = {
            ServiceData: []
        }
    }

    componentDidMount() {
        this.getPopularServices();
    }

    getPopularServices = () => {
        axios.get(ApiURL.GetPopularServices).then(response => {
            let respData = response.data;
            if (respData.StatusCode === 200) {
                let dataResult = respData.DataResult;
                console.log(dataResult);
                this.setState({
                    ServiceData: dataResult
                })
            }
            else if (respData.StatusCode === 500) {
                cogoToast.error("Internal Server Error ! Please Try Again.", { position: 'bottom-center' });
            }

        }).catch(error => {
            cogoToast.error("Request Failed! Please Try Again.", { position: 'bottom-center' })
        })
    }

    render() {
        return (
            <>
                <SearchService PopularServiceData={this.state.ServiceData} />
                
                <Legal />
            </>
        );
    }
}

export default SearchServicePage;