import React, { Component } from 'react';
import { Link, Redirect } from 'react-router-dom';
import ApiURL from "../../../api/ApiURL";
import { Swiper, SwiperSlide } from "swiper/react";
// import required modules
import { Autoplay, Pagination, Navigation } from "swiper";

// Import Swiper styles
import "swiper/swiper-bundle.min.css";
import "swiper/swiper.min.css";

class Banner extends Component {
    constructor(props) {
        super(props);
        this.state = {
            HomeBannerSliderData: [],
            RedirectLoginStatus: false
        }
    }

    componentDidMount() {
        const bannerDataList = [
            { ImageName: "1.jpg", Title: "banner-1" },
            { ImageName: "2.jpg", Title: "banner-2" },
            { ImageName: "3.jpg", Title: "banner-3" },
            { ImageName: "4.jpg", Title: "banner-4" },
            { ImageName: "5.jpg", Title: "banner-5" },
            { ImageName: "6.jpg", Title: "banner-6" },
            { ImageName: "7.jpg", Title: "banner-7" },
            { ImageName: "8.jpg", Title: "banner-8" }
        ];

        this.setState({ HomeBannerSliderData: bannerDataList })
    }

    RedirectToLogin = () => {
        if (this.state.RedirectLoginStatus === true) {
            return <Redirect exact to="/login" />
        }
    }

    render() {

        /* Home Banner Slider Data */
        const sliderList = this.state.HomeBannerSliderData;
        const MyView = sliderList.length > 0 ?
            (<Swiper
                spaceBetween={30}
                slidesPerView={1}
                navigation={false}
                pagination={{ clickable: true }}
                centeredSlides={true}
                autoplay={{ delay: 2500, disableOnInteraction: false }}
                /* onSwiper={(swiper) => console.log(swiper)}
                onSlideChange={() => console.log('slide change')} */
                modules={[Autoplay, Pagination, Navigation]}
                className="mySwiper"
            >
                {sliderList.map((sliderItem, i) => {
                    const sliderImgURL = ApiURL.HomeBannerImageURL + "/" + sliderItem.ImageName;
                    return (<SwiperSlide key={i}>
                        <img src={sliderImgURL} alt={sliderItem.Title} />
                    </SwiperSlide>)
                })}
            </Swiper>) :
            (<div></div>)


        return (
            <>
                <section className="banner-provider-panel">
                    <div className="container">
                        <div className="row">
                            <div className="col-xl-6">
                                <div className="banner-content">
                                    <h3>ONE-STOP SOLUTION </h3>
                                    <div className="banner-head">FOR YOUR <span>SERVICES</span></div>
                                    <form>
                                        <div className="popular-provider-services">Popular Services:</div>
                                        <div className="banner-provider-link">
                                            <Link to={"/service-by-category/Domestic%20Cleaning"} className="banner-btn">Domestic Cleaning</Link>
                                            <Link to={"/service-by-category/Domestic%20Cooking"} className="banner-btn">Domestic Cooking</Link>
                                            <Link to={"/service-by-category/Man%20with%20Van"} className="banner-btn">Man with Van</Link>
                                        </div>
                                    </form>
                                </div>
                            </div>
                            <div className="col-xl-6">
                                <div className="banner-slider-wrap">
                                    <div className="swiper mySwiper">
                                        <div className="swiper-wrapper">
                                            {MyView}
                                        </div>
                                        <div className="swiper-pagination"></div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                {/* {this.RedirectToLogin()} */}
            </>
        );
    }
}

export default Banner;