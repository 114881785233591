import React, { Component, Fragment } from "react";
import ApiURL from "../../api/ApiURL";
import axios from "axios";
import cogoToast from 'cogo-toast';
import MainHeader from "../../components/common/provider/MainHeader";
import Banner from "../../components/home/provider/Banner";
import MarketPlace from "../../components/home/provider/MarketPlace";
import ServicesWeOffer from "../../components/home/provider/ServicesWeOffer";
import MobileHeader from "../../components/common/MobileHeader";
//import MobileHeader from "../../components/common/provider/MobileHeader";
import FooterDesktop from "../../components/common/FooterDesktop";
import Legal from "../../components/common/Legal";

class HomePage extends Component {
  constructor(props) {
    console.log(props.checkUserType);
    super(props);
    this.state = {
      ServicesWeOffer: [],
      BestOffers: [],
      PopularServices: [],
      OurServices: [],
      checkUserType: props.checkUserType
    }
  }

  componentDidMount() {
    window.scroll(0, 0);
    this.getHomeServices();
  }

  getHomeServices = () => {
    axios.get(ApiURL.GetHomeServices).then(response => {
      let respData = response.data;
      if (respData.StatusCode === 200) {
        let dataResult = respData.DataResult;
        console.log(dataResult);
        this.setState({
          ServicesWeOffer: dataResult.ServicesWeOffer,
          BestOffers: dataResult.BestOffers,
          PopularServices: dataResult.PopularServices,
          OurServices: dataResult.OurServices,
        })
      }
      else if (respData.StatusCode === 500) {
        cogoToast.error("Internal Server Error ! Please Try Again.", { position: 'bottom-center' });
      }

    }).catch(error => {
      cogoToast.error("Request Failed! Please Try Again.", { position: 'bottom-center' })
    })
  }

  render() {
    return (
      <Fragment>
        <div className="desktop">
          <MainHeader checkUserType={this.state.checkUserType} />
        </div>
        <div className="mobile">
          <MobileHeader checkUserType={this.state.checkUserType}/>
        </div>

        <Banner />
        <ServicesWeOffer ServicesWeOffer={this.state.ServicesWeOffer} />        
        <MarketPlace />  

        <div className="desktop">
          <FooterDesktop />
        </div>
        
        <Legal />
      </Fragment>
    );
  }
}

export default HomePage;
