import React, { Component } from 'react';
import { NavLink, Link } from 'react-router-dom';

class Cancellation extends Component {
    render() {
        return (
            <>
                <section className="banner-inner">
                    <div className="container">
                        <nav aria-label="breadcrumb">
                            <ol className="breadcrumb">
                                <li className="breadcrumb-item"><NavLink to="/">Home</NavLink></li>
                                <li className="breadcrumb-item active" aria-current="page">Cancellations Policy</li>
                            </ol>
                        </nav>
                    </div>
                </section>
                <section className="site-container">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12 col-md-12 col-sm-12">
                                <div className="odr-confrm-wrap">
                                    <div style={{ marginBottom: "20px" }}>
                                        <div>
                                            <center>
                                                <h3><strong>Cancellation Policy</strong></h3>
                                            </center>
                                            <p>
                                                Time is precious for our service providers. Any cancellation can jeopardize their schedule, and by extension have a grave impact on their earnings.
                                                Any last minutes cancellation affects the daily plan majorly. Our cancellation policies are outlined keeping the interest of both customers and service providers.
                                                Please read our cancellations Policy carefully before using <Link to="https://www.myboogy.co.uk">https://www.myboogy.co.uk</Link> for booking a service through S2P Services Limited (‘MyBoogy”, we, us).
                                                These terms will tell you, how we provide services to our customers and what to do in case there is any problem and other important information
                                            </p>
                                        </div>
                                        <p>
                                            The cancellations Policy must be read along with the website terms and conditions, Booking policy, Data Privacy Policy.
                                        </p>
                                        <p>
                                            Please don’t proceed with booking If you disagree with any points mentioned on the cancellations policy.
                                        </p>
                                    </div>

                                    <div style={{ marginBottom: "20px" }}>
                                        <div style={{ textAlign: 'center', marginBottom: "40px" }}>
                                            <table className='table-bordered rwd-table'>
                                                <thead>
                                                    <tr>
                                                        <th className='col-md-4'>Cancellation Type</th>
                                                        <th className='col-md-3'>Time Scale</th>
                                                        <th className='col-md-2'>Charge</th>
                                                        <th className='col-md-3'>Remarks</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    <tr>
                                                        <td data-th="Cancellation Type">User - Customer</td>
                                                        <td data-th="Time Scale">N/A</td>
                                                        <td data-th="Charge">NIL</td>
                                                        <td data-th="Remarks">---</td>
                                                    </tr>
                                                    <tr>
                                                        <td data-th="Cancellation Type">User - Service Provider</td>
                                                        <td data-th="Time Scale">N/A</td>
                                                        <td data-th="Charge">NIL</td>
                                                        <td data-th="Remarks">---</td>
                                                    </tr>
                                                    <tr>
                                                        <td data-th="Cancellation Type">Booking Cancellation</td>
                                                        <td data-th="Time Scale">More than 1 day prior</td>
                                                        <td data-th="Charge">NIL</td>
                                                        <td data-th="Remarks">---</td>
                                                    </tr>
                                                    <tr>
                                                        <td data-th="Cancellation Type">Booking Cancellation</td>
                                                        <td data-th="Time Scale">Same Day</td>
                                                        <td data-th="Charge">100%</td>
                                                        <td data-th="Remarks">You will be billed</td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </div>
                                        <p>
                                            <span style={{ backgroundColor: 'green', color: '#fff' }}>
                                                In the event of booking cancellation same day- Invoice will be sent to your email id which needs to be cleared within 48 hours from the mail generation. We may pursue this legally in due course of time making further impact on your credit scores.
                                            </span>
                                        </p>
                                    </div>

                                    <div style={{ marginBottom: "20px" }}>
                                        <h5><strong>You have every right to move on: </strong></h5>
                                        <p>
                                            If you're ready to move on from us, terminating your contract is simple. All it takes is a moment's notice - simply stop using the Facilitation Services and prepare for life without them! If you have an account associated with our Website, contact customer service via email, phone  for further assistance.
                                        </p>
                                    </div>

                                    <div style={{ marginBottom: "20px" }}>
                                        <h6><strong>For any clarifications or enquiry you can contact us: </strong></h6>
                                        <div>
                                            <ul type='circle'>
                                                <li>By email at - contact-us@myboogy.co.uk</li>
                                                <li>By phone at - 020 - 45421750</li>
                                                <li>By post at – S2P Services Limited, 200 Brook Drive, Green Park, Reading, Berkshire, RG2 6UB, United Kingdom.</li>
                                            </ul>
                                        </div>
                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </>
        );
    }
}

export default Cancellation;
