import React, { Component } from 'react';
import MainHeader from '../components/common/MainHeader';
import MobileHeader from '../components/common/MobileHeader';
import FooterDesktop from '../components/common/FooterDesktop';
import Legal from '../components/common/Legal';
import CookiesPolicy from '../components/Others/CookiesPolicy';

class CookiesPolicyPage extends Component {
    componentDidMount() {
        window.scroll(0, 0);
    }
    
    render() {
        return (
            <>
                <div className="desktop">
                    <MainHeader />
                </div>
                <div className="mobile">
                    <MobileHeader />
                </div>

                <CookiesPolicy />

                <div className="desktop">
                    <FooterDesktop />
                </div>
                
                <Legal />

            </>
        );
    }
}

export default CookiesPolicyPage;