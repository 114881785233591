import React, { Component } from 'react';
import { NavLink, Redirect } from 'react-router-dom';
import ApiURL from "../../api/ApiURL";
import axios from "axios";
import cogoToast from 'cogo-toast';
import LocalStorageHelper from '../../LocalStorageHelper/LocalStorageHelper';

class BookingSummary extends Component {
    constructor(props) {
        super(props);
        this.state = {
            btn: "Check out",
            UserId: 0,
            ServiceId: 0,
            BookingId: 0,
            BookingNo: '',
            AppointmentDate: '',
            SlotStartTime: '',
            CustName: '',
            MobileNo: '',
            EmailId: '',
            OrderAmount: 0,
            AddressLine: '',
            ProviderId: 0,
            ProviderName: '',
            ServiceName: '',
            RedirectToLogin: false,
            RedirectToBookingConfirmation: false
        }
    }

    componentDidMount() {
        var _self = this;
        var userData = JSON.parse(LocalStorageHelper.getUser());
        if (userData !== null && userData.UserId > 0) {
            var svcData = JSON.parse(LocalStorageHelper.getService());
            if (svcData !== null) {
                this.setState({
                    UserId: userData.UserId,
                    ServiceId: svcData.ServiceId,
                }, () => _self.getCartSummary())
            }
        }
        else {
            let winlocation = window.location.pathname;
            console.log(window.location.href);
            LocalStorageHelper.SetLastRedirectPath(winlocation);
            this.setState({ RedirectToLogin: true })
        }
    }

    getCartSummary = () => {
        let param = {
            CustUserId: this.state.UserId,
            ServiceId: this.state.ServiceId
        };
        axios.post(ApiURL.GetCartSummary, param).then(response => {
            let respData = response.data;
            if (respData.StatusCode === 200) {
                let dataResult = respData.DataResult;
                if (dataResult !== null) {
                    this.setState({
                        AppointmentDate: dataResult.AppointmentDate,
                        SlotStartTime: dataResult.SlotStartTime,
                        CustName: dataResult.CustName,
                        MobileNo: dataResult.MobileNo,
                        EmailId: dataResult.EmailId,
                        OrderAmount: dataResult.OrderAmount,
                        AddressLine: dataResult.AddressLine,
                        ProviderId: dataResult.ProviderId,
                        ProviderName: dataResult.ProviderName,
                        ServiceName: dataResult.ServiceName,
                    })
                }
            }
            else if (respData.StatusCode === 500) {
                cogoToast.error("Internal Server Error ! Please Try Again.", { position: 'bottom-center' });
                this.setState({ btnSelectProvider: 'Select Provider' })
            }

        }).catch(error => {
            cogoToast.error("Request Failed! Please Try Again.", { position: 'bottom-center' })
            this.setState({ btnSelectProvider: 'Select Provider' })
        })
    }

    checkout = () => {
        if(window.confirm("Are you confirmed to Book your Appointment?")){
            this.setState({ btn: "Processing.." });
            let param = {
                CustUserId: this.state.UserId,
                UpdatedBy: this.state.UserId,
                ApplicationType: "W"
            };

            axios.post(ApiURL.BookAppointment, param).then(response => {
                let respData = response.data;
                if (respData.StatusCode === 200) {
                    let dataResult = respData.DataResult;
                    if (dataResult !== null && dataResult.ReturnStatus===-2) {
                        cogoToast.error("Sorry! Booking failed due to Overlapping Slots.", { position: 'bottom-center' });
                        this.setState({ btn: 'Check out' })
                    }
                    else if (dataResult !== null && dataResult.ReturnStatus===1) {
                        LocalStorageHelper.setBooking(JSON.stringify(dataResult));
                        this.setState({
                            BookingId: dataResult.BookingId,
                            BookingNo: dataResult.BookingNo,
                            RedirectToBookingConfirmation: true
                        })
                    }

                } else if (respData.StatusCode === 500) {
                    cogoToast.error("Internal Server Error ! Please Try Again.", { position: 'bottom-center' });
                    this.setState({ btn: 'Check out' })
                }

            }).catch(error => {
                this.setState({ btn: "Check out" });
                cogoToast.error("Request Failed! Please Try Again.", { position: 'bottom-center' })
            })
        }
    }

    pageRedirectToLogin = () => {
        if (this.state.RedirectToLogin === true) {
            return <Redirect to="/login" />
        }
    }

    pageRedirectToBookingConfirmation = () => {
        if (this.state.RedirectToBookingConfirmation === true) {
            return <Redirect to="/booking-confirmation" />
        }
    }

    render() {
        const sidebarImgURL = ApiURL.BaseImageURL + "/booking-cln-img.jpg";

        return (
            <>
                <section className="banner-inner">
                    <div className="container">
                        <nav aria-label="breadcrumb">
                            <ol className="breadcrumb">
                            <li className="breadcrumb-item"><NavLink to="/">Home</NavLink></li>
                                <li className="breadcrumb-item"><NavLink to="/wishlist">Wishlist</NavLink></li>
                                <li className="breadcrumb-item"><NavLink to="/service-delilvery-address">Delivery Address</NavLink></li>
                                <li className="breadcrumb-item"><NavLink to="/provider">Provider</NavLink></li>
                                <li className="breadcrumb-item active" aria-current="page">Booking Summary</li>
                            </ol>
                        </nav>
                    </div>
                </section>

                <div className="wrapper d-flex align-items-center justify-content-center">
                    <div className="box text-box bg-light rounded-3 row flex-res">
                        <div
                            className="col-lg-7 col-md-12 col-sm-12 payment-heading bg-info d-flex justify-content-center px-3 flex-column booking-image-hold">
                            <img src={sidebarImgURL} alt="..." />
                        </div>
                        <div className="col-lg-12 col-md-12 col-sm-12 col-12 h-100 payment-details p-4">
                            <h3 className="text-center">
                                Booking Summary
                            </h3>
                            <div className="booking-info-box rounded-3 p-3">
                                <table className="w-100">
                                    <tbody>
                                        <tr className="py-1">
                                            <th>Customer : </th>
                                            <td>{this.state.CustName}</td>
                                        </tr>
                                        <tr className="py-1">
                                            <th>Email :</th>
                                            <td>{this.state.EmailId}</td>
                                        </tr>
                                        {/* <tr className="py-1">
                                            <th>Mobile :</th>
                                            <td>{this.state.MobileNo}</td>
                                        </tr> */}
                                        <tr className="py-1">
                                            <th>Address :</th>
                                            <td>{this.state.AddressLine}</td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                            <div className="d-flex align-items-center justify-content-between py-1">
                                <h4 className="h6">Service Name</h4>
                                <h5 className="h6 fw-bold">{this.state.ServiceName}</h5>
                            </div>
                            <div className="d-flex align-items-center justify-content-between py-1">
                                <h4 className="h6">Provider Name</h4>
                                <h5 className="h6 fw-bold">{this.state.ProviderName}</h5>
                            </div>
                            <div className="d-flex align-items-center justify-content-between py-1">
                                <h4 className="h6">Appointment Date</h4>
                                <h5 className="h6 fw-bold">{this.state.AppointmentDate}</h5>
                            </div>
                            <div className="d-flex align-items-center justify-content-between py-1">
                                <h4 className="h6 ">Checkin Time</h4>
                                <h5 className="h6 fw-bold">{this.state.SlotStartTime} hours</h5>
                            </div>
                            <div className="d-flex align-items-center justify-content-between py-1">
                                <h4 className="h6">Charges Rate</h4>
                                <h5 className="h6 fw-bold"><i className="fa fa-gbp"></i> {this.state.OrderAmount.toFixed(2)}</h5>
                            </div>
                            <button type="button"
                                className="py-2 rounded-3 w-100 btn btn-success d-flex my-4  align-items-center justify-content-center"
                                onClick={this.checkout}>
                                {this.state.btn}
                            </button>
                            <h5 className="text-center h6 text-danger">
                                Note:- Our Team member will contact you shortly to complete the payment process.
                            </h5>
                        </div>
                    </div>
                </div>
                {this.pageRedirectToLogin()}
                {this.pageRedirectToBookingConfirmation()}
            </>
        );
    }
}

export default BookingSummary;