import React, { Component } from 'react';
import Cart from '../components/Cart/Cart';
import MainHeader from '../components/common/MainHeader';
import FooterDesktop from '../components/common/FooterDesktop';
import Legal from '../components/common/Legal';
import MobileHeader from '../components/common/MobileHeader';
import axios from "axios";
import ApiURL from "../api/ApiURL";
import cogoToast from 'cogo-toast';
import LocalStorageHelper from '../LocalStorageHelper/LocalStorageHelper';


class CartPage extends Component {
    constructor(props) {
        super(props);
        this.state = {
            CartServiceData: [],
            CartListCount: 0,
        }
    }

    componentDidMount() {
        window.scroll(0, 0);
        this.getCartDetails();
    }

    getCartDetails = () => {
        var _self = this;
        var userData = JSON.parse(LocalStorageHelper.getUser());
        if (userData !== null) {
            var param = {
                UserId: userData.UserId,
                GuestId: userData.GuestId
            };

            axios.post(ApiURL.GetCartDetails, param).then(response => {
                let respData = response.data;
                if (respData.StatusCode === 200) {
                    var dataResult = respData.DataResult;
                    _self.setState({
                        ..._self.state,
                        CartServiceData: dataResult.CartDetails,
                        CartListCount: dataResult.CartCounter
                    })
                }
                else if (respData.StatusCode === 500) {
                    cogoToast.error("Internal Server Error ! Please Try Again.", { position: 'bottom-center' });
                }

            }).catch(error => {
                cogoToast.error("getCartDetails() Request Failed! Please Try Again.", { position: 'bottom-center' })
            })
        }
    }

    render() {
        return (
            <>
                <div className="desktop">
                    <MainHeader />
                </div>
                <div className="mobile">
                    <MobileHeader />
                </div>

                <Cart
                    CartServiceData={this.state.CartServiceData}
                    CartListCount={this.state.CartListCount}
                    getCartDetails={this.getCartDetails}
                />

                <div className="desktop">
                    <FooterDesktop />
                </div>

                <Legal />
            </>
        );
    }
}

export default CartPage;