import React, { Component } from 'react';

class PersonalInformation extends Component {

    render() {
        return (
            <>
                <section className="provider-reg-sec">
                    <div className="container">
                        <div className="provider-reg-info">
                            <h3>Personal information</h3>
                            <p>Please put all your details here.</p>
                        </div>
                        <div className="provider-reg-wrap">
                            <div className="row">
                                <div className="col-xl-6">
                                    <div className="form-floating mb-3">
                                        <input type="text" name="FirstName" className="form-control"  onChange={this.props.handleChange} placeholder="First Name" autoComplete={0} />
                                        <label htmlFor="floatingInput">First Name *</label>
                                    </div>
                                </div>
                                <div className="col-xl-6">
                                    <div className="form-floating mb-3">
                                        <input type="text" name="LastName" className="form-control" onChange={this.props.handleChange} placeholder="Last Name" autoComplete={0} />
                                        <label htmlFor="floatingInput">Last Name *</label>
                                    </div>
                                </div>
                                <div className="col-xl-6">
                                    <div className="form-floating mb-3">
                                        <input type="text" name="Email" className="form-control" onChange={this.props.handleChange} placeholder="Email" autoComplete={0} />
                                        <label htmlFor="floatingInput">Email *</label>
                                    </div>
                                </div>
                                <div className="col-xl-6">
                                    <div className="form-floating mb-3">
                                        <input type="text" name="Mobile" className="form-control" onChange={this.props.handleChange} placeholder="Mobile" autoComplete={0} />
                                        <label htmlFor="floatingInput">Mobile *</label>
                                    </div>
                                </div>
                                <h6 className="text-white">Address</h6>
                                <div className="col-xl-6">
                                    <div className="form-floating mb-3">
                                        <input type="text" name="AddressLine1" className="form-control" onChange={this.props.handleChange} placeholder="Address line one" autoComplete={0} />
                                        <label htmlFor="floatingInput">Address line one *</label>
                                    </div>
                                </div>
                                <div className="col-xl-6">
                                    <div className="form-floating mb-3">
                                        <input type="text" name="AddressLine2" className="form-control" onChange={this.props.handleChange} placeholder="Address line two" autoComplete={0} />
                                        <label htmlFor="floatingInput">Address line two</label>
                                    </div>
                                </div>
                                <div className="col-xl-6">
                                    <div className="form-floating mb-3">
                                        <input type="text" name="PostalCode" className="form-control" onChange={this.props.handleChange} placeholder="Post Code" autoComplete={0} />
                                        <label htmlFor="floatingInput">Post Code *</label>
                                    </div>
                                </div>

                                <div className="col-xl-12">
                                    <div className="btn-submit-wrap d-flex justify-content-end">
                                        <input type="button" className="btn-frm-submit" value="Next" onClick={this.props.nextStep} />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </>
        );
    }
}

export default PersonalInformation;