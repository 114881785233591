import React, { Component } from 'react';
import { Link, NavLink } from 'react-router-dom';
import axios from "axios";
import ApiURL from "../../api/ApiURL";
import cogoToast from 'cogo-toast';
import LocalStorageHelper from '../../LocalStorageHelper/LocalStorageHelper';

class SearchService extends Component {
    constructor(props) {
        super(props);
        this.state = {
            UserId: '',
            GuestId: '',
            DisplayName: '',
            Mobile: '',
            Email: '',
            LoggedIn: false,
            CartListCount: 0,
            PopularServiceData: props.PopularServiceData,
            ServiceData: [],
            SearchKey: '',
            ShowSearchList: false,
            ServiceBooked: false,
            ShowSearchBar: true,
        }
    }

    onChangeHandler = (e) => {
        var _self = this;
        let searchKey = e.target.value;
        setTimeout(function () {

            _self.searchService(searchKey);

        }, 500)
    }

    searchService = (searchKey) => {
        var _self = this;
        if (searchKey.length > 2) {
            _self.getServicesByDBSearch(searchKey);
        }
        else {
            _self.setState({ SearchKey: searchKey, ShowSearchList: false })
        }
    }

    getServicesByDBSearch = (searchKey) => {
        var _self = this;
        var param = {
            UserId: _self.state.UserId,
            GuestId: _self.state.GuestId,
            SearchKeyword: searchKey
        };

        axios.post(ApiURL.SearchService, param).then(response => {
            let respData = response.data;
            if (respData.StatusCode === 200) {
                let dataResult = respData.DataResult;
                this.setState({
                    ServiceData: dataResult.SearchServices,
                    ShowSearchList: true
                }, () => {
                    console.log('ServiceData :: ', this.state.ServiceData);
                })
            }
            else if (respData.StatusCode === 500) {
                cogoToast.error("Internal Server Error ! Please Try Again.", { position: 'bottom-center' });
            }

        }).catch(error => {
            cogoToast.error("Request Failed! Please Try Again.", { position: 'bottom-center' })
        })
    }

    bookService = (e, serviceId) => {
        e.preventDefault();
        var _self = this;
        var param = {
            ServiceId: serviceId,
            ApplicationType: 'W',
            UserId: 0,
            GuestId: 0,
        };

        if (JSON.parse(LocalStorageHelper.getUser()) !== null) {
            let userData = JSON.parse(LocalStorageHelper.getUser());
            if (userData.UserId > 0) {
                param.UserId = userData.UserId;
            }
            if (userData.GuestId > 0) {
                param.GuestId = userData.GuestId;
            }

        }
        else {
            var guestId = Math.floor(100000 + Math.random() * 900000);
            param.GuestId = guestId;
            let userData = {
                GuestId: guestId,
                UserId: 0
            };
            LocalStorageHelper.setUser(JSON.stringify(userData));
            _self.setState({ ..._self.state, GuestId: guestId });
        }

        this.addServiceToCart(param);
    }

    addServiceToCart = (param) => {
        var _self = this;
        axios.post(ApiURL.AddServiceItemToCart, param).then(response => {
            let respData = response.data;
            if (respData.StatusCode === 200) {
                let dataResult = respData.DataResult;
                if (dataResult === -3) {
                    cogoToast.error('Selected Service not available !', { position: 'bottom-center' });
                }
                if (dataResult === -2) {
                    cogoToast.error('Selected Service already exists into the cart !', { position: 'bottom-center' });
                }
                if (dataResult === -1) {
                    cogoToast.error('Customer Id not provided !', { position: 'bottom-center' });
                }
                else if (dataResult === 0) {
                    cogoToast.error('Selected Service could not be added into the cart !', { position: 'bottom-center' });
                }
                else if (dataResult > 0) {
                    cogoToast.success('Selected Service successfully added into the cart !', { position: 'bottom-center' });                   
                }
            }
            else if (respData.StatusCode === 500) {
                cogoToast.error("Internal Server Error ! Please Try Again.", { position: 'bottom-center' });
            }

        }).catch(error => {
            cogoToast.error("Request Failed! Please Try Again.", { position: 'bottom-center' })
        })

    }

    getCartListCount = () => {
        var _self = this;
        var param = {
            UserId: _self.state.UserId,
            GuestId: _self.state.GuestId,
        };
        axios.post(ApiURL.GetCartListCount, param).then(response => {
            let respData = response.data;
            if (respData.StatusCode === 200) {
                _self.setState({ ..._self.state, CartListCount: respData.DataResult });
            }
            else {
                cogoToast.error("getCartListCount Request Failed! Try Again", { position: 'bottom-center' });
            }
        }).catch(error => {
            cogoToast.error("getCartListCount Catch Request Failed! Try Again :: " + error, { position: 'bottom-center' })
        });
    }

    selectAddedService = (serviceId) => {
        var serviceDataList = this.state.ServiceData;
        var svcData = serviceDataList.filter((item) => item.ServiceId === serviceId)[0];
        if (svcData !== null) {
            svcData.IsSelected = true;
        }
        this.setState({
            ...this.state,
            ServiceBooked: true,
            ServiceData: serviceDataList
        });
    }

    render() {

        /* Popular Service Data */
        console.log(this.props.PopularServiceData);
        const popularServiceDataList = this.props.PopularServiceData;
        const PopularServiceView = popularServiceDataList.map((svc, i) => {
            return (
                <div key={svc.ServiceId}>
                    {
                        (i === 0) && <h6>Some Popular Services</h6>
                    }

                    <div key={svc.ServiceId} className="search-reasult-wrap d-flex">
                        <div className="search-reasult-service d-flex">
                            <h4>{svc.ServiceName}</h4>
                            {
                                (svc.IsSelected) ?
                                    <Link to={"#"} className="search-service-btn btn_selected">
                                        <i className="fa fa-check"></i> Selected
                                    </Link> :
                                    <Link to={"#"}
                                        className="search-service-btn"
                                        onClick={(e) => this.bookService(e, svc.ServiceId)}>
                                        Book Now
                                    </Link>
                            }
                        </div>
                    </div>
                </div>
            )
        })


        /* Search Service Data */
        const serviceDataList = this.state.ServiceData;
        const SearchServiceView = serviceDataList.length > 0 ?
            (
                serviceDataList.map((svc, i) => {
                    return (
                        <div key={svc.ServiceId}>
                            {
                                (i === 0) &&
                                <>
                                    <h6>Showing Search Results</h6>
                                    <h6 className="clear-all">Clear All</h6>
                                </>
                            }

                            <div key={svc.ServiceId} className="search-reasult-wrap d-flex">
                                <div className="search-reasult-service d-flex">
                                    <h4>{svc.ServiceName}</h4>
                                    {
                                        (svc.IsSelected) ?
                                            <Link to={"#"} className="search-service-btn btn_selected">
                                                <i className="fa fa-check"></i> Selected
                                            </Link> :
                                            <Link to={"#"}
                                                className="search-service-btn"
                                                onClick={(e) => this.bookService(e, svc.ServiceId)}>
                                                Book Now
                                            </Link>
                                    }
                                </div>
                            </div>
                        </div>
                    )
                })
            ) :
            (<div>
                <h6>No Result(s) Found!</h6>
            </div>);


        return (
            <>
                <div className="form-wrapper fixed-top">
                    <Link className="btn btn-default" to="/">
                        <i className='fa fa-arrow-left'></i>
                    </Link>
                    <input type="text" className="search-service"
                        placeholder="Search your service"
                        onKeyUp={this.onChangeHandler} />
                    <button className="search-btn" title="Search"><i className='bx bx-search'></i></button>
                </div>
                <div className="container">
                    {
                        (this.state.ShowSearchList !== true) &&
                        <div className="search-result-wrap">

                            {PopularServiceView}

                        </div>
                    }
                    <div className={(this.state.ShowSearchList === true) ? "search-result-wrap" : "hide-search-holder-wrap"}>

                        {SearchServiceView}

                    </div>
                </div>
            </>
        );
    }
}

export default SearchService;