import React, { Component } from 'react';
import MainHeader from '../components/common/MainHeader';
import FooterDesktop from '../components/common/FooterDesktop';
import Legal from '../components/common/Legal';
import SelectProvider from '../components/Provider/SelectProvider';
import MobileHeader from '../components/common/MobileHeader';

class SelectProviderPage extends Component {
    componentDidMount() {
        window.scroll(0, 0);
    }
    render() {
        return (
            <>
                <div className="desktop">
                    <MainHeader />
                </div>
                <div className="mobile">
                    <MobileHeader />
                </div>

                <SelectProvider />

                <div className="desktop">
                    <FooterDesktop />
                </div>
                
                <Legal />
            </>
        );
    }
}

export default SelectProviderPage;