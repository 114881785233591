import React, { Component, Fragment } from "react";
import FooterDesktop from '../components/common/FooterDesktop';
import MainHeader from '../components/common/MainHeader';
//import MobileHeader from "../components/common/MobileHeader";
import About from "../components/Others/About";
import MobileHeader from "../components/common/MobileHeader";
import Legal from "../components/common/Legal";

class AboutPage extends Component {
    constructor(props) {
        super(props);
    }

    componentDidMount() {
        window.scroll(0, 0);
    }

    render() {
        return (
            <Fragment>
                <div className="desktop">
                    <MainHeader />
                </div>
                <div className="mobile">
                    <MobileHeader />
                </div>

                <About />

                <div className="desktop">
                    <FooterDesktop />
                </div>

                <Legal />

            </Fragment>
        );
    }
}

export default AboutPage;