import React, { Component } from 'react';
import { NavLink } from 'react-router-dom';

class Policy extends Component {
    render() {
        return (
            <>
                <section className="banner-inner">
                    <div className="container">
                        <nav aria-label="breadcrumb">
                            <ol className="breadcrumb">
                                <li className="breadcrumb-item"><NavLink to="/">Home</NavLink></li>
                                <li className="breadcrumb-item active" aria-current="page">Privacy Policy</li>
                            </ol>
                        </nav>
                    </div>
                </section>
                <section className="site-container">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12 col-md-12 col-sm-12">
                                <div className="odr-confrm-wrap">
                                    <div style={{ marginBottom: "20px" }}>
                                        <div>
                                            <center>
                                                <h3><strong>Privacy Policy</strong></h3>
                                            </center>
                                            <p>
                                                At <strong>MyBoogy</strong>, we are committed to safeguarding your personal information and ensuring your privacy. This Data Privacy Policy outlines how we collect, use, and protect your data.
                                            </p>
                                        </div>
                                    </div>
                                    <div className="policy" style={{ marginBottom: "20px" }}>
                                        <ol>
                                            <li>
                                                <h6><strong>Data Collection</strong></h6>
                                                We collect personal information, shared by you as an input to the website. With your complete awareness the data is not limited to your name, contact information, and payment details, only with your consent. We may also gather data about your interactions with our website, products, and services.
                                            </li>
                                            <li>
                                                <h6><strong>Data Use</strong></h6>
                                                Your information is used for the following purposes:
                                                <ul>
                                                    <li>Providing and improving our services</li>
                                                    <li>Processing transactions</li>
                                                    <li>Communicating with you</li>
                                                    <li>Compliance with legal obligations</li>
                                                </ul>
                                            </li>
                                            <li>
                                                <h6><strong>Data Sharing</strong></h6>
                                                Your information may be shared with trusted partners and service providers who help us deliver our services. We do not sell your data to third parties. We may also share your data to comply with legal requirements.
                                            </li>
                                            <li>
                                                <h6><strong>Cookies and Tracking</strong></h6>
                                                Our website may use cookies and similar technologies to enhance your user experience and collect data about your usage. You can manage your cookie preferences in your browser settings.
                                            </li>
                                            <li>
                                                <h6><strong>Data Access and Control</strong></h6>
                                                You have the right to access, correct, or delete your personal information. You can opt-out of marketing communications at any time. To exercise these rights or for any privacy-related concerns, contact us.
                                            </li>
                                            <li>
                                                <h6><strong>Children's Privacy</strong></h6>
                                                Our services are not intended for individuals under the age of 18. We do not knowingly collect data from children.
                                            </li>
                                            <li>
                                                <h6><strong>Changes to this Policy</strong></h6>
                                                We may update this policy as necessary. You will be notified of any significant changes.
                                            </li>
                                        </ol>
                                    </div>
                                    <div style={{ marginBottom: "20px" }}>
                                        <p>
                                            By using our services, you agree to the terms of this <b>Privacy Policy</b>. It is important to read and understand this policy. If you have any questions or concerns, please contact us.
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </>
        );
    }
}

export default Policy;
