import React, { Component } from 'react';
import FooterDesktop from '../components/common/FooterDesktop';
import Legal from '../components/common/Legal';
import MainHeader from '../components/common/MainHeader';
import ProviderRegistration from '../components/Provider/ProviderRegistration';
import MobileHeader from '../components/common/MobileHeader';

class ProviderRegistrationPage extends Component {
    render() {
        return (
            <>
                <div className="desktop">
                    <MainHeader />
                </div>
                <div className="mobile">
                    <MobileHeader />
                </div>

                <ProviderRegistration />
                
                <div className="desktop">
                    <FooterDesktop />
                </div>
                
                <Legal />
            </>
        );
    }
}

export default ProviderRegistrationPage;