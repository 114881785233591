import React, { Component } from 'react';
import ManageWorkArea from '../../components/ProviderPanel/ManageWorkArea';
import FooterDesktop from "../../components/common/FooterDesktop";
import Legal from "../../components/common/Legal";
import MainHeader from "../../components/common/provider/MainHeader";
import ManageProfile from '../../components/ProviderPanel/ManageProfile';
import MobileHeader from '../../components/common/MobileHeader';

class ManageWorkAreaPage extends Component {
    constructor(props) {
        super(props);
        this.state = {
            checkUserType: props.checkUserType
        }
    }
    
    render() {
        return (
            <>
                <div className="desktop">
                    <MainHeader checkUserType={this.state.checkUserType} />
                </div>
                <div className="mobile">
                    <MobileHeader />
                </div>


                <ManageWorkArea />


                <div className="desktop">
                    <FooterDesktop />
                </div>
                <Legal />
            </>
        );
    }
}

export default ManageWorkAreaPage;