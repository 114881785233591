import React, { Component, Fragment } from "react";
import { Route, Switch } from "react-router";
import HomePage from "../pages/provider/HomePage";
import UnauthorizedPage from "../pages/provider/UnauthorizedPage";
import ManageSlotPage from "../pages/provider/ManageSlotPage";
import ManageServiceRatePage from "../pages/provider/ManageServiceRatePage";
import BookingHistoryPage from "../pages/provider/BookingHistoryPage";
import ProtectedRoute from "../pages/ProtectedRoutes";
import LoginPage from "../pages/LoginPage";
import VerifyOTPPage from "../pages/VerifyOTPPage";
import ManageProfilePage from "../pages/provider/ManageProfilePage";
import ManageWorkAreaPage from "../pages/provider/ManageWorkAreaPage";
import ManageServicePage from "../pages/provider/ManageServicePage";
import AboutPage from "../pages/AboutPage";
import ContactPage from "../pages/ContactPage";
import BookingTermsAndConditionPage from "../pages/BookingTermsAndConditionPage";
import PolicyPage from "../pages/PolicyPage";
import CancellationPage from "../pages/CancellationPage";
import CookiesPolicyPage from "../pages/CookiesPolicyPage";

class ProviderAppRoute extends Component {
    constructor(props) {
        super(props);
        this.state = {
            checkUserType: props.checkUserType
        }
    }
    render() {
        return (
            <Fragment>
                <Switch>
                    <Route
                        exact
                        path="/"
                        render={props => <HomePage {...props} checkUserType={this.state.checkUserType} key={Date.now()} />}
                    />

                    <Route
                        exact
                        path="/login"
                        render={props => <LoginPage {...props} key={Date.now()} />}
                    />
                    <Route
                        exact
                        path="/verifyotp/:mobileNo"
                        render={(props) => <VerifyOTPPage {...props} checkUserType={this.state.checkUserType} key={Date.now()} />
                        }
                    />

                    <Route
                        exact
                        path="/provider-home"
                        render={props => <HomePage {...props} checkUserType={this.state.checkUserType} key={Date.now()} />}
                    />
                    <Route
                        exact
                        path="/provider-slot"
                        render={props => <ManageSlotPage {...props} checkUserType={this.state.checkUserType} key={Date.now()} />}
                    />
                    <Route
                        exact
                        path="/service-provider-rate"
                        render={props => <ManageServiceRatePage {...props} checkUserType={this.state.checkUserType} key={Date.now()} />}
                    />
                    <Route
                        exact
                        path="/manage-booking-history"
                        render={props => <BookingHistoryPage {...props} checkUserType={this.state.checkUserType} key={Date.now()} />}
                    />
                    <Route
                        exact
                        path="/profile"
                        render={props => <ManageProfilePage {...props} checkUserType={this.state.checkUserType} key={Date.now()} />}
                    />
                    <Route
                        exact
                        path="/manage-work-area"
                        render={props => <ManageWorkAreaPage {...props} checkUserType={this.state.checkUserType} key={Date.now()} />}
                    />
                    <Route
                        exact
                        path="/manage-service"
                        render={props => <ManageServicePage {...props} checkUserType={this.state.checkUserType} key={Date.now()} />}
                    />

                    {/* <ProtectedRoute
                        exact
                        path="/manage-booking-history"
                        component={BookingHistoryPage}
                    /> */}

                    <Route
                        exact
                        path="/unauthorize"
                        render={props => <UnauthorizedPage {...props} key={Date.now()} />}
                    />
                    <Route
                        exact
                        path="/about"
                        render={props => <AboutPage {...props} key={Date.now()} />}
                    />
                    <Route
                        exact
                        path="/contact"
                        render={props => <ContactPage {...props} key={Date.now()} />}
                    />
                    <Route
                        exact
                        path="/btc"
                        render={props => <BookingTermsAndConditionPage {...props} key={Date.now()} />}
                    />
                    <Route
                        exact
                        path="/policy"
                        render={props => <PolicyPage {...props} key={Date.now()} />}
                    />
                    <Route
                        exact
                        path="/cancellation"
                        render={props => <CancellationPage {...props} key={Date.now()} />}
                    />
                    <Route
                        exact
                        path="/cookies-policy"
                        render={props => <CookiesPolicyPage {...props} key={Date.now()} />}
                    />
                    {/* <Route component={UnauthorizedPage} /> */}

                </Switch>
            </Fragment>
        );
    }
}

export default ProviderAppRoute;
