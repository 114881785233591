import React, { Component } from 'react';

class Legal extends Component {
    render() {
        return (
            <>
                <section id="legal">
                    <div className="container">
                        <p>Copyright © 2024 Myboogy. All rights reserved.</p>
                    </div>
                </section>
            </>
        );
    }
}

export default Legal;