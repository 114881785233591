import React, { Component } from 'react';
import { NavLink, Link } from 'react-router-dom';

class CookiesPolicy extends Component {
    render() {
        return (
            <>
                <section className="banner-inner">
                    <div className="container">
                        <nav aria-label="breadcrumb">
                            <ol className="breadcrumb">
                                <li className="breadcrumb-item"><NavLink to="/">Home</NavLink></li>
                                <li className="breadcrumb-item active" aria-current="page">Cookies Policy</li>
                            </ol>
                        </nav>
                    </div>
                </section>
                <section className="site-container">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12 col-md-12 col-sm-12">
                                <div className="odr-confrm-wrap">
                                    <div style={{ marginBottom: "20px" }}>
                                        <div>
                                            <center>
                                                <h3><strong>Cookies Policy</strong></h3>
                                            </center>
                                            <p>
                                                S2P Services Limited ( MyBoogy, We, Us) is committed to protecting your privacy. We are committed to our visitors, Users & Service Providers to create an enjoyable and safe experience when visiting our website.
                                            </p>
                                        </div>
                                        <p>
                                            In operating this Site, we use a technology called “cookies.”
                                        </p>
                                    </div>

                                    <div style={{ marginBottom: "20px" }}>
                                        <h5><strong>An Introduction To Cookies</strong></h5>
                                        <p>
                                            A cookie is a small data file that is automatically placed onto your device storage area. Cookie files are created by our website and placed onto your device in order to analyze and influence your preferences or behavior. Analytical cookies are designed to track your journey and experience on our website – so that we may tailor your experience and gather data. Overall, cookies help us provide you with a better website, by enabling us to monitor which pages you find useful and which you do not. A cookie in no way gives us access to your computer or any information about you, other than the data you choose to share with us.
                                        </p>
                                        <p>Therefore it could be classified as : </p>
                                        <div>

                                            <ul>
                                                <li>Identifying you ( customer/ service provider) during the sign-in process to access our services.</li>
                                                <li>Showcasing features and services that align with your interests, including relevant advertisements on our platforms for newly added, popular and upcoming services.</li>
                                                <li>Monitoring the contents of your service cart for providing suggestions in future.</li>
                                                <li>Safeguarding your browsing details against fraudulent activities.</li>
                                                <li>Enhancing overall security measures.</li>
                                                <li>Recording your preferences, such as your location and language settings.</li>
                                            </ul>
                                        </div>
                                    </div>

                                    <div style={{ marginBottom: "20px" }}>
                                        <h5><strong>How do we use cookies ? </strong></h5>
                                        <p>
                                            These compact data snippets serve various purposes, such as remembering your device and interactions with the website. For instance, they can store login credentials, sparing you the need to re-enter information during subsequent visits. Additionally, cookies play a role in personalized advertising, aligning promotional content based on your search history and interests.
                                        </p>
                                        <p>
                                            Moreover, cookies contribute to the efficient functioning of the website by retaining settings like custom video streaming preferences or volume adjustments. In essence, these modest files enable MyBoogy to remember and optimize your browsing experience, fostering convenience and personalization.
                                        </p>
                                    </div>

                                    <div style={{ marginBottom: "20px" }}>
                                        <h5><strong>Types of Cookies used in MyBoogy</strong></h5>
                                        <div>
                                            <h6><strong>Type 1 : Necessary Cookies</strong></h6>
                                            These cookies play a vital role in facilitating your navigation through the website and utilizing its functionalities, including accessing secure sections. They are indispensable for enabling key features, such as the use of shopping carts, automated emails regarding job scheduling & invoicing etc. Without these cookies, specific functions essential to your online experience would be rendered inaccessible.
                                        </div>

                                        <h6><strong>Type 2 : Performance Cookies</strong></h6>
                                        <p>
                                            Gathering data on website usage, these cookies focus on tracking visitors' behaviors, such as the pages they frequent, and any error messages encountered. Importantly, they do not gather information that personally identifies a visitor. Instead, the collected data is anonymized and aggregated, solely serving the purpose of enhancing the overall functionality and performance of the website. The cookies are filtered based on the users system navigation.
                                        </p>
                                        <h6><strong>Type 3 : Functionality Cookies for making note of navigations between pages</strong></h6>
                                        <p>
                                            These cookies enable the website to retain your preferences, such as user name, language, or geographical region, offering enhanced and personalized features. For instance, the website may utilize a cookie to store your current location, allowing it to provide search results to show local or area based service providers. Additionally, these cookies can remember adjustments you've made to text size, fonts, and other customizable elements on web pages. Furthermore, they may facilitate the provision of requested services, such as video playback or commenting on a blog. It's important to note that the information gathered by these cookies can be anonymized, and they do not have the capability to track your browsing activity on external websites.
                                        </p>
                                    </div>

                                    <div style={{ marginBottom: "20px" }}>
                                        <h5><strong>Cookie Preferences</strong></h5>
                                        <p>
                                            The majority of modern web browsers will automatically allow cookies to be placed onto your device. However, you can usually modify your browser’s settings in order to prevent any cookies from being placed onto your device. Setting your browser to block cookies may prevent you from taking full advantage of our website.
                                        </p>
                                        <p>
                                            Most internet browsers/ our users are initially set up to automatically accept cookies. While you visit our website you will get a pop up. Based on your preference you can accept or decline the cookies on our website. You can also go to your browser and delete all the cookies from history.
                                        </p>
                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>
                </section>

            </>
        );
    }
}

export default CookiesPolicy;