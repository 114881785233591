import React, { Component } from 'react';
import { NavLink, Redirect } from 'react-router-dom';
import ApiURL from "../../api/ApiURL";
import axios from "axios";
import cogoToast from 'cogo-toast';
import LocalStorageHelper from '../../LocalStorageHelper/LocalStorageHelper';

class BookingHistory extends Component {
    constructor(props) {
        super(props);
        this.state = {
            UserId: 0,
            BookingHistoryData: [],
            RedirectToLogin: false,
        }
    }

    componentDidMount() {
        var _self = this;
        var userData = JSON.parse(LocalStorageHelper.getUser());
        if (userData !== null && userData.UserId > 0) {
            this.setState({
                UserId: userData.UserId
            }, () => _self.getBookingHistory())
        }
        /* else {
            let winlocation = window.location.pathname;
            console.log(window.location.href);
            LocalStorageHelper.SetLastRedirectPath(winlocation);
            this.setState({ RedirectToLogin: true })
        } */
    }

    getBookingHistory = () => {
        let param = { CustUserId: this.state.UserId };
        axios.post(ApiURL.GetBookingHistory, param).then(response => {
            let respData = response.data;
            if (respData.StatusCode === 200) {
                let dataResult = respData.DataResult;
                if (dataResult.length > 0) {
                    this.setState({ BookingHistoryData: dataResult })
                }
            }
            else if (respData.StatusCode === 500) {
                cogoToast.error("Internal Server Error ! Please Try Again.", { position: 'bottom-center' });

            }

        }).catch(error => {
            cogoToast.error("Request Failed! Please Try Again.", { position: 'bottom-center' })
        })
    }

    cancelBooking = (bk) => {
        //e.preventDefault();
        if (window.confirm("Are you sure to Cancel the selected Booking?")) {
            var userData = JSON.parse(LocalStorageHelper.getUser());
            if (userData !== null && userData.UserId > 0) {
                //let userId=userData.UserId;
                let custName= userData.DisplayName;
                let email=userData.Email;
                let mobile=userData.Mobile;

                let param = {
                    CustUserId: this.state.UserId,
                    BookingNo: bk.BookingNo,
                    CustName: custName,
                    EmailId: email,
                    MobileNo: mobile
                };
                
                axios.post(ApiURL.CancelBooking, param).then(response => {
                    let respData = response.data;
                    if (respData.StatusCode === 200) {
                        let status = respData.DataResult;
                        //console.log(dataResult);
                        if (status === -1) {
                            cogoToast.error("Booking cancellation at the same day not possible! Please Try Again.", { position: 'bottom-center' });
                        }
                        else if (status === 0) {
                            cogoToast.error("Booking cancellation failed! Please Try Again.", { position: 'bottom-center' });
                        }
                        else if (status === 1) {
                            cogoToast.success("Booking cancellation succeeded!", { position: 'bottom-center' });
                            this.getBookingHistory();
                        }

                    }
                    else if (respData.StatusCode === 500) {
                        cogoToast.error("Internal Server Error ! Please Try Again.", { position: 'bottom-center' });
                    }

                }).catch(error => {
                    cogoToast.error("Request Failed! Please Try Again.", { position: 'bottom-center' })
                })
            }

        }

    }

    pageRedirectToLogin = () => {
        if (this.state.RedirectToLogin === true) {
            return <Redirect to="/login" />
        }
    }

    render() {

        const bookingHistoryDataList = this.state.BookingHistoryData;
        const BookingListView = bookingHistoryDataList.length > 0 &&
            (
                bookingHistoryDataList.map((bk, i) => {
                    return (
                        <tr key={i}>
                            <td data-th="Booking Number">
                                {bk.BookingNo}
                            </td>
                            <td data-th="Service Name">
                                {bk.ServiceName}
                            </td>                            
                            <td data-th="Provider Name">
                                {bk.ProviderName}
                            </td>
                            <td data-th="Amount">
                                {bk.BillAmount} GBP
                            </td>
                            <td data-th="Appointment Date">
                                {bk.AppointmentDate}
                            </td>
                            <td data-th="Slot Start Time">
                                {bk.SlotStartTime}
                            </td>                            
                            <td data-th="Booking Status">
                                {bk.BookingStatus}
                            </td>
                            <td data-th="Action">
                                {
                                    (bk.BookingStatus === 'Confirmed') ?
                                        <button className="btn btn-danger btn-cancel"
                                            onClick={() => this.cancelBooking(bk)}
                                        >
                                            <i className="fa fa-cancel" ></i>&nbsp; Cancel Booking
                                        </button>
                                        :
                                        <button className="btn btn-warning btn-cancel disabled">
                                            <i className="fa fa-cancel" ></i>&nbsp; Cancelled
                                        </button>
                                }

                            </td>
                        </tr>
                    )
                })
            );


        return (
            <>
                <section className="banner-inner">
                    <div className="container">
                        <nav aria-label="breadcrumb">
                            <ol className="breadcrumb">
                                <li className="breadcrumb-item"><NavLink to="/">Home</NavLink></li>
                                <li className="breadcrumb-item active" aria-current="page">Booking History</li>
                            </ol>
                        </nav>
                    </div>
                </section>

                <section id="booking-history">
                    <div className="container">
                        <div className="heading">
                            <h3>Booking <span>History</span></h3>
                            {
                                (this.state.BookingHistoryData.length > 0) ?
                                    <h6 className="section-sub-title"><span>List of booked item(s)</span>, you may manage</h6>
                                    :
                                    <h6 className="section-sub-title">Sorry! Booking List is empty.</h6>
                            }
                        </div>
                    </div>
                </section>

                <section className="booking-list">
                    <div className="container">
                        <table className="rwd-table">
                            <tbody>
                                <tr>
                                    <th>Booking No.</th>
                                    <th>Service Name</th>
                                    <th>Provider Name</th>
                                    <th>Amount</th>
                                    <th>Appointment Date</th>
                                    <th>Slot Start Time</th>
                                    <th>Status</th>
                                    <th>Action</th>
                                </tr>

                                {BookingListView}

                            </tbody>
                        </table>
                    </div>
                </section>
                {this.pageRedirectToLogin()}
            </>
        );
    }
}

export default BookingHistory;