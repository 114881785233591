import React, { Component } from 'react';
import { NavLink } from 'react-router-dom';
import Select from 'react-select';
import makeAnimated from 'react-select/animated';
import axios from "axios";
import ApiURL from "../../api/ApiURL";
import cogoToast from 'cogo-toast';
import LocalStorageHelper from '../../LocalStorageHelper/LocalStorageHelper';

class ManageWorkArea extends Component {
    constructor(props) {
        super(props);
        const animatedComponents = makeAnimated();
        this.state = {
            btn: 'Save',
            ProviderId: 0,
            AnimatedComponents: animatedComponents,
            CoveragePostCodeData: [],
            AreaCodeData: [],
            SelectedAreaCodes:[],
            FilteredCovPostCodeData: [],
            SelectedPostCodeValues: [],
            SelectedPostCodeIds: []
        };

    }

    //componentDidMount Event   
    componentDidMount() {
        window.scroll(0, 0);
        this.getAllWorkingAreas();
        var userData = JSON.parse(LocalStorageHelper.getUser());
        if (userData !== null) {
            this.setState({ ProviderId: userData.UserId })
        }
        else {
            let winlocation = window.location.pathname;
            console.log(window.location.href);
            LocalStorageHelper.SetLastRedirectPath(winlocation);
            this.setState({ RedirectToLogin: true })
        }
    }

    getAllWorkingAreas = () => {
        var _self = this;
        let reactSelectAreaData = [];
        axios.get(ApiURL.GetAllProviderDataList).then(response => {
            let respData = response.data;
            if (respData.StatusCode === 200) {
                var dataResult = respData.DataResult;
                let covPostCodeData = dataResult.CoveragePostCodes;
                let postCodeAreas = this.getAreaDataFromCovPostCodes(covPostCodeData);
                for (var i = 1; i < postCodeAreas.length; i++) {
                    reactSelectAreaData.push({
                        value: postCodeAreas[i].PostCodeArea,
                        label: postCodeAreas[i].PostCodeArea
                    })
                }

                _self.setState({
                    CoveragePostCodeData: covPostCodeData,
                    AreaCodeData: reactSelectAreaData,
                })
            }
        })
    }

    getAreaDataFromCovPostCodes = (array) => {
        const key = 'PostCodeArea';
        const arrayUniqueByKey = [...new Map(array.map(item =>
            [item[key], item])).values()];
        return arrayUniqueByKey;
    }

    onChangeAreaCode = (selected) => {
        let filteredCoverageData = [];
        let covPostCodes = this.state.CoveragePostCodeData;
        if (selected.length > 0) {
            selected.map(si => {
                let filterKey = si.value;
                filteredCoverageData = filteredCoverageData.concat(covPostCodes.filter((item) => item.PostCodeArea === filterKey));
            });
        }
        var myData = [{ value: 0, label: 'All' }];
        for (var i = 0; i < filteredCoverageData.length; i++) {
            myData.push({
                value: filteredCoverageData[i].PostcodeDistrictId,
                label: filteredCoverageData[i].PostCodeDistrict
            })
        }
        this.setState({ ...this.state, SelectedAreaCodes: selected, FilteredCovPostCodeData: myData })
    }

    onChangePostcode = (selected) => {
        var selectedItems = [];
        var coverageData = this.state.FilteredCovPostCodeData;
        if (selected.length > 0) {
            selectedItems = selected;
            //Check For All
            if (selected[selected.length - 1].value === 0) {
                selectedItems = coverageData.filter((item) => item.value !== 0);
            }
        }
        let areaCodeIds = selectedItems.map((item) => item.value);
        this.setState({
            ...this.state,
            SelectedPostCodeValues: selectedItems,
            SelectedPostCodeIds: areaCodeIds
        })
    }

    onSaveChanges = (e) => {
        e.preventDefault();
        //console.log(this.state.SelectedPostCodeIds);
        if (window.confirm('Do you want to save the Selected Post Codes?')) {
            this.setState({ btn: "Processing.." });
            let param = {
                ProviderId: this.state.ProviderId,
                PostCodeDistrictIds: this.state.SelectedPostCodeIds.toString()
            };
            axios.post(ApiURL.UpdateProviderPostCodes, param).then(response => {
                let respData = response.data;
                if (respData.StatusCode === 200) {
                    let dataResult=respData.DataResult;
                    if(dataResult===1)
                    {
                        cogoToast.success("Service code saved successfully!", { position: 'bottom-center' });
                    }
                    else{
                        cogoToast.error("Sorry! Request failed.", { position: 'bottom-center' });
                    }
                    this.setState({ btn: "Save" });                    
                }
                else if (respData.StatusCode === 500) {
                    this.setState({ btn: "Save" });
                    cogoToast.error("Internal Server Error ! Please Try Again.", { position: 'bottom-center' });
                }

            }).catch(error => {
                this.setState({ btn: "Save" });
                cogoToast.error("Request Failed! Please Try Again.", { position: 'bottom-center' })
            })
        }

    }

    onResetData=(e)=>{
        e.preventDefault();
        var self=this;
        self.setState({
            CoveragePostCodeData: [],
            AreaCodeData: [],
            SelectedAreaCodes:[],
            FilteredCovPostCodeData: [],
            SelectedPostCodeValues: [],
            SelectedPostCodeIds: []
        },()=>{
            self.getAllWorkingAreas()
        })
        
    }


    render() {
        return (
            <>
                <section className="banner-inner">
                    <div className="container">
                        <nav aria-label="breadcrumb">
                            <ol className="breadcrumb">
                                <li className="breadcrumb-item"><NavLink to="/provider-home">Home</NavLink></li>
                                <li className="breadcrumb-item active" aria-current="page">Manage Coverage Area</li>
                            </ol>
                        </nav>
                    </div>
                </section>
                <section id="provider-reg-info">
                    <div className="container-fluid">
                        <div className="heading">
                            <h3>Manage <span>Coverage Area</span></h3>
                            <p>Please mention the postcodes where you are looking to provide services.</p>
                            <hr />
                        </div>
                    </div>
                </section>
                <div className="container">
                    <div className="main-body">
                        <div className="row">
                            <div className="col-sm-3">&nbsp;</div>
                            <div className="col-sm-6">
                                <form>
                                    <div className="card">
                                        <div className="card-body">
                                            <div className="form-group">
                                                <h6>Area Code</h6>
                                                <Select
                                                    name="areacode"
                                                    closeMenuOnSelect={true}
                                                    components={this.state.AnimatedComponents}
                                                    options={this.state.AreaCodeData}
                                                    isMulti
                                                    onChange={this.onChangeAreaCode}
                                                    value={this.state.SelectedAreaCodes}
                                                />
                                            </div>
                                            <div className="form-group">
                                                <h6>Out-Code</h6>
                                                <Select
                                                    name="postcode"
                                                    closeMenuOnSelect={true}
                                                    components={this.state.AnimatedComponents}
                                                    options={this.state.FilteredCovPostCodeData}
                                                    isMulti
                                                    onChange={this.onChangePostcode}
                                                    value={this.state.SelectedPostCodeValues}
                                                />

                                            </div>
                                            <div className="form-group float-end">
                                                <button type="button" className="btn btn-outline-success mr-2" onClick={this.onSaveChanges}>
                                                    <i className="fa fa-floppy-o"></i> Save
                                                </button>
                                                <button className="btn btn-outline-success" onClick={this.onResetData}>
                                                    <i className="fa fa-solid fa-eraser"></i> Reset
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                </form>
                            </div>
                            <div className="col-sm-3">&nbsp;</div>
                        </div>
                    </div>
                </div>
            </>
        );
    }
}

export default ManageWorkArea;