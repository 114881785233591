import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import { Link, Redirect, NavLink } from "react-router-dom";
import axios from "axios";
import ApiURL from "../../api/ApiURL";
import cogoToast from "cogo-toast";
import LocalStorageHelper from "../../LocalStorageHelper/LocalStorageHelper";
import "react-responsive-modal/styles.css";
import { Modal } from "react-responsive-modal";

class VerifyOTP extends Component {
  constructor(props) {
    super(props);
    this.state = {
      btn: 'Verify',
      MobileNo: '',
      Otp: '',
      UserRedirect: false,
      VerifyStatus: 0,
      UserType: 'C',
      isModalOpen: false,
    };
  }

  getUserProfile = (otpData) => {
    var _self = this;
    var param = otpData;
    var userData = null;
    if (JSON.parse(LocalStorageHelper.getUser()) !== null) {
      userData = JSON.parse(LocalStorageHelper.getUser());
      param.GuestId = userData.GuestId;
    }

    axios.post(ApiURL.GetUserProfile, param).then(response => {
      let respData = response.data;
      if (respData.StatusCode === 200) {
        var loggedInUserProfile = response.data.DataResult;

        if (loggedInUserProfile !== null) {
          loggedInUserProfile.GuestId = (userData !== null && userData.GuestId > 0) ? userData.GuestId : 0;
          LocalStorageHelper.removeUser();
          LocalStorageHelper.setUser(JSON.stringify(loggedInUserProfile));
          _self.props.checkUserType(param.UserType);
          if (param.UserType === 'C') {
            _self.props.history.push('/');
          }
          else if (param.UserType === 'S') {
            _self.props.history.push('/provider-home');
          }
        }
        else {
          this.setState({ btn: "Verify" });
          cogoToast.error("Request Failed! Please Try Again.", { position: 'bottom-center' });
        }

      } else if (respData.StatusCode === 400) {

        this.setState({ btn: "Verify" });
        cogoToast.error(respData.Message, { position: 'bottom-center' });

      } else if (respData.StatusCode === 500) {

        this.setState({ btn: "Verify" });
        cogoToast.error("Request Failed! Please Try Again.", { position: 'bottom-center' });

      }
    }).catch(error => {

      this.setState({ btn: "Verify" });
      cogoToast.error("Request Failed! Please Try Again.", { position: 'bottom-center' });

    });
  }

  onVerifyClick = (e) => {
    e.preventDefault();

    let mobileNo = this.props.Mobile;
    let otpValue = this.state.Otp;

    if (mobileNo.length === 0) {
      cogoToast.error("Mobile Number Required.", { position: 'bottom-center' });
    }
    if (otpValue.length !== 6) {
      cogoToast.error("Invalid OTP ! Please try again.", { position: 'bottom-center' });
    }
    else {
      this.setState({ btn: "Processing.." });
      var param = {
        MobileNo: mobileNo,
        OTP: otpValue
      };
      axios.post(ApiURL.OTPVerification, param).then(response => {
        let respData = response.data;
        if (respData.StatusCode === 200) {
          let dataResult = respData.DataResult;
          let verifyStatus = dataResult.ReturnStatus;
          let userType = dataResult.UserType;

          if (verifyStatus === -1) {
            this.setState({ btn: "Verify" });
            cogoToast.error("Invalid OTP ! Please try again.", { position: 'bottom-center' });
          }
          else if (verifyStatus === 1) {
            param.UserType = userType;
            if (userType === 'C' || userType === 'S') {
              //Fetch User Profile Data
              this.getUserProfile(param);
            }
            else if (userType === 'X') {
              this.setState({ ...this.state, isModalOpen: true, MobileNo: mobileNo })
            }
          }

        } else if (respData.StatusCode === 400) {

          this.setState({ btn: "Verify" });
          cogoToast.error(respData.Message, { position: 'bottom-center' });

        } else if (respData.StatusCode === 500) {

          this.setState({ btn: "Verify" });
          cogoToast.error("Request Failed! Please Try Again.", { position: 'bottom-center' });

        }
      }).catch(error => {

        this.setState({ btn: "Verify" });
        cogoToast.error("Request Failed! Please Try Again.", { position: 'bottom-center' });

      });
    }
  }

  onResendOTPClick = (e) => {
    e.preventDefault();
    var param = { Mobile: this.props.Mobile };
    axios.post(ApiURL.ResendOTP, param).then(response => {
      let respData = response.data;
      if (respData.StatusCode === 200) {
        console.log(respData.DataResult);
        if (respData.DataResult === 1) {
          cogoToast.success('Your 6 digits OTP successfully send to your Registered Email.', { position: 'bottom-center' });
        }
        else {
          cogoToast.error("Request Failed! Please Try Again.", { position: 'bottom-center' });
        }

      } else if (respData.StatusCode === 400) {
        cogoToast.error(respData.Message, { position: 'bottom-center' });

      } else if (respData.StatusCode === 500) {
        cogoToast.error("Request Failed! Please Try Again.", { position: 'bottom-center' });
      }

    }).catch(error => {
      cogoToast.error("Request Failed! Please Try Again.", { position: 'bottom-center' });
    });




  }

  onUserRedirect() {
    let winPath = "";
    if (this.state.UserRedirect === true) {
      winPath = LocalStorageHelper.GetLastRedirectPath();
      if (winPath === null) {
        return (<Redirect exact to="/" />)
      }
      else {
        return (<Redirect exact to={winPath} />)
      }
    }
  }

  onOpenModal = () => {
    this.setState({ isModalOpen: true });
  }

  onCloseModal = () => {
    this.setState({ isModalOpen: false });
  }

  onSubmitUserType = (e) => {
    var param = {
      MobileNo: this.state.MobileNo,
      OTP: this.state.Otp,
      UserType: this.state.UserType
    };
    //Fetch User Profile Data
    this.getUserProfile(param);

  }

  render() {
    const { isModalOpen } = this.state;
    const otpImgURL = ApiURL.BaseImageURL + "/otp.png";
    return (
      <>
        <section className="banner-inner">
          <div className="container">
            <nav aria-label="breadcrumb">
              <ol className="breadcrumb">
                <li className="breadcrumb-item"><NavLink to="/">Home</NavLink></li>
                <li className="breadcrumb-item active" aria-current="page">Verify OTP</li>
              </ol>
            </nav>
          </div>
        </section>
        <section className="user-reg-sec">
          <div className="container">
            <div className="row align-items-center">
              <div className="col-xl-7">
                <div className="user-reg-img-wrap">
                  <img src={otpImgURL} className="img-fluid" alt="..." />
                </div>
              </div>
              <div className="col-xl-5">
                <div className="user-reg-frm-wrap">
                  <h4>User Verification</h4>
                  <h5>
                    <i className="fa fa-info-circle" aria-hidden="true"></i>&nbsp;
                    Please enter the <b>OTP</b> sent to your Registered Email.
                  </h5>
                  <div className="form-floating mb-3">
                    <input
                      type="text"
                      className="form-control"
                      placeholder="otp"
                      autoComplete={0}
                      onChange={(e) => this.setState({ Otp: e.target.value })}
                    />
                    <label htmlFor="floatingInput">Enter OTP</label>
                  </div>
                  <div className="frm-ctrl-wrap mb-3">
                    <input type="button" className="btn-submit" value={this.state.btn} onClick={this.onVerifyClick} />
                  </div>
                  <p className="sign-para">
                    <h5>Did not get OTP?  Press  &nbsp;
                      <a href="#" onClick={this.onResendOTPClick}> <u>Resend OTP</u> </a> &nbsp;link.
                    </h5>
                  </p>
                </div>
              </div>
            </div>
          </div>
          {/* {this.onUserRedirect()} */}
        </section>
        <Modal open={isModalOpen} onClose={this.onCloseModal}>
          <div className="container">
            <div className="row">
              <div className="col-sm-12">
                <h5>Login/Join as a</h5>
                <div className="form-group">
                  <label>Please select below-mentioned user type</label >
                  <select
                    className="form-select mb-3"
                    defaultValue={"C"}
                    onChange={e => this.setState({ UserType: e.target.value })}>
                    <option value="C">Customer</option>
                    <option value="S">Service Provider</option>
                  </select>
                </div>
                <input type="button" className="btn-submit" value="Submit" onClick={this.onSubmitUserType} />
              </div>
            </div>

          </div>
        </Modal>
      </>
    );
  }
}



export default withRouter(VerifyOTP);
