import React, { Component } from 'react';
import { NavLink, Redirect } from 'react-router-dom';
import ApiURL from "../../api/ApiURL";
import axios from "axios";
import cogoToast from 'cogo-toast';
import LocalStorageHelper from '../../LocalStorageHelper/LocalStorageHelper';

class BookingHistory extends Component {
    constructor(props) {
        super(props);
        this.state = {
            ProviderId: 0,
            BookingHistoryData: [],
            RedirectToLogin: false,
        }
        this.hotTable = React.createRef();
    }

    componentDidMount() {
        var _self = this;
        var userData = JSON.parse(LocalStorageHelper.getUser());
        if (userData !== null) {
            this.setState({ ProviderId: userData.UserId }, () =>
                _self.getBookingHistory()
            )
        }
        /* else {
            let winlocation = window.location.pathname;
            console.log(window.location.href);
            LocalStorageHelper.SetLastRedirectPath(winlocation);
            this.setState({ RedirectToLogin: true })
        } */
    }

    getBookingHistory = () => {
        let param = {
            CustUserId: 0,
            ProviderUserId: this.state.ProviderId
        };
        axios.post(ApiURL.GetBookingHistory, param).then(response => {
            let respData = response.data;
            if (respData.StatusCode === 200) {
                let dataResult = respData.DataResult;
                console.log(dataResult);
                if (dataResult.length > 0) {
                    this.setState({ BookingHistoryData: dataResult })
                }
            }
            else if (respData.StatusCode === 500) {
                cogoToast.error("Internal Server Error ! Please Try Again.", { position: 'bottom-center' });
            }

        }).catch(error => {
            cogoToast.error("Request Failed! Please Try Again.", { position: 'bottom-center' })
        })
    }

    //Deselect column after click on input
    doNotSelectColumn = (event, coords) => {
        if (coords.row === -1) {
            event.stopImmediatePropagation();
            this.hotTable.current.hotInstance.deselectCell();
        }
    }

    render() {
        var self = this;
        var { BookingHistoryData } = self.state;

        const BookingListView = BookingHistoryData.length > 0 &&
            (
                BookingHistoryData.map((bk, i) => {
                    return (
                        <tr key={i}>
                            <td data-th="Booking No.">
                                {bk.BookingNo}
                            </td>
                            <td data-th="Booking Date">
                                {bk.BookingDate}
                            </td>
                            <td data-th="Service Name">
                                {bk.ServiceName}
                            </td>
                            <td data-th="Customer Name">
                                {bk.CustomerName}
                            </td>
                            <td data-th="Appoint. Date">
                                {bk.AppointmentDate}
                            </td>
                            <td data-th="Login Time">
                                {bk.SlotStartTime}
                            </td>
                            <td data-th="Booking Status">
                                {bk.BookingStatus}
                                {/* <select
                                    name="bookingStatus"
                                    className="form-control"
                                    value={this.state.BookingStatus}
                                >
                                    {
                                        (masterServiceData && masterServiceData.length > 0) && masterServiceData.map((ms) => {
                                            return (<option key={ms.ServiceMasterId} value={ms.ServiceMasterId}> {ms.ServiceMasterName}</option>);
                                        })
                                    }
                                </select> */}
                            </td>
                        </tr>
                    )
                })
            );


        return (
            <>
                <section className="banner-inner">
                    <div className="container">
                        <nav aria-label="breadcrumb">
                            <ol className="breadcrumb">
                                <li className="breadcrumb-item"><NavLink to="/">Home</NavLink></li>
                                <li className="breadcrumb-item active" aria-current="page">Booking History</li>
                            </ol>
                        </nav>
                    </div>
                </section>
                <section id="provider-panel">
                    <div className="container-fluid">
                        <div className="heading">
                            <h3>Booking <span>History</span></h3>
                            {
                                (BookingHistoryData.length > 0) ?
                                    <h6 className="section-sub-title"><span>List of booked item(s)</span>, you may manage</h6>
                                    :
                                    <h6 className="section-sub-title">Sorry! Booking List is empty.</h6>
                            }
                        </div>
                    </div>
                </section>
                <section className="booking-list">
                    <div className="container-fluid">
                        <div className="row">
                            <div className="col-lg-12 col-md-12 col-sm-12">
                                {
                                    (BookingHistoryData !== null && BookingHistoryData.length > 0) &&
                                    <table className="rwd-table">
                                        <tbody>
                                            <tr>
                                                <th>Booking No.</th>
                                                <th>Booking Date</th>
                                                <th>Service Name</th>
                                                <th>Customer Name</th>
                                                <th>Appoint. Date</th>
                                                <th>Login Time</th>
                                                <th>Status</th>
                                            </tr>

                                            {BookingListView}

                                        </tbody>
                                    </table>
                                }
                            </div>
                        </div>

                    </div>
                </section>

            </>
        );
    }
}

export default BookingHistory;