import React, { Component } from 'react';
import axios from "axios";
import ApiURL from "../../api/ApiURL";

class ProviderService extends Component {
    constructor(props) {
        super(props);
        this.state = {
            ServiceData: [],
            checkedServices: [],
            isCheckAll: false
        };
        this.MegaMenu = this.MegaMenu.bind(this);
    }

    //componentDidMount Event   
    componentDidMount() {            
        window.scroll(0, 0);
        this.getAllProviderDataList();
    }

    MegaMenu() {
        var acc = document.getElementsByClassName("card");
        var accNum = acc.length;
        var i;
        for (i = 0; i < accNum; i++) {
            acc[i].addEventListener("click", function () {
                this.classList.toggle("active");
                var cardBody = this.children[0].nextElementSibling;
                //console.log(this.children[0].innerText);
                cardBody.classList.toggle("collapse");
            })
        }
    }

    getAllProviderDataList = () => {
        var _self = this;
        axios.get(ApiURL.GetAllProviderDataList).then(response => {
            let respData = response.data;
            if (respData.StatusCode === 200) {
                var dataResult = respData.DataResult;
                let svcData = dataResult.Services;
                this.setState({ ServiceData: svcData }, () => _self.MegaMenu())
            }
        })
    }

    /* handleSelectAll = e => {
        let chkedServices = [];
        let serviceMasterId = e.target.id.split('-')[1];
        let checkAll = !this.state.isCheckAll;
        if (checkAll == true) {
            let svcList = this.state.ServiceData;
            let childServices = [];
            for (let i = 0; i < svcList.length; i++) {
                if (svcList[i].ServiceMasterId == parseInt(serviceMasterId)) {
                    childServices = svcList[i].ChildServices;
                    break;
                }
            }
            if (childServices.length > 0) {
                childServices.map(li => chkedServices.push(li.ServiceId));
            }
        }        

        this.setState({ 
            ... this.state, 
            isCheckAll: checkAll, 
            checkedServices: chkedServices 
        },()=> console.log(this.state.checkedServices))

    } */

    handleClick = e => {
        let chkServices = this.state.checkedServices;
        const { id, checked } = e.target;
        if (!checked) {
            chkServices = this.state.checkedServices.filter(item => item !== parseInt(id));
        }
        else{
            chkServices.push(parseInt(id)); 
        }
        this.props.handleChange(chkServices);
        this.setState({
            ...this.state,
            checkedServices: chkServices
        })
    }

    render() {

        const svcList = this.state.ServiceData;
        const myServiceView = svcList.map((parentService, i) => {
            return <div className="card" key={parentService.ServiceMasterId.toString()}>
                <div className="card-header">
                    {parentService.ServiceMasterName}
                </div>
                <div className="card-body collapse">
                    <div className="sub-service-wrap">
                        <div className="custom-control custom-checkbox">
                            {/* <input
                                type="checkbox"
                                className="styled-checkbox"
                                id={"chkall-" + parentService.ServiceMasterId.toString()}
                                checked={this.state.isCheckAll}
                                onChange={this.handleSelectAll}
                            />
                            <label htmlFor={"chkall-" + parentService.ServiceMasterId.toString()} className="lbl-txt">All</label> */}
                            {parentService.ChildServices.map((childService, index) => {
                                return <div key={childService.ServiceId}>
                                    <input
                                        type="checkbox"
                                        className="styled-checkbox"
                                        id={childService.ServiceId.toString()}
                                        value={childService.ServiceName}
                                        checked={this.state.checkedServices.includes(childService.ServiceId)}
                                        onChange={this.handleClick}
                                    />
                                    <label
                                        htmlFor={childService.ServiceId}
                                        className="lbl-txt">
                                        {childService.ServiceName}
                                    </label>

                                </div>
                            })}
                        </div>

                    </div>
                </div>
            </div>
        })


        return (
            <>
                <section className="provider-reg-sec">
                    <div className="container">
                        <div className="provider-reg-info">
                            <h3>Select Services</h3>
                            <p>Select the services you offer.</p>
                        </div>
                        <div className="provider-reg-wrap">
                            <div className="accordion">
                                {myServiceView}
                            </div>
                            <div className="row">
                                <div className="col-xl-12">
                                    <div className="btn-submit-wrap d-flex justify-content-end mt-4">
                                        <input type="button" className="btn-frm-submit" value="Next" onClick={this.props.nextStep} />
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>
                </section>
            </>
        );
    }
}

export default ProviderService;