import * as localforage from "localforage";

class LocalStorageHelper {

    // Set Get Site Info Footer
    static SetSiteInfoFooter(JSONData) {
        localforage.setItem('SiteInfoFooter', JSON.stringify(JSONData));
    }
    static GetSiteInfoFooter(callback) {
        localforage.getItem('SiteInfoFooter', function (err, value) {
            if (err) {
                callback(null)
            }
            else {
                callback(JSON.parse(value))
            }
        });
    }


    // Set/Get/Remove Guest Id
    static setGuestId(GuestId) {
        localStorage.setItem("GuestId", GuestId);
    }
    static getGuestId() {
        return localStorage.getItem("GuestId");
    }
    static removeGuestId() {
        return localStorage.removeItem("GuestId");
    }


    // Set/Get/Remove User
    static setUser(UserData) {
        localStorage.setItem("User", UserData);
    }
    static getUser() {
        return localStorage.getItem("User");
    }
    static removeUser() {
        return localStorage.removeItem("User");
    }


    // Set/Get/Remove User Mobile
    static setUserMobile(UserMobile) {
        localforage.setItem("UserMobile", UserMobile)
    }
    static getUserMobile() {
        return localforage.getItem("UserMobile")
    }
    static removeUserMobile() {
        return localforage.removeItem("UserMobile")
    }


    // Set/Get User Winlocation
    static SetLastRedirectPath(winlocation) {
        sessionStorage.setItem("winlocation", winlocation)
    }

    static GetLastRedirectPath() {
        return sessionStorage.getItem("winlocation");
    }

    static removeLastRedirectPath() {
        return sessionStorage.removeItem("winlocation");
    }


    // Set/Get/Remove Service
    static setService(ServiceData) {
        sessionStorage.setItem("ServiceData", ServiceData)
    }
    static getService() {
        return sessionStorage.getItem("ServiceData")
    }
    static removeService() {
        return sessionStorage.removeItem("ServiceData")
    }


    // Set/Get/Remove Provider
    static setProvider(ProviderData) {
        sessionStorage.setItem("ProviderData", ProviderData)
    }
    static getProvider() {
        return sessionStorage.getItem("ProviderData")
    }
    static removeProvider() {
        return sessionStorage.removeItem("ProviderData")
    }


    // Set/Get/Remove Booking
    static setBooking(BookingData) {
        sessionStorage.setItem("BookingData", BookingData)
    }
    static getBooking() {
        return sessionStorage.getItem("BookingData")
    }
    static removeBooking() {
        return sessionStorage.removeItem("BookingData")
    }

    static getAccessToken() {
        let accessToken = "";
        const userData = JSON.parse(LocalStorageHelper.getUser());
        if (userData !== null) {
            accessToken = userData.Token;
        }
        return accessToken;
    }
}
export default LocalStorageHelper;