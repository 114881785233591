import React, { Component } from 'react';
import { NavLink, Redirect } from 'react-router-dom';
import ApiURL from "../../api/ApiURL";
import axios from "axios";
import cogoToast from 'cogo-toast';
import validation from '../../validation/validation'
import LocalStorageHelper from '../../LocalStorageHelper/LocalStorageHelper';

class ManageProfile extends Component {
    constructor(props) {
        super(props);
        this.state = {
            btn: 'Save Profile',
            UserId: 0,
            FullName: '',
            Email: '',
            Mobile: '',
            ProfileImageName: '',
            ProfileImageFile: null,
            CVFile: null,
            LanguageKnown: '',
            Gender: '',
            Expertise: '',
            Experience: '',
            PostCode: '',
            Address: '',
            City: '',
            SocialLink: '',
            YouTubeLink: '',
            TwitterLink: '',
            InstaLink: '',
            FaceBookLink: '',
            linkedInLink: '',
            RedirectToLogin: false,
            PageRefreshStatus: false
        }
    }

    componentDidMount() {
        var _self = this;
        var userData = JSON.parse(LocalStorageHelper.getUser());
        if (userData !== null && userData.UserId > 0) {
            this.setState({
                UserId: userData.UserId
            }, () => {
                _self.getProfileData();
            })
        }
        else {
            let winlocation = window.location.pathname;
            console.log(window.location.href);
            LocalStorageHelper.SetLastRedirectPath(winlocation);
            this.setState({ RedirectToLogin: true })
        }
    }

    componentDidUpdate(prevProps, prevState) {
        if (prevState.ProfileImageName !== this.state.ProfileImageName) {
            this.getProfileImage(this.state.ProfileImageName);
        }
    }

    getProfileData = () => {
        let fbLink = '';
        let twitLink = '';
        let instaLink = '';
        let linkedinLink = '';
        let param = {
            ProviderId: this.state.UserId
        };
        axios.post(ApiURL.GetProviderProfileData, param).then(response => {
            let respData = response.data;
            if (respData.StatusCode === 200) {
                let dataResult = respData.DataResult;
                console.log(dataResult);
                if (dataResult !== null) {
                    if (dataResult.SocialLink.length > 0) {
                        let socialLinks = dataResult.SocialLink.split(',');
                        for (var i = 0; i < socialLinks.length; i++) {
                            if (/facebook/.test(socialLinks[i]) === true)
                                fbLink = socialLinks[i];
                            else if (/twitter/.test(socialLinks[i]) === true)
                                twitLink = socialLinks[i];
                            else if (/instagram/.test(socialLinks[i]) === true)
                                instaLink = socialLinks[i];
                            else if (/linkedin/.test(socialLinks[i]) === true)
                                linkedinLink = socialLinks[i];
                        }
                    }

                    this.setState({
                        btn: 'Save Profile',
                        UserId: dataResult.UserId,
                        FullName: dataResult.FullName,
                        Email: dataResult.EmailId,
                        Mobile: dataResult.MobileNo,
                        ProfileImageName: dataResult.ProfileImageName,
                        LanguageKnown: dataResult.LanguageKnown,
                        Gender: dataResult.Gender,
                        Expertise: dataResult.Expertise,
                        Experience: dataResult.Experience,
                        PostCode: dataResult.PostCode,
                        Address: dataResult.Address,
                        City: dataResult.City,
                        SocialLink: dataResult.SocialLink,
                        YouTubeLink: dataResult.YouTubeLink,
                        FaceBookLink: fbLink,
                        TwitterLink: twitLink,
                        InstaLink: instaLink,
                        linkedInLink: linkedinLink,
                    })
                }
            }
            else if (respData.StatusCode === 500) {
                cogoToast.error("Internal Server Error ! Please Try Again.", { position: 'bottom-center' });
            }

        }).catch(error => {
            cogoToast.error("Request Failed! Please Try Again.", { position: 'bottom-center' })
        })
    }

    onChangeHandler = (e) => {
        this.setState({
            [e.target.name]: e.target.value
        });
    }

    onSelectFileHandler = (e) => {
        let fileId = e.target.id;
        let file = e.target.files[0];
        if (fileId === 'profileImage') {
            this.setState({ ...this.state, ProfileImageFile: file })
        }
        else if (fileId === 'cv') {
            this.setState({ ...this.state, CVFile: file })
        }
    }    

    extractVideoId = (targetValue) => {
        var result = '';
        const match = targetValue.match(validation.YoutubeRegx);
        if (match && match[1]) {
            result = match[1];
        }
        return result;
    }

    onSaveChanges = (e) => {
        var _self = this;
        e.preventDefault();
        var { UserId, FullName, Email, ProfileImageFile, LanguageKnown, Gender, Expertise,
            Experience, PostCode, Address, City, SocialLink, YouTubeLink, CVFile } = _self.state;

        var YouTubeVideoId = '';
        if (YouTubeLink !== '') {
            YouTubeVideoId = this.extractVideoId(YouTubeLink);
            if (YouTubeVideoId === '') {
                cogoToast.warn('Invalid YouTube link ! Please Try Another', { position: 'bottom-center' });
                return false;
            }
        }

        const formData = new FormData()
        formData.append('UserId', UserId);
        formData.append('FullName', FullName);
        formData.append('EmailId', Email);
        formData.append('Address', Address);
        formData.append('City', City);
        formData.append('PostCode', PostCode);
        formData.append('Gender', Gender);
        formData.append('LanguageKnown', LanguageKnown);
        formData.append('Expertise', Expertise);
        formData.append('Experience', Experience);
        formData.append('SocialLink', SocialLink);
        formData.append('YouTubeLink', YouTubeVideoId);
        formData.append('ProfileImageFile', ProfileImageFile);
        formData.append('CVFile', CVFile);

        _self.setState({ btn: "Processing.." });

        axios.post(ApiURL.UpdateProviderProfileData, formData).then(response => {
            let respData = response.data;
            if (respData.StatusCode === 200) {
                let dataResult = respData.DataResult;
                console.log(dataResult);
                if (dataResult === 0) {
                    cogoToast.error('Profile data updation failed !', { position: 'bottom-center' });
                    _self.setState({ btn: "Save Profile" });
                }
                else if (dataResult === 1) {
                    cogoToast.success('Profile data updated successfully !', { position: 'bottom-center' });
                    _self.getProfileData();
                }
            }
            else if (respData.StatusCode === 500) {
                _self.setState({ btn: "Save Profile" });
                cogoToast.error("Internal Server Error ! Please Try Again.", { position: 'bottom-center' });
            }

        }).catch(error => {
            _self.setState({ btn: "Save Profile" });
            cogoToast.error("Request Failed! Please Try Again.", { position: 'bottom-center' })
        })
    }

    pageRefresh = () => {
        if (this.state.PageRefreshStatus === true) {
            let winlocation = window.location.pathname;
            return <Redirect to={winlocation} />
        }
    }

    getProfileImage = (profileImageName) => {
        let profImageName = profileImageName || "p1.jpg";
        return ApiURL.ProviderImageURL + '/' + profImageName;
    }

    render() {

        return (
            <>
                <section className="banner-inner">
                    <div className="container">
                        <nav aria-label="breadcrumb">
                            <ol className="breadcrumb">
                                <li className="breadcrumb-item"><NavLink to="/provider-home">Home</NavLink></li>
                                <li className="breadcrumb-item active" aria-current="page">Manage Profile</li>
                            </ol>
                        </nav>
                    </div>
                </section>
                <section id="manage-profile">
                    <div className="container-fluid">
                        <div className="heading">
                            <h3>Manage <span>Profile</span></h3>
                            <hr />
                        </div>
                    </div>
                </section>
                <div className="container">
                    <div className="main-body">
                        <div className="row">
                            <div className="col-lg-4">
                                <div className="card">
                                    <div className="card-body">
                                        <div className="d-flex flex-column align-items-center text-center">
                                            <img src={this.getProfileImage(this.state.ProfileImageName)} alt="..." className="rounded-circle p-1 bg-success" width="110" />
                                            <div className="mt-3">
                                                <h4>{this.state.FullName}</h4>
                                                <p className="text-secondary mb-1">{this.state.Expertise}</p>
                                                <p className="text-muted font-size-sm">{this.state.Address}</p>

                                                <div className="mb-3">
                                                    {/* <input className="form-control" type="file" id="formFile" /> */}
                                                    <input
                                                        type="file"
                                                        id="profileImage"
                                                        className="form-control"
                                                        accept="image/*,.png,.jpg"
                                                        onChange={this.onSelectFileHandler}
                                                    />
                                                </div>
                                                {/* <button className="btn btn-outline-success mr-2">
                                                    <i class="fa fa-solid fa-user"></i> Change Profile Image
                                                </button>
                                                <button className="btn btn-outline-success">
                                                    <i class="fa fa-solid fa-eraser"></i> Reset
                                                </button> */}
                                            </div>
                                        </div>
                                        <ul className="list-group list-group-flush">
                                            {
                                                (this.state.FaceBookLink.length > 0) &&
                                                <li className="list-group-item d-flex justify-content-between align-items-center flex-wrap">
                                                    <a href={this.state.FaceBookLink} target='__blank'>
                                                        <h4 className="mb-0">
                                                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="feather feather-facebook me-2 icon-inline text-primary"><path d="M18 2h-3a5 5 0 0 0-5 5v3H7v4h3v8h4v-8h3l1-4h-4V7a1 1 0 0 1 1-1h3z"></path></svg>
                                                            <span className="text-secondary">Facebook</span>
                                                        </h4>
                                                    </a>
                                                </li>
                                            }
                                            {
                                                (this.state.TwitterLink.length > 0) &&
                                                <li className="list-group-item d-flex justify-content-between align-items-center flex-wrap">
                                                    <a href={this.state.TwitterLink} target='__blank'>
                                                        <h4 className="mb-0">
                                                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="feather feather-twitter me-2 icon-inline text-info"><path d="M23 3a10.9 10.9 0 0 1-3.14 1.53 4.48 4.48 0 0 0-7.86 3v1A10.66 10.66 0 0 1 3 4s-4 9 5 13a11.64 11.64 0 0 1-7 2c9 5 20 0 20-11.5a4.5 4.5 0 0 0-.08-.83A7.72 7.72 0 0 0 23 3z"></path></svg>
                                                            <span className="text-secondary">Twitter</span>
                                                        </h4>
                                                    </a>
                                                </li>
                                            }
                                            {
                                                (this.state.InstaLink.length > 0) &&
                                                <li className="list-group-item d-flex justify-content-between align-items-center flex-wrap">
                                                    <a href={this.state.InstaLink} target='__blank'>
                                                        <h4 className="mb-0">
                                                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="feather feather-instagram me-2 icon-inline text-danger"><rect x="2" y="2" width="20" height="20" rx="5" ry="5"></rect><path d="M16 11.37A4 4 0 1 1 12.63 8 4 4 0 0 1 16 11.37z"></path><line x1="17.5" y1="6.5" x2="17.51" y2="6.5"></line></svg>
                                                            <span className="text-secondary">Instagram</span>
                                                        </h4>
                                                    </a>
                                                </li>
                                            }
                                            {
                                                (this.state.linkedInLink.length > 0) &&
                                                <li className="list-group-item d-flex justify-content-between align-items-center flex-wrap">
                                                    <a href={this.state.linkedInLink} target='__blank'>
                                                        <h4 className="mb-0">
                                                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="feather feather-instagram me-2 icon-inline text-danger"><rect x="2" y="2" width="20" height="20" rx="5" ry="5"></rect><path d="M16 11.37A4 4 0 1 1 12.63 8 4 4 0 0 1 16 11.37z"></path><line x1="17.5" y1="6.5" x2="17.51" y2="6.5"></line></svg>
                                                            <span className="text-secondary">Linked in</span>
                                                        </h4>
                                                    </a>
                                                </li>
                                            }
                                            {
                                                (this.state.YouTubeLink.length > 0) &&
                                                <li className="list-group-item d-flex justify-content-between align-items-center flex-wrap">
                                                    <div className="video-responsive">
                                                        <iframe
                                                            width="300"
                                                            height="180"
                                                            src={`https://www.youtube.com/embed/${this.state.YouTubeLink}`}
                                                            frameBorder="0"
                                                            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                                                            allowFullScreen
                                                            title="Embedded youtube"
                                                        />
                                                    </div>
                                                </li>
                                            }
                                        </ul>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-8">
                                <form>
                                    <div className="card">
                                        <div className="card-body">
                                            <div className="form-group">
                                                <label htmlFor="FirstName">Full Name:</label>
                                                <input type="text" name="FullName" className="form-control" placeholder='Full Name' value={this.state.FullName} disabled={true} onChange={this.onChangeHandler} />
                                            </div>
                                            <div className="form-group">
                                                <label htmlFor="Email">Email:</label>
                                                <input type="text" name="Email" className="form-control" placeholder='Email' value={this.state.Email} disabled={true} onChange={this.onChangeHandler} />
                                            </div>
                                            <div className="form-group">
                                                <label htmlFor="Mobile">Mobile:</label>
                                                <input type="text" name="Mobile" className="form-control" placeholder='Mobile' value={this.state.Mobile} disabled={true} onChange={this.onChangeHandler} />
                                            </div>
                                            <div className="form-group">
                                                <label htmlFor="LanguageKnown">Language Known: <i className='text-danger'>*</i></label>
                                                <input type="text" name="LanguageKnown" className="form-control" value={this.state.LanguageKnown} onChange={this.onChangeHandler} />
                                            </div>
                                            <div className="form-group">
                                                <label htmlFor="Gender">Gender: <i className='text-danger'>*</i></label>
                                                <select name="Gender" className="form-control" value={this.state.Gender} onChange={this.onChangeHandler}>
                                                    <option value=''>-- Select Gender --</option>
                                                    <option value='M'>Male</option>
                                                    <option value='F'>Female</option>
                                                    <option value='T'>Transgender</option>
                                                </select>
                                            </div>
                                            <div className="form-group">
                                                <label htmlFor="Expertise">Expertise: <i className='text-danger'>*</i></label>
                                                <input type="text" name="Expertise" className="form-control" placeholder='Expertise' value={this.state.Expertise} onChange={this.onChangeHandler} />
                                            </div>
                                            <div className="form-group">
                                                <label htmlFor="Experience">Year of Experience:</label>
                                                <input type="text" name="Experience" className="form-control" placeholder='Year of Experience' value={this.state.Experience} onChange={this.onChangeHandler} />
                                            </div>
                                            <div className="form-group">
                                                <label htmlFor="Experience">Post Code: <i className='text-danger'>*</i></label>
                                                <input type="text" name="PostCode" className="form-control" placeholder='Post Code' value={this.state.PostCode} onChange={this.onChangeHandler} />
                                            </div>
                                            <div className="form-group">
                                                <label htmlFor="Experience">Address:</label>
                                                <input type="text" name="Address" className="form-control" placeholder='Address' value={this.state.Address} onChange={this.onChangeHandler} />
                                            </div>
                                            <div className="form-group">
                                                <label htmlFor="Experience">City:</label>
                                                <input type="text" name="City" className="form-control" placeholder='City' value={this.state.City} onChange={this.onChangeHandler} />
                                            </div>
                                            <div className="form-group">
                                                <label htmlFor="Experience">Social Link (Enter Social media link seperated by comma(,)):</label>
                                                <textarea name="SocialLink"
                                                    rows={6}
                                                    cols={12}
                                                    className="form-control"
                                                    placeholder='Enter Social media link seperated by comma(,)'
                                                    value={this.state.SocialLink}
                                                    onChange={this.onChangeHandler} />
                                            </div>
                                            <div className="form-group">
                                                <label htmlFor="Experience">YouTube Link :</label>
                                                <input type="text" name="YouTubeLink" className="form-control" placeholder='YouTube Embed Id' value={this.state.YouTubeLink} onChange={this.onChangeHandler} />
                                            </div>
                                            <div className="form-group">
                                                <label htmlFor="Experience">Upload CV :</label>
                                                <input
                                                        type="file"
                                                        id="cv"
                                                        className="form-control"
                                                        accept=".pdf"
                                                        onChange={this.onSelectFileHandler}
                                                    />
                                            </div>
                                            
                                            <div className="form-group float-end">
                                                <button type="button" className="btn btn-outline-success mr-2" onClick={this.onSaveChanges}>
                                                    <i className="fa fa-floppy-o"></i> {this.state.btn}
                                                </button>
                                                {/* <button className="btn btn-outline-success">
                                                    <i class="fa fa-solid fa-eraser"></i> Reset
                                                </button> */}
                                            </div>
                                        </div>
                                    </div>
                                </form>
                            </div>

                        </div>
                    </div>
                </div >
                {this.pageRefresh()}
            </>
        );
    }
}

export default ManageProfile;