import React, { Component, Fragment } from "react";
import { Route, Switch } from "react-router";
import AboutPage from "../pages/AboutPage";
import ContactPage from "../pages/ContactPage";
import CustomerRegistrationPage from "../pages/CustomerRegistrationPage";
import HomePage from "../pages/HomePage";
import LoginPage from "../pages/LoginPage";
import PolicyPage from "../pages/PolicyPage";
import PurchasePage from "../pages/PurchasePage";
import VerifyOTPPage from "../pages/VerifyOTPPage";
import ProviderRegistrationPage from "../pages/ProviderRegistrationPage";
import CartPage from "../pages/CartPage";
import SelectProviderPage from "../pages/SelectProviderPage";
import ProviderProfilePage from "../pages/ProviderProfilePage";
import ServiceDeliveryAddressPage from "../pages/ServiceDeliveryAddressPage";
import BookingSummaryPage from "../pages/BookingSummaryPage";
import BookingConfirmationPage from "../pages/BookingConfirmationPage";
import BookingHistoryPage from "../pages/BookingHistoryPage";
import ProtectedRoute from "../pages/ProtectedRoutes";
import ServiceListByCategory from "../pages/ServiceListByCategory";
import UnauthorizedPage from "../pages/UnauthorizedPage";
import CancellationPage from "../pages/CancellationPage";
import BookingTermsAndConditionPage from "../pages/BookingTermsAndConditionPage";
import CookiesPolicyPage from "../pages/CookiesPolicyPage";
import SearchServicePage from "../pages/SearchServicePage";

class AppRoute extends Component {
  constructor(props) {
    super(props);
     this.state={
      checkUserType: props.checkUserType
    }
  }

  render() {
    return (
      <Fragment>
        <Switch>
          <Route
            exact
            path="/"
            render={props => <HomePage {...props} checkUserType={this.state.checkUserType} key={Date.now()} />}
          />
          <Route
            exact
            path="/home"
            render={props => <HomePage {...props} checkUserType={this.state.checkUserType} key={Date.now()} />}
          />
          <Route
            exact
            path="/contact"
            render={props => <ContactPage {...props} key={Date.now()} />}
          />
          <Route
            exact
            path="/purchase"
            render={props => <PurchasePage {...props} key={Date.now()} />}
          />
          <Route
            exact
            path="/policy"
            render={props => <PolicyPage {...props} key={Date.now()} />}
          />
          <Route
            exact
            path="/cancellation"
            render={props => <CancellationPage {...props} key={Date.now()} />}
          />
          <Route
            exact
            path="/btc"
            render={props => <BookingTermsAndConditionPage {...props} key={Date.now()} />}
          />
          <Route
            exact
            path="/cookies-policy"
            render={props => <CookiesPolicyPage {...props} key={Date.now()} />}
          />
          <Route
            exact
            path="/about"
            render={props => <AboutPage {...props} key={Date.now()} />}
          />
          <Route
            exact
            path="/customer-reg"
            render={props => <CustomerRegistrationPage {...props} key={Date.now()} />}
          />
          <Route
            exact
            path="/provider-reg"
            render={props => <ProviderRegistrationPage {...props} key={Date.now()} />}
          />
          <Route
            exact
            path="/login"
            render={props => <LoginPage {...props} key={Date.now()} />}
          />
          <Route
            exact
            path="/unauthorize"
            render={props => <UnauthorizedPage {...props} key={Date.now()} />}
          />
          <Route
            exact
            path="/verifyotp/:mobileNo"
            render={(props) => <VerifyOTPPage {...props} checkUserType={this.state.checkUserType} key={Date.now()} />
            }
          />
          <Route
            exact
            path="/service-by-category/:Category"
            render={(props) => <ServiceListByCategory {...props} key={Date.now()} />}
          />
          <Route
            exact
            path="/wishlist"
            render={props => <CartPage {...props} key={Date.now()} />}
          />
          <Route
            exact
            path="/search"
            render={props => <SearchServicePage {...props} key={Date.now()} />}
          />
          
          <ProtectedRoute
            exact
            path="/service-delilvery-address"
            component={ServiceDeliveryAddressPage}
          />
          <ProtectedRoute
            exact
            path="/provider"
            component={SelectProviderPage}
          />
          <ProtectedRoute
            exact
            path="/provider-profile"
            component={ProviderProfilePage}
          />
          <ProtectedRoute
            exact
            path="/booking-summary"
            component={BookingSummaryPage}
          />
          <ProtectedRoute
            exact
            path="/booking-confirmation"
            component={BookingConfirmationPage}
          />
          <ProtectedRoute
            exact
            path="/booking-history"
            component={BookingHistoryPage}
          />
          

        </Switch>
      </Fragment>
    );
  }
}

export default AppRoute;
