import React, { Component } from 'react';
import { NavLink } from 'react-router-dom';
import { Redirect } from 'react-router-dom';
import * as signalR from '@microsoft/signalr';
import axios from "axios";
import ApiURL from "../../api/ApiURL";
import cogoToast from "cogo-toast";
import LocalStorageHelper from '../../LocalStorageHelper/LocalStorageHelper';
import validation from '../../validation/validation';

class CustomerRegistration extends Component {
    constructor(props) {
        super(props);
        this.state = {
            btn: "Sign up with OTP",
            firstName: '',
            lastName: '',
            email: '',
            mobile: '',
            redirectOTPStatus: false,
        }
    }

    componentDidMount() {
        let connection = new signalR.HubConnectionBuilder().withUrl(ApiURL.BaseURL + "/notificationsHub", {
          skipNegotiation: true,
          transport: signalR.HttpTransportType.WebSockets
        }).build();
    
        connection.start().then(() => console.log("connected!")).catch(err => console.log(err));
    
        connection.on("Connected", function (message) {
          console.log('Hub Connected...');
        });
    
        /* connection.on("SendMessage", function (data, statusCode) {
          if (statusCode === "Succeeded") {
            console.log(data);
          }
        }); */
      }

    onChangeHandler = (e) => {
        this.setState({
            [e.target.name]: e.target.value
        })
    }

    signUp = (e) => {
        e.preventDefault();

        let firstName = this.state.firstName;
        let lastName = this.state.lastName;
        let mobileNo = this.state.mobile;
        let email = this.state.email;
        let appType = 'W';

        if (firstName.length === 0) {
            cogoToast.error("First Name Required.", { position: 'bottom-center' });
        }
        else if (lastName.length === 0) {
            cogoToast.error("Last Name Required.", { position: 'bottom-center' });
        }
        else if (mobileNo.length === 0) {
            cogoToast.error("Mobile Number Required.", { position: 'bottom-center' });
        }
        else if (mobileNo.length !== 0 && !(validation.MobileRegx).test(mobileNo)) {
            cogoToast.error("Invalid Mobile No.", { position: 'bottom-center' });
        }
        else if (email.length === 0) {
            cogoToast.error("Email Required!", { position: 'bottom-center' });            
        }
        else if (email.length > 0 && !(validation.EmailRegx).test(email)) {
            cogoToast.error("Invalid Email.", { position: 'bottom-center' });
        }
        else {
            //Retrieve user info from local storage and update by api data
            this.setState({ btn: "Processing.." });

            var param = {
                FirstName: firstName,
                LastName: lastName,
                Email: email,
                Mobile: mobileNo,
                ApplicationType: appType
            };

            axios.post(ApiURL.CustomerRegistration, param).then(response => {
                let respData = response.data;
                if (respData.StatusCode === 200) {
                    let dataResult = respData.DataResult;                    
                    if (dataResult === -1) {
                        cogoToast.error('User already exists !', { position: 'bottom-center' });
                    }
                    else if (dataResult === 0) {
                        cogoToast.error('User could not be added !', { position: 'bottom-center' });
                    }
                    else if (dataResult > 0) {
                        LocalStorageHelper.removeUser();
                        var userData = param;
                        console.log(userData);
                        LocalStorageHelper.setUser(JSON.stringify(userData));
                        this.setState({ btn: "Sign up with OTP", redirectOTPStatus: true })
                    }
                    else if (dataResult === 2){
                        cogoToast.warn('Registration completed with OTP sending error ! Please contact to your System Administrator.', { position: 'bottom-center' });
                    }

                } else if (respData.StatusCode === 400) {
                    this.setState({ btn: "Sign up with OTP" });
                    cogoToast.error(respData.Message, { position: 'bottom-center' });

                } else if (respData.StatusCode === 500) {
                    this.setState({ btn: "Sign up with OTP" });
                    cogoToast.error("Internal Server Error ! Please Try Again.", { position: 'bottom-center' });
                }

            }).catch(error => {
                this.setState({ btn: "Sign up with OTP" });
                cogoToast.error("Request Failed! Please Try Again.", { position: 'bottom-center' })
            })
        }

    }

    RedirectToOTPPage = () => {
        if (this.state.redirectOTPStatus === true) {
            let url = "/verifyotp/" + this.state.mobile;
            return <Redirect to={url} />
        }
    }

    render() {
        const userImgURL = ApiURL.BaseImageURL + "/user.jpg";
        return (
            <>
                <section className="banner-inner">
                    <div className="container">
                        <nav aria-label="breadcrumb">
                            <ol className="breadcrumb">
                                <li className="breadcrumb-item"><NavLink to="/">Home</NavLink></li>
                                <li className="breadcrumb-item active" aria-current="page">User Registration</li>
                            </ol>
                        </nav>
                    </div>
                </section>
                <section className="user-reg-sec">
                    <div className="container">
                        <div className="row">
                            <div className="col-xl-7">
                                <div className="user-reg-img-wrap">
                                    <img src={userImgURL} className="img-fluid" alt="..." />
                                </div>
                            </div>
                            <div className="col-xl-5">
                                <div className="user-reg-frm-wrap">
                                    <h4>User Registration</h4>
                                    <div className="form-floating mb-3">
                                        <input type="text" name="firstName" className="form-control" placeholder="First Name *" onChange={this.onChangeHandler} autoComplete={0} />
                                        <label htmlFor="floatingInput">First Name *</label>
                                    </div>
                                    <div className="form-floating mb-3">
                                        <input type="text" name="lastName" className="form-control" placeholder="Last Name *" onChange={this.onChangeHandler} autoComplete={0} />
                                        <label htmlFor="floatingInput">Last Name *</label>
                                    </div>
                                    <div className="form-floating mb-3">
                                        <input type="text" name="mobile" className="form-control" placeholder="Mobile *" onChange={this.onChangeHandler} autoComplete={0} />
                                        <label htmlFor="floatingInput">Mobile *</label>
                                    </div>
                                    <div className="form-floating mb-3">
                                        <input type="text" name="email" className="form-control" placeholder="Email (optional)" onChange={this.onChangeHandler} autoComplete={0} />
                                        <label htmlFor="floatingInput">Email *</label>
                                    </div>
                                    <div className="frm-ctrl-wrap mb-3">
                                        <input type="submit" className="btn-submit" value={this.state.btn} onClick={(e) => this.signUp(e)} />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                {this.RedirectToOTPPage()}
            </>
        );
    }
}

export default CustomerRegistration;