class ApiURL {
  ////==========================================================================
  ////Base URL Section
  ////==========================================================================
  ////Local
  //static BaseURL = "http://localhost:5073";

  ////Development
  //static BaseURL = "http://77.68.124.81:8080";  
  //static BaseURL = "http://103.168.18.29:8086";

  ////Production
  //static BaseURL = "http://213.171.210.197:8080;
  static BaseURL = "https://service.myboogy.co.uk";  

  ////==========================================================================
  ////Image URL Section
  ////==========================================================================
  static BaseProviderDocURL = this.BaseURL + "/uploadfiles/providerDocs";
  static BaseImageURL = this.BaseURL + "/uploadfiles/images";
  static LogoImageURL = this.BaseImageURL + "/Logo";
  static HomeBannerImageURL = this.BaseImageURL + "/HomeBanner";
  static HomeServiceImageURL = this.BaseImageURL + "/CategoryService";
  static BsetOfferImageURL = this.BaseImageURL + "/Service";
  static PopularServiceImageURL = this.BaseImageURL + "/Service";
  static OtherServiceImageURL = this.BaseImageURL + "/Service";
  static MarketplaceImageURL = this.BaseImageURL + "/Marketplace";
  static ClientTestimonialImageURL = this.BaseImageURL + "/Testimonial";
  static ProviderImageURL = this.BaseImageURL + "/Provider";

  ////===========================================================================
  ////API URL Section
  ////===========================================================================
  //Customer APIs
  static CustomerRegistration = this.BaseURL + "/api/customer/customerRegistration";

  //Auth APIs
  static UserLogin = this.BaseURL + "/api/auth/userLogin";
  static OTPVerification = this.BaseURL + "/api/auth/validateOTP";
  static ResendOTP = this.BaseURL + "/api/auth/resendOTP";
  static GetUserProfile = this.BaseURL + "/api/auth/getUserProfile";

  //Provider APIs
  static ProviderRegistration = this.BaseURL + "/api/provider/providerRegistration";
  static GetProviderProfileData =this.BaseURL +'/api/provider/getProviderProfileData';
  static GetAllRegisteredArea =this.BaseURL +'/api/provider/getAllRegisteredArea';
  static UpdateProviderProfileData =this.BaseURL +'/api/provider/updateProviderProfile';
  static GetAllProviderDataList = this.BaseURL + "/api/provider/getAllProviderDataList";
  static GetAllRegisteredServiceList = this.BaseURL + "/api/provider/getAllProviderDataList";
  static GetAllProvider = this.BaseURL + "/api/provider/getAllProvider";
  static GetServiceProviderRates = this.BaseURL + "/api/provider/GetProviderServiceRates";
  static UpdateServiceProviderRates = this.BaseURL + "/api/provider/UpdateServiceProviderRates";
  static GetProviderSlots = this.BaseURL + "/api/provider/getProviderSlots";
  static SelectProviderSlot = this.BaseURL + "/api/provider/selectProviderSlot";
  static GetProviderServiceSlotCalendar = this.BaseURL + "/api/provider/GetProviderServiceSlotCalendar";
  static UpdateProviderServiceSlotCalendar = this.BaseURL + "/api/provider/UpdateProviderServiceSlotCalendar";
  static UpdateProviderPostCodes = this.BaseURL + "/api/provider/UpdateProviderPostCode";
  static UpdateProviderServices = this.BaseURL + "/api/provider/UpdateProviderServices";
  
  static GetRegisteredMasterServicesByProvider(providerId) {
    return this.BaseURL + "/api/provider/getRegisteredMasterServicesByProvider/" + providerId;
  }

  static GetRegisteredServices(providerId) {
    return this.BaseURL + "/api/provider/getRegisteredServices/" + providerId;
  }


  //Service APIs
  static SearchService = this.BaseURL + "/api/Service/getServicesBySearch";
  static GetHomeServices = this.BaseURL + "/api/service/getHomeServices";
  static GetPopularServices = this.BaseURL + "/api/service/getPopularServices";
  static ContactUs = this.BaseURL + "/api/service/contactUs";
  static GetMasterServiceList(recordsTake) {
    return this.BaseURL + "/api/service/getMasterServices/" + recordsTake;
  }

  static GetServiceListByMaster(serviceMasterId) {
    return this.BaseURL + "/api/service/serviceListByMaster/" + serviceMasterId;
  }

  static ServiceListByCategory(categoryName) {
    return this.BaseURL + "/api/service/serviceListByCategory/" + categoryName;
  }

  //Cart APIs
  static AddServiceItemToCart = this.BaseURL + "/api/Cart/addEditCartItem";
  static RemoveCartItem = this.BaseURL + "/api/Cart/removeCartItem";
  static GetCartListCount = this.BaseURL + "/api/cart/getCartListCount";
  static GetCartDetails = this.BaseURL + "/api/cart/getCartDetails";
  static GetSlotAddressDataList = this.BaseURL + "/api/cart/getSlotAddressList";
  static AddSlotAddress = this.BaseURL + "/api/cart/addSlotAddress";
  static SetPrimaryAddress = this.BaseURL + "/api/cart/setPrimaryAddress";
  static SetServiceDeliveryAddress = this.BaseURL + "/api/cart/setServiceDeliveryAddress";
  static GetCartSummary = this.BaseURL + "/api/cart/getCartSummary";

  //Booking APIs
  static BookAppointment = this.BaseURL + "/api/booking/bookAppointment";
  static GetBookingHistory = this.BaseURL + "/api/booking/getBookingHistory";
  static CancelBooking = this.BaseURL + "/api/booking/cancelBooking";


}

export default ApiURL;
