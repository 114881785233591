import React, { Component } from 'react';
import ApiURL from "../../api/ApiURL";
import { NavLink } from 'react-router-dom';
import axios from "axios";
import cogoToast from 'cogo-toast';
import LocalStorageHelper from '../../LocalStorageHelper/LocalStorageHelper';

class PopularService extends Component {    

    componentDidMount() {
        /* const popularServiceDataList = [
            {
                ServiceId: 1,
                ImageName: "service_1.jpg",
                AllternateText: "offer 1",
                Title: "Ac repairing service 1",
                Description: 'Lorem Ipsum is simply dummy text of the printing and typesetting industry.',
                Rating: '4.9 (506)',
                Price: '$10'
            },
            {
                ServiceId: 2,
                ImageName: "service_1.jpg",
                AllternateText: "offer 2",
                Title: "Ac repairing service 2",
                Description: 'Lorem Ipsum is simply dummy text of the printing and typesetting industry.',
                Rating: '4.9 (506)',
                Price: '$10'

            },
            {
                ServiceId: 3,
                ImageName: "service_1.jpg",
                AllternateText: "offer 3",
                Title: "Ac repairing service 3",
                Description: 'Lorem Ipsum is simply dummy text of the printing and typesetting industry.',
                Rating: '4.9 (506)',
                Price: '$10'
            },
            {
                ServiceId: 4,
                ImageName: "service_2.jpg",
                AllternateText: "offer 4",
                Title: "Ac repairing service 4",
                Description: 'Lorem Ipsum is simply dummy text of the printing and typesetting industry.',
                Rating: '4.9 (506)',
                Price: '$10'
            },
            {
                ServiceId: 5,
                ImageName: "service_2.jpg",
                AllternateText: "offer 5",
                Title: "Ac repairing service 5",
                Description: 'Lorem Ipsum is simply dummy text of the printing and typesetting industry.',
                Rating: '4.9 (506)',
                Price: '$10'
            },
            {
                ServiceId: 6,
                ImageName: "service_2.jpg",
                AllternateText: "offer 6",
                Title: "Ac repairing service 6",
                Description: 'Lorem Ipsum is simply dummy text of the printing and typesetting industry.',
                Rating: '4.9 (506)',
                Price: '$10'
            },

        ]; 

        this.setState({ PopularServiceData: popularServiceDataList })
        
        */
    }

    bookService = (e, serviceId) => {
        e.preventDefault();
        var _self = this;
        var param = {
            ServiceId: serviceId,
            ApplicationType: 'W',
            UserId:0,
            GuestId:0,
        };

        if (JSON.parse(LocalStorageHelper.getUser()) !== null) {
            let userData = JSON.parse(LocalStorageHelper.getUser());
            if (userData.UserId > 0) {
                param.UserId = userData.UserId;
            }
            if (userData.GuestId > 0) {
                param.GuestId = userData.GuestId;
            }

        }
        else {
            var guestId = Math.floor(100000 + Math.random() * 900000);
            param.GuestId = guestId;
            let userData = {
                GuestId: guestId,
                UserId: 0
            };
            LocalStorageHelper.setUser(JSON.stringify(userData));
            _self.setState({ ..._self.state, GuestId: guestId });
        }

        this.addServiceToCart(param);
    }

    addServiceToCart = (param) => {
        axios.post(ApiURL.AddServiceItemToCart, param).then(response => {
            let respData = response.data;
            if (respData.StatusCode === 200) {
                let dataResult = respData.DataResult;
                if (dataResult === -3) {
                    cogoToast.error('Selected Service not available !', { position: 'bottom-center' });
                }
                if (dataResult === -2) {
                    cogoToast.error('Selected Service already exists into the cart !', { position: 'bottom-center' });
                }
                if (dataResult === -1) {
                    cogoToast.error('Customer Id not provided !', { position: 'bottom-center' });
                }
                else if (dataResult === 0) {
                    cogoToast.error('Selected Service could not be added into the cart !', { position: 'bottom-center' });
                }
                else if (dataResult > 0) {
                    cogoToast.success('Selected Service successfully added into the cart !', { position: 'bottom-center' });
                }
            }
            else if (respData.StatusCode === 500) {
                cogoToast.error("Internal Server Error ! Please Try Again.", { position: 'bottom-center' });
            }

        }).catch(error => {
            cogoToast.error("Request Failed! Please Try Again.", { position: 'bottom-center' })
        })
    }

    render() {

        const popularServiceDataList = this.props.PopularServices;
        const MyView = popularServiceDataList.length > 0 ?
            (
                popularServiceDataList.map((serviceItem, i) => {
                    const serviceImgURL = ApiURL.PopularServiceImageURL + "/" + serviceItem.ImageName;
                    return (
                        <div className="col-lg-3 col-md-6 col-sm-12" key={i}>
                            <div className="card-design">
                                <div className="card-img-box">
                                    <img src={serviceImgURL} alt="..." />
                                </div>
                                <div className="card-info">
                                    <h6>{serviceItem.ServiceName}</h6>                                    
                                    <p>{serviceItem.Description}</p>
                                    <div className="card-footer">
                                        <NavLink to={'#'} className="small-btn" onClick={(e) => this.bookService(e, serviceItem.ServiceId)}>Book Now</NavLink>
                                    </div>
                                </div>
                            </div>
                        </div>
                    )
                })
            ) :
            (<div></div>)

        return (
            <>
                <section id="popular-services">
                    <div className="container">
                        <div className="heading">
                            <h3>Popular <span>Services</span></h3>
                        </div>
                        <div className="row">
                            {MyView}
                        </div>
                    </div>
                </section>
            </>
        );
    }
}

export default PopularService;