import React, { Component } from 'react';
import { Link, NavLink, Redirect } from 'react-router-dom';
import axios from "axios";
import ApiURL from "../../api/ApiURL";
import cogoToast from 'cogo-toast';
import LocalStorageHelper from '../../LocalStorageHelper/LocalStorageHelper';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css'
import 'slick-carousel/slick/slick-theme.css'

class ServiceDeliveryAddress extends Component {
    constructor(props) {
        super(props);
        this.state = {
            btn: 'Submit',
            btnSelectProvider: 'Select Provider',
            showAddressBox: false,
            UserId: 0,
            ServiceId: 0,
            FirstName: '',
            LastName: '',
            Mobile: '',
            Email: '',
            AddressLine1: '',
            AddressLine2: '',
            AddressType: 'H',
            PostCode: '',
            Landmark: '',
            LoggedIn: false,
            SlotAddressData: [],
            RedirectToLogin: false,
            redirectProviderListStatus: false,
        }
    }

    componentDidMount() {
        var _self = this;
        var userData = JSON.parse(LocalStorageHelper.getUser());
        if (userData !== null && userData.UserId > 0) {
            var svcData = JSON.parse(LocalStorageHelper.getService());
            if (svcData !== null && svcData.ServiceId > 0) {

                _self.getSlotAddresses(userData.UserId);
                let userLoggedInState = (userData.UserId > 0) ? true : false;
                let firstName = userData.DisplayName.split(' ')[0];
                let lastName = userData.DisplayName.split(' ')[1];

                _self.setState({
                    ..._self.state,
                    UserId: userData.UserId,
                    ServiceId: svcData.ServiceId,
                    btnSelectProvider: 'Select Provider',
                    FirstName: firstName,
                    LastName: lastName,
                    Mobile: userData.Mobile,
                    Email: userData.Email,
                    AddressLine1: '',
                    AddressLine2: '',
                    AddressType: 'H',
                    PostCode: '',
                    Landmark: '',
                    LoggedIn: userLoggedInState,
                    SlotAddressData: [],
                    RedirectToLogin: false,
                    redirectProviderListStatus: false,
                });
            }
        }
        else{
            let winlocation = window.location.pathname;
            console.log(window.location.href);
            LocalStorageHelper.SetLastRedirectPath(winlocation);
            this.setState({ RedirectToLogin: true })
        }
    }

    showAddressBox = (e) => {
        e.preventDefault();
        this.setState({ showAddressBox: true })
    }

    getSlotAddresses = (userId) => {
        var param = { UserId: userId };
        axios.post(ApiURL.GetSlotAddressDataList, param).then(response => {
            let respData = response.data;
            if (respData.StatusCode === 200) {
                let dataResult = respData.DataResult;
                console.log(dataResult);
                this.setState({ SlotAddressData: dataResult })
            }
            else if (respData.StatusCode === 500) {
                cogoToast.error("Internal Server Error ! Please Try Again.", { position: 'bottom-center' });
            }

        }).catch(error => {
            cogoToast.error("Request Failed! Please Try Again.", { position: 'bottom-center' })
        })
    }

    onChangeHandler = (e) => {
        this.setState({
            [e.target.name]: e.target.value
        })
    }

    onAddAddressHandler = (e) => {
        e.preventDefault();

        if (this.state.AddressLine1.length === 0) {
            cogoToast.error("Address Line one Required.", { position: 'bottom-center' });
        }
        else if (this.state.PostCode.length === 0) {
            cogoToast.error("Postcode Required.", { position: 'bottom-center' });
        }        
        else {
            this.setState({ btn: "Processing.." });

            let addLine = this.state.AddressLine1;
            if (this.state.AddressLine2.trim() !== "") {
                addLine += " " + this.state.AddressLine2;
            }
            var param = {
                UserId: this.state.UserId,
                PostCode: this.state.PostCode,
                AddressLine: addLine,
                AddressType: this.state.AddressType,
                Landmark: this.state.Landmark,
                UpdatedBy: this.state.UserId
            };

            axios.post(ApiURL.AddSlotAddress, param).then(response => {
                let respData = response.data;
                if (respData.StatusCode === 200) {
                    let dataResult = respData.DataResult;
                    if (dataResult > 0) {
                        /* var userData = JSON.parse(LocalStorageHelper.getUser());
                        if (userData !== null && userData.UserId > 0) {
                            
                        }      */  
                        this.getSlotAddresses(this.state.UserId);                 
                        cogoToast.success("Address added successfully!", { position: 'bottom-center' })
                        this.setState({ btn: "Submit" })
                    }
                    else if (dataResult === 0) {
                        cogoToast.error('Address could not be added !', { position: 'bottom-center' });
                        this.setState({ btn: "Submit" })
                    }
                }
                else if (respData.StatusCode === 500) {
                    cogoToast.error("Internal Server Error ! Please Try Again.", { position: 'bottom-center' });
                    this.setState({ btn: "Submit" })
                }

            }).catch(error => {
                cogoToast.error("Request Failed! Please Try Again.", { position: 'bottom-center' })
                this.setState({ btn: "Submit" })
            })
        }

    }

    setPrimaryAddress = (e, address) => {
        e.preventDefault();
        if (window.confirm('Are you sure to change the Primary Address?')) {
            var param = {
                UserId: this.state.UserId,
                AddressId: address.AddressId,
                UpdatedBy: this.state.UserId
            };
            axios.post(ApiURL.SetPrimaryAddress, param).then(response => {
                let respData = response.data;
                if (respData.StatusCode === 200) {
                    let dataResult = respData.DataResult;
                    if (dataResult > 0) {
                        this.getSlotAddresses(param.UserId);
                        cogoToast.success("Primary Address set successfully!", { position: 'bottom-center' })
                    }
                }
                else if (respData.StatusCode === 500) {
                    cogoToast.error("Internal Server Error ! Please Try Again.", { position: 'bottom-center' });
                }

            }).catch(error => {
                cogoToast.error("Request Failed! Please Try Again.", { position: 'bottom-center' })
            })
        }
    }

    submitServiceDeliveryAddress = (e) => {
        e.preventDefault();
        if (window.confirm('Do you want to save the Primary Address as a Service Delivery Address?')) {
            this.setState({ btnSelectProvider: 'Processing..' })
            let primaryAddress = this.state.SlotAddressData.filter((item) => item.IsPrimary === 1);
            console.log(primaryAddress);
            if (primaryAddress.length > 0) {
                let primeAdd = primaryAddress[0];
                var param = {
                    UserId: this.state.UserId,
                    ServiceId: this.state.ServiceId,
                    AddressId: primeAdd.AddressId,
                    POstCode: primeAdd.PostCode
                };
                axios.post(ApiURL.SetServiceDeliveryAddress, param).then(response => {
                    let respData = response.data;
                    if (respData.StatusCode === 200) {
                        let dataResult = respData.DataResult;
                        if (dataResult > 0) {
                            cogoToast.success("Primary Address set successfully!", { position: 'bottom-center' })
                            this.setState({
                                btnSelectProvider: 'Select Provider',
                                redirectProviderListStatus: true
                            })
                        }
                    }
                    else if (respData.StatusCode === 500) {
                        cogoToast.error("Internal Server Error ! Please Try Again.", { position: 'bottom-center' });
                        this.setState({ btnSelectProvider: 'Select Provider' })
                    }

                }).catch(error => {
                    cogoToast.error("Request Failed! Please Try Again.", { position: 'bottom-center' })
                    this.setState({ btnSelectProvider: 'Select Provider' })
                })
            }
        }
    }

    pageRedirectToLogin = () => {
        if (this.state.RedirectToLogin === true) {
            return <Redirect to="/login" />
        }
    }

    redirectProviderListPage = () => {
        if (this.state.redirectProviderListStatus === true) {
            let url = "/provider";
            return <Redirect to={url} />
        }
    }

    render() {
        const settings = {
            arrows: true,
            dots: false,
            infinite: false,
            speed: 500,
            slidesToShow: 3,
            slidesToScroll: 1,
            responsive: [
                {
                  breakpoint: 1024,
                  settings: {
                    slidesToShow: 3,
                    slidesToScroll: 3,
                    infinite: true,
                    dots: true
                  }
                },
                {
                  breakpoint: 600,
                  settings: {
                    slidesToShow: 2,
                    slidesToScroll: 2,
                    initialSlide: 2
                  }
                },
                {
                  breakpoint: 480,
                  settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1
                  }
                }
              ]
        };

        const sliderList = this.state.SlotAddressData;
        const MyView = sliderList.length > 0 ?
            (sliderList.map((sliderItem, i) => {
                return (<div className="swiper-slide" key={i}>
                    <div className={sliderItem.IsPrimary === 1 ? "address-box-wrap adress_selected" : "address-box-wrap"}>
                        <h4 className="address-name">{sliderItem.FullName}</h4>
                        <p>
                            {sliderItem.AddressLine}<br />
                            Postcode - {sliderItem.PostCode},<br/>
                            {
                                (sliderItem.Landmark!=='') &&'Landmark -'+sliderItem.Landmark
                            }
                            
                        </p>
                        <Link to={"/"} className="edit-address-btn" onClick={(e) => this.setPrimaryAddress(e, sliderItem)}>
                            <i className="fa fa-pencil-square-o"></i>
                        </Link>
                    </div>
                </div>
                )
            })) :
            (<div></div>)

        return (
            <>
                <section className="banner-inner">
                    <div className="container">
                        <nav aria-label="breadcrumb">
                            <ol className="breadcrumb">
                                <li className="breadcrumb-item"><NavLink to="/">Home</NavLink></li>
                                <li className="breadcrumb-item"><NavLink to="/wishlist">Wishlist</NavLink></li>
                                <li className="breadcrumb-item active" aria-current="page">Delivery Address</li>
                            </ol>
                        </nav>
                    </div>
                </section>
                <section className="slot">
                    <div className="container">
                        <h4>Delivery Address</h4>
                        <div className={!this.state.showAddressBox ? "row addres-box-hide" : "row"}>
                            <div className="col-xl-12">
                                <h6>Personal Information</h6>
                                <div className="user-personal-info">
                                    <div className="row">
                                        <div className="col-xl-6 __web-inspector-hide-shortcut__">
                                            <div className="form-floating mb-3">
                                                <input type="text"
                                                    name="FirstName"
                                                    className="form-control"
                                                    disabled
                                                    placeholder="First Name"
                                                    value={this.state.FirstName}
                                                    onChange={this.onChangeHandler}
                                                />
                                                <label htmlFor="floatingInput">First Name *</label>
                                            </div>
                                        </div>
                                        <div className="col-xl-6 __web-inspector-hide-shortcut__">
                                            <div className="form-floating mb-3">
                                                <input type="text"
                                                    name="LastName"
                                                    className="form-control"
                                                    disabled
                                                    placeholder="Last Name"
                                                    value={this.state.LastName}
                                                    onChange={this.onChangeHandler}
                                                />
                                                <label htmlFor="floatingInput">Last Name *</label>
                                            </div>
                                        </div>
                                        <div className="col-xl-6 __web-inspector-hide-shortcut__">
                                            <div className="form-floating mb-3">
                                                <input type="email"
                                                    name="Email"
                                                    className="form-control"
                                                    disabled
                                                    placeholder="Email"
                                                    value={this.state.Email}
                                                    onChange={this.onChangeHandler}
                                                />
                                                <label htmlFor="floatingInput">Email *</label>
                                            </div>
                                        </div>
                                        <div className="col-xl-6 __web-inspector-hide-shortcut__">
                                            <div className="form-floating mb-3">
                                                <input type="text"
                                                    name="Mobile"
                                                    className="form-control"
                                                    disabled
                                                    placeholder="Phone No."
                                                    value={this.state.Mobile}
                                                    onChange={this.onChangeHandler}
                                                />
                                                <label htmlFor="floatingInput">Phone No. *</label>
                                            </div>
                                        </div>
                                        <h6>Address</h6>
                                        <div className="col-xl-6 __web-inspector-hide-shortcut__">
                                            <div className="form-floating mb-3">
                                                <input type="text"
                                                    name="AddressLine1"
                                                    className="form-control"
                                                    placeholder="Address line one"
                                                    value={this.state.AddressLine1}
                                                    onChange={this.onChangeHandler}
                                                />
                                                <label htmlFor="floatingInput">Address line one*</label>
                                            </div>
                                        </div>
                                        <div className="col-xl-6 __web-inspector-hide-shortcut__">
                                            <div className="form-floating mb-3">
                                                <input type="text"
                                                    name="AddressLine2"
                                                    className="form-control"
                                                    placeholder="Address line two"
                                                    value={this.state.AddressLine2}
                                                    onChange={this.onChangeHandler}
                                                />
                                                <label htmlFor="floatingInput">Address line two</label>
                                            </div>
                                        </div>
                                        <div className="col-xl-6 __web-inspector-hide-shortcut__">
                                            <div className="form-floating mb-3">
                                                <input type="text"
                                                    name="PostCode"
                                                    className="form-control"
                                                    placeholder="Post Code"
                                                    value={this.state.PostCode}
                                                    onChange={this.onChangeHandler}
                                                />
                                                <label htmlFor="floatingInput">Postal Code *</label>
                                            </div>
                                        </div>
                                        <div className="col-xl-6 __web-inspector-hide-shortcut__">
                                            <div className="form-floating mb-3">
                                                <input type="text"
                                                    name="Landmark"
                                                    className="form-control"
                                                    placeholder="Landmark"
                                                    value={this.state.Landmark}
                                                    onChange={this.onChangeHandler}
                                                />
                                                <label htmlFor="floatingInput">Landmark</label>
                                            </div>
                                        </div>
                                        <div className="col-xl-6 __web-inspector-hide-shortcut__">
                                            <div className="form-floating mb-3">
                                                <select
                                                    name="AddressType"
                                                    className="form-control"
                                                    value={this.state.AddressType}
                                                    onChange={this.onChangeHandler}
                                                >
                                                    <option value="H">Home</option>
                                                    <option value="O">Office</option>
                                                </select>

                                            </div>
                                        </div>
                                        <div className="col-xl-6">
                                            <div className="frm-ctrl-wrap mb-3">
                                                <input type="button" className="btn-submit" value={this.state.btn} onClick={this.onAddAddressHandler} />
                                            </div>
                                        </div>

                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="add-adress-wrap">
                            <a href={"/"} className="add-adress_btn" onClick={this.showAddressBox}>Add Address</a>
                        </div>
                    </div>
                </section>
                <section className="user-address">
                    <div className="container">
                        <h6>Selected Address</h6>
                        <div className="swiper slide-address">
                            <div>
                                <Slider {...settings}>
                                    {MyView}
                                </Slider>
                            </div>
                            {/* <div className="swiper-pagination"></div> */}
                        </div>
                    </div>
                </section>
                <section className="slot-lwr">
                    <div className="container">
                        <div className="pro-btn">
                            <a href={"/"} className="pr-btn" onClick={this.submitServiceDeliveryAddress}>{this.state.btnSelectProvider}</a>
                        </div>
                    </div>
                </section>
                {this.pageRedirectToLogin()}
                {this.redirectProviderListPage()}
            </>
        );
    }
}

export default ServiceDeliveryAddress;