import React, { Component, Fragment } from 'react';
import { BrowserRouter } from "react-router-dom";
import AppRoute from "./route/AppRoute";
import LocalStorageHelper from './LocalStorageHelper/LocalStorageHelper';
import ProviderAppRoute from './route/ProviderAppRoute';
import MyContext from './contextProvider/contextProvider';


class App extends Component {
    constructor() {
        super();
        this.state = {
            UserType: ''
        };
    }    

    componentDidMount() {
        var _self = this;      

        /* Check User Authentication */
        var userData = JSON.parse(LocalStorageHelper.getUser());
        if (userData !== null) {
            _self.setState({ ..._self.state, UserType: userData.UserTypeId });
        }        
    }    

    googleTranslateElementInit = () => {
        new window.google.translate.TranslateElement(
            {
                pageLanguage: "en",
                autoDisplay: false
            },
            "google_translate_element"
        );
    };

    checkUserType = (userTypeId) => {
        this.setState({
            UserType: userTypeId,
        })
    }

    render() {
        return (
            <Fragment>                                           
                {/* <MyContext.Provider value={this.state}> */}
                <BrowserRouter>
                    {
                        (this.state.UserType === undefined || this.state.UserType === '' || this.state.UserType === 'C') ?
                            <AppRoute checkUserType={this.checkUserType} /> :
                            <ProviderAppRoute checkUserType={this.checkUserType} />
                    }
                </BrowserRouter>
                {/* </MyContext.Provider> */}

                   

            </Fragment>
        );
    }
}

export default App;
