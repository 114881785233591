import React, { Component } from 'react';
import FooterDesktop from "../../components/common/FooterDesktop";
import Legal from "../../components/common/Legal";
import MainHeader from "../../components/common/provider/MainHeader";
import BookingHistory from '../../components/ProviderPanel/BookingHistory';
import MobileHeader from '../../components/common/MobileHeader';

class BookingHistoryPage extends Component {
    constructor(props) {
        super(props);
        this.state = {
            checkUserType: props.checkUserType
        }
    }
    render() {
        return (
            <>
                <div className="desktop">
                    <MainHeader checkUserType={this.state.checkUserType}/>
                </div>
                <div className="mobile">
                    <MobileHeader checkUserType={this.state.checkUserType}/>
                </div>


                <BookingHistory />


                <div className="desktop">
                    <FooterDesktop />
                </div>

                <Legal />
            </>
        );
    }
}

export default BookingHistoryPage;