import React, { Component } from 'react';
import MainHeader from '../components/common/MainHeader';
import MobileHeader from '../components/common/MobileHeader';
import BookingTermsAndCondition from '../components/Others/BookingTermsAndCondition';
import FooterDesktop from '../components/common/FooterDesktop';
import Legal from "../components/common/Legal";

class BookingTermsAndConditionPage extends Component {
    render() {
        return (
            <>

                <div className="desktop">
                    <MainHeader />
                </div>
                <div className="mobile">
                    <MobileHeader />
                </div>

                <BookingTermsAndCondition />

                <div className="desktop">
                    <FooterDesktop />
                </div>
                
                <Legal />

            </>
        );
    }
}

export default BookingTermsAndConditionPage;