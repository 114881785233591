import React, { Component } from 'react';
import ApiURL from "../api/ApiURL";
import axios from "axios";
import cogoToast from 'cogo-toast';
import MainHeader from '../components/common/MainHeader';
import FooterDesktop from '../components/common/FooterDesktop';
import Legal from '../components/common/Legal';
import ListByCategory from '../components/ServiceDetails/ListByCategory';
import MobileHeader from '../components/common/MobileHeader';

class ServiceListByCategory extends Component {
    constructor({ match }) {
        super();
        this.state = {
            Category: match.params.Category,
            ServiceData: []
        }

    }

    componentDidMount() {
        window.scroll(0, 0)
        this.getServicesByCategory();
    }

    getServicesByCategory = () => {
        axios.get(ApiURL.ServiceListByCategory(this.state.Category)).then(response => {
            let respData = response.data;
            if (respData.StatusCode === 200) {
                this.setState({ ServiceData: respData.DataResult })
            }

        }).catch(error => {
            cogoToast.error("Request Failed! Please Try Again.", { position: 'bottom-center' })
        });
    }

    render() {
        return (
            <>
                <div className="desktop">
                    <MainHeader />
                </div>
                <div className="mobile">
                    <MobileHeader />
                </div>


                <ListByCategory
                    Category={this.state.Category}
                    ServiceData={this.state.ServiceData}
                />

                <div className="desktop">
                    <FooterDesktop />
                </div>
                
                <Legal />
            </>
        );
    }
}

export default ServiceListByCategory;