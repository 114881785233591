import React, { Component } from 'react';
import { Link, NavLink } from 'react-router-dom';
import { Redirect } from 'react-router-dom';
import axios from "axios";
import ApiURL from "../../api/ApiURL";
import cogoToast from "cogo-toast";
import validation from '../../validation/validation';

class Login extends Component {
    constructor() {
        super();
        this.state = {
            btn: "Next",
            mobile: '',
            redirectOTPStatus: false
        }
    }

    onSubmit = (e) => {
        e.preventDefault();
        let mobile = this.state.mobile;
        if (mobile.length === 0) {
            cogoToast.error("Mobile Number Required.", { position: 'bottom-center' });
        }
        else if (mobile.length !== 0 && !(validation.MobileRegx).test(mobile)) {
            cogoToast.error("Invalid Mobile No.", { position: 'bottom-center' });
        }
        else {
            this.setState({ btn: "Processing.." });
            var param = { Mobile: mobile };
            axios.post(ApiURL.UserLogin, param).then(response => {
                let respData = response.data;
                if (respData.StatusCode === 200) {
                    let dataResult = respData.DataResult;
                    if (dataResult === 0) {
                        cogoToast.error('Login failed ! Please contact to your System Administrator', { position: 'bottom-center' });
                    }
                    else if (dataResult === 1) {
                        this.setState({ btn: "Next", redirectOTPStatus: true })
                    }

                } else if (respData.StatusCode === 400) {
                    this.setState({ btn: "Next" });
                    cogoToast.error(respData.Message, { position: 'bottom-center' });

                } else if (respData.StatusCode === 500) {
                    this.setState({ btn: "Next" });
                    cogoToast.error("Internal Server Error ! Please Try Again.", { position: 'bottom-center' });
                }

            }).catch(error => {
                this.setState({ btn: "Next" });
                cogoToast.error("Request Failed! Please Try Again.", { position: 'bottom-center' })
            })
        }
    }

    RedirectToOTPPage = () => {
        if (this.state.redirectOTPStatus === true) {
            let url = "/verifyotp/" + this.state.mobile;
            return <Redirect to={url} />
        }
    }


    render() {
        const loginImgURL = ApiURL.BaseImageURL + "/signin.png";
        return (
            <>
                <section className="banner-inner">
                    <div className="container">
                        <nav aria-label="breadcrumb">
                            <ol className="breadcrumb">
                                <li className="breadcrumb-item"><NavLink to="/">Home</NavLink></li>
                                <li className="breadcrumb-item active" aria-current="page">User Login</li>
                            </ol>
                        </nav>
                    </div>
                </section>
                <section className="user-reg-sec">
                    <div className="container">
                        <div className="row align-items-center">
                            <div className="col-xl-7">
                                <div className="user-reg-img-wrap">
                                    <img src={loginImgURL} className="img-fluid" alt="Login" />
                                </div>
                            </div>
                            <div className="col-xl-5">
                                <div className="user-reg-frm-wrap">
                                    <h4>User Sign in</h4>
                                    <h5>
                                        <i className="fa fa-info-circle" aria-hidden="true"></i>&nbsp;
                                        <b>OTP</b> will be sent to your Registered Email.
                                    </h5>
                                    <div className="form-floating mb-3">
                                        <input
                                            type="text"
                                            className="form-control"
                                            placeholder="Mobile Number"
                                            autoComplete={0}
                                            onChange={(e) => this.setState({ mobile: e.target.value })}
                                        />
                                        <label htmlFor="floatingInput">Mobile Number</label>
                                    </div>
                                    <div className="frm-ctrl-wrap mb-3">
                                        <input type="submit" className="btn-submit" value={this.state.btn} onClick={(e) => this.onSubmit(e)} />
                                    </div>
                                    <p className="sign-para">
                                        <h5>New member? <Link to={"/customer-reg"}><u>Register</u></Link> here.</h5>
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                {this.RedirectToOTPPage()}
            </>
        );
    }
}

export default Login;