import React, { Component, Fragment } from "react";
import ApiURL from "../api/ApiURL";
import axios from "axios";
import cogoToast from 'cogo-toast';
import FooterDesktop from "../components/common/FooterDesktop";
import Legal from "../components/common/Legal";
import MainHeader from "../components/common/MainHeader";
import Banner from "../components/home/Banner";
import BestOffer from "../components/home/BestOffer";
import MarketPlace from "../components/home/MarketPlace";
import PopularService from "../components/home/PopularService";
import ServicesWeOffer from "../components/home/ServicesWeOffer";
import Testimonial from "../components/home/Testimonial";
import MobileHeader from "../components/common/MobileHeader";

class HomePage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      ServicesWeOffer: [],
      BestOffers: [],
      PopularServices: [],
      OurServices: [],
      checkUserType: props.checkUserType
    }
  }

  componentDidMount() {
    window.scroll(0, 0);
    this.getHomeServices();
  }

  getHomeServices = () => {
    axios.get(ApiURL.GetHomeServices).then(response => {
      let respData = response.data;
      if (respData.StatusCode === 200) {
        let dataResult = respData.DataResult;
        console.log(dataResult);
        this.setState({
          ServicesWeOffer: dataResult.ServicesWeOffer,
          BestOffers: dataResult.BestOffers,
          PopularServices: dataResult.PopularServices,
          OurServices: dataResult.OurServices,
        })
      }
      else if (respData.StatusCode === 500) {
        cogoToast.error("Internal Server Error ! Please Try Again.", { position: 'bottom-center' });
      }

    }).catch(error => {
      cogoToast.error("Request Failed! Please Try Again.", { position: 'bottom-center' })
    })
  }

  render() {
    return (
      <Fragment>

        <div className="desktop">
          <MainHeader checkUserType={this.state.checkUserType} />
        </div>
        <div className="mobile">
          <MobileHeader />
        </div>


        <Banner />
        <ServicesWeOffer ServicesWeOffer={this.state.ServicesWeOffer} />
        {/* <BestOffer BestOffers={this.state.BestOffers} /> */}
        <PopularService PopularServices={this.state.PopularServices} />
        <MarketPlace />
        <Testimonial />


        <div className="desktop">
          <FooterDesktop />
        </div>

        <Legal />

      </Fragment>
    );
  }
}

export default HomePage;
