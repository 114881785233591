import React, { Component } from 'react';
import { Link, NavLink } from 'react-router-dom';
import axios from "axios";
import ApiURL from "../../api/ApiURL";
import cogoToast from 'cogo-toast';
import * as signalR from '@microsoft/signalr';
import LocalStorageHelper from '../../LocalStorageHelper/LocalStorageHelper';

class MobileHeader extends Component {
    constructor(props) {
        super(props);
        this.state = {
            sideNavState: '',
            contentOverState: 'content-overlay-close',
            UserId: '',
            GuestId: '',
            DisplayName: '',
            Mobile: '',
            Email: '',
            UserTypeId: 'C',
            LoggedIn: false,
            CartListCount: 0,
            ServiceData: [],
            SearchKey: '',
            ShowSearchList: false,
            ServiceBooked: false,
            actionNavState: false,
        }
    }

    componentDidMount() {
        var _self = this;

        /* Configure signalR HubConnection */
        let connection = new signalR.HubConnectionBuilder().withUrl(ApiURL.BaseURL + "/notificationsHub", {
            skipNegotiation: true,
            transport: signalR.HttpTransportType.WebSockets
        }).build();

        connection.start().then(() => console.log("connected!")).catch(err => console.log(err));
        connection.on("Connected", () => console.log('Hub Connected...'));
        connection.on("ReceiveHubMessage", function (data, statusCode) {
            if (statusCode === "Succeeded" && parseInt(data) > _self.state.CartListCount) {
                let listItemCount = parseInt(data);
                _self.setState({
                    ..._self.state,
                    CartListCount: listItemCount
                })
            }
        });

        /* Check User Authentication */
        var userData = JSON.parse(LocalStorageHelper.getUser());
        //console.log('checkUserAuth', userData);
        if (userData !== null) {
            let userLoggedInState = (userData.UserId > 0) ? true : false;
            _self.setState({
                ..._self.state,
                UserId: userData.UserId,
                GuestId: userData.GuestId,
                DisplayName: userData.DisplayName,
                Mobile: userData.Mobile,
                Email: userData.Email,
                UserTypeId: userData.UserTypeId,
                LoggedIn: userLoggedInState
            }, () => _self.getCartListCount());
        }
    }

    actionNavOpenClose = (event) => {
        let actionNavState = this.state.actionNavState;
        if (actionNavState === false) {
            this.setState({ actionNavState: true })
        }
        else {
            this.setState({ actionNavState: false })
        }
    }

    menubarClickHandler = () => {
        this.sideNavOpenClose();
    }

    contentOverlayClickHandler = () => {
        this.sideNavOpenClose();
    }

    sideNavOpenClose = () => {
        let sideNavState = this.state.sideNavState;
        if (sideNavState === '') {
            this.setState({ sideNavState: 'active', contentOverState: 'content-overlay-open' })
        }
        else {
            this.setState({ sideNavState: '', contentOverState: 'content-overlay-close' })
        }
    }

    setGuestUserId = () => {
        var _self = this;
        //guestId = JSON.parse(LocalStorageHelper.getGuestId());
        var userData = JSON.parse(LocalStorageHelper.getUser());
        console.log(userData);
        if (userData !== null) {
            let userLoggedInState = (userData.UserId > 0) ? true : false;
            _self.setState({
                ..._self.state,
                UserId: userData.UserId,
                GuestId: userData.GuestId,
                LoggedIn: userLoggedInState
            }, () => {
                console.log(_self.state);
            })
        }
    }

    signOut = () => {
        LocalStorageHelper.removeGuestId();
        LocalStorageHelper.removeUser();
        LocalStorageHelper.removeService();
        LocalStorageHelper.removeProvider();
        LocalStorageHelper.removeBooking();
        LocalStorageHelper.removeLastRedirectPath();
        this.menubarClickHandler();
    }

    getCartListCount = () => {
        var _self = this;
        var param = {
            UserId: _self.state.UserId,
            GuestId: _self.state.GuestId,
        };
        axios.post(ApiURL.GetCartListCount, param).then(response => {
            let respData = response.data;
            if (respData.StatusCode === 200) {
                _self.setState({ ..._self.state, CartListCount: respData.DataResult });
            }
            else {
                cogoToast.error("getCartListCount Request Failed! Try Again", { position: 'bottom-center' });
            }
        }).catch(error => {
            cogoToast.error("getCartListCount Catch Request Failed! Try Again :: " + error, { position: 'bottom-center' })
        });
    }


    render() {
        let logoImageURL = ApiURL.LogoImageURL + '/96.png';
        return (
            <>
                <header>
                    <div className="custom-navbar-mob">
                        <div className="nav-btn-mob" onClick={this.menubarClickHandler}><i className='bx bx-menu-alt-left'></i></div>
                        <div className="brand"><NavLink to="/">MyBoogy</NavLink></div>
                        <div className="main-search-form">
                            <Link to={"/search"}>
                                <div className="search-icon-wrapper">
                                    <i className='fa fa-search' aria-hidden='true' />
                                </div>
                            </Link>
                        </div>
                        <div className="nav-link-btn">
                            <div className="login-btn">
                                <Link to={"/wishlist"}>
                                    <i className="fa fa-shopping-cart"></i>
                                    {
                                        (this.state.CartListCount > 0) &&
                                        <span>{this.state.CartListCount}</span>
                                    }
                                </Link>
                            </div>
                        </div>
                    </div>
                </header>

                <div className={this.state.sideNavState !== '' ? "side-menu active" : "side-menu"}>
                    <div className="container">
                        <div className='row'>
                            <div className='col-sm-12 my-2'>
                                <Link to={"/"}>
                                    <img src={logoImageURL} alt='...' />
                                </Link>
                                <button type="button" onClick={this.menubarClickHandler} className="close float-end fs-4" aria-label="Close">
                                    <span aria-hidden="true">&times;</span>
                                </button>
                            </div>
                        </div>
                        <div className='row user-profile-sec my-1'>
                            <div className='col-sm-12'>
                                {
                                    (this.state.LoggedIn === true) ?
                                        <div className="user-profile-dtl">
                                            <span><i className='fa fa-user' aria-hidden='true' /> &nbsp;
                                                {this.state.DisplayName} &nbsp;
                                                <Link to={"#"} onClick={this.signOut} className="btn btn-success float-end">
                                                    <i className='fa fa-sign-out' aria-hidden='true' /> Logout
                                                </Link>
                                            </span>
                                            <span><i className='fa fa-phone' aria-hidden='true' /> &nbsp;{this.state.Mobile}</span>
                                            <span><i className='fa fa-envelope' aria-hidden='true' /> &nbsp;{this.state.Email}</span>
                                        </div>
                                        :
                                        <div className="user-profile-dtl">
                                            <Link to={"/login"} className="btn btn-success float-end">
                                                <i className='fa fa-sign-in' aria-hidden='true' /> Login
                                            </Link>
                                            <label>Welcome</label>
                                            <span>
                                                <i className='fa fa-user' aria-hidden='true' /> &nbsp; Guest &nbsp;
                                            </span>
                                        </div>
                                }
                            </div>
                        </div>
                    </div>
                    <div className="nav-menu">
                        <ul>
                            <li><Link to={"/"}><i className='fa fa-home' aria-hidden='true' /> Home</Link></li>
                            <li><NavLink to="/about"><i className='fa fa-info-circle' aria-hidden='true' /> About Us</NavLink></li>
                            <li><NavLink to="/contact"><i className='fa fa-envelope' aria-hidden='true' /> Contact Us</NavLink></li>

                            {
                                (this.state.UserTypeId === 'C') ?
                                    <>
                                        {
                                            (this.state.LoggedIn === true) ?
                                                <li><NavLink to="/booking-history"><i className='fa fa-list' aria-hidden='true' /> Booking History</NavLink></li>
                                                :
                                                <li><NavLink to="/customer-reg"><i className='fa fa-user' aria-hidden='true' /> Customer Signup</NavLink></li>
                                        }
                                    </> :
                                    <>
                                        {
                                            (this.state.LoggedIn === true) &&
                                            <>
                                                <li><NavLink to="/profile"><i className='fa fa-user' aria-hidden='true' /> Profile</NavLink></li>
                                                <li>
                                                    <span onClick={this.actionNavOpenClose}>
                                                        <i className={!this.state.actionNavState ? "fa fa-plus" : "fa fa-minus"}></i> Actions
                                                    </span>
                                                    <ul className={!this.state.actionNavState ? "collapse" : ""}>
                                                        <li><i className="fa fa-hand-o-right"></i> <Link to={"/manage-work-area"} className="manage-txt">Manage Work Area</Link></li>
                                                        <li><i className="fa fa-hand-o-right"></i> <Link to={"/manage-service"} className="manage-txt">Manage Service</Link></li>
                                                        <li><i className="fa fa-hand-o-right"></i> <Link to={"/manage-booking-history"} className="manage-txt">Track Booking History</Link></li>
                                                        <li><i className="fa fa-hand-o-right"></i> <Link to={"/provider-slot"} className="manage-txt">Publish Availability</Link></li>
                                                        <li><i className="fa fa-hand-o-right"></i> <Link to={"/service-provider-rate"} className="manage-txt">Manage Service Rate</Link></li>
                                                    </ul>
                                                </li>
                                            </>
                                        }
                                    </>
                            }

                            <li><NavLink to="/btc"><i className='fa fa-hand-o-right' aria-hidden='true' /> Terms & Conditions</NavLink></li>
                            <li><NavLink to="/policy"><i className='fa fa-hand-o-right' aria-hidden='true' /> Privacy Policy</NavLink></li>
                            <li><NavLink to="/cookies-policy"><i className='fa fa-hand-o-right' aria-hidden='true' /> Cookies Policy</NavLink></li>
                            <li><NavLink to="/cancellation"><i className='fa fa-hand-o-right' aria-hidden='true' /> Cancellation Policy</NavLink></li>
                            <li><NavLink to="/"><i className='fa fa-hand-o-right' aria-hidden='true' /> Advice for Providers</NavLink></li>
                        </ul>
                    </div>

                    <div className="nav-link-btn">
                        <div className="join-as">
                            <Link to={"/provider-reg"}>Join as a Professional</Link>
                        </div>
                        <div className="social-site">
                            <NavLink to={"#"} className="mr-3" onClick={this.fbLinkClick}><i className='bx bxl-facebook'></i></NavLink>
                            <NavLink to={"#"} className="mr-3"><i className='bx bxl-twitter'></i></NavLink>
                            <NavLink to={"#"} className="mr-3"><i className='bx bxl-instagram'></i></NavLink>
                        </div>
                    </div>
                </div>
                <div onClick={this.contentOverlayClickHandler} className={this.state.contentOverState}>

                </div>
            </>
        );
    }
}

export default MobileHeader;