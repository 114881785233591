import React, { Component, Fragment } from 'react';
import FooterDesktop from "../components/common/FooterDesktop";
import Legal from "../components/common/Legal";
import Login from '../components/common/Login';
import MainHeader from "../components/common/MainHeader";
import MobileHeader from '../components/common/MobileHeader';

class LoginPage extends Component {
    componentDidMount() {
        window.scroll(0, 0);
    }

    render() {
        return (
            <Fragment>
                <div className="desktop">
                    <MainHeader />
                </div>
                <div className="mobile">
                    <MobileHeader />
                </div>

                <Login />

                <div className="desktop">
                    <FooterDesktop />
                </div>
                
                <Legal />
            </Fragment>
        );
    }
}

export default LoginPage;