import React, { Component } from 'react';
import ApiURL from "../../api/ApiURL";

class SuccessRegistration extends Component {
    render() {
        const successImgURL = ApiURL.BaseImageURL + "/check-circle.svg";
        return (
            <>
                <section className="provider-reg-sec">
                    <div className="container">
                        <div className="provider-reg-info">
                            <span>
                                <img src={successImgURL} alt="..."/>
                            </span>
                            <h4>You have Successfully Registered</h4>
                            {/* <p>
                                Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aliquam
                                et bibendum risus. Vestibulum sagittis.
                            </p> */}
                        </div>
                    </div>
                </section>
            </>
        );
    }
}

export default SuccessRegistration;