import React, { Component } from 'react';
import ApiURL from "../../api/ApiURL";
import { Link, NavLink, Redirect } from 'react-router-dom';
import axios from "axios";
import cogoToast from 'cogo-toast';
import LocalStorageHelper from '../../LocalStorageHelper/LocalStorageHelper';


class ProviderProfile extends Component {
    constructor(props) {
        super(props);
        this.state = {
            btn: "Proceed",
            UserId: 0,
            ServiceId: 0,
            ProviderId: 0,
            ProviderName: '',
            ProfileImage: '',
            Gender: '',
            Experience: '',
            Expertise: '',
            LanguageKnown: '',
            SocialLink: '',
            TwitterLink: '',
            InstaLink: '',
            FaceBookLink: '',
            linkedInLink: '',
            YouTubeLink: '',
            CVFileName: '',
            CVFileURL: '',
            CDate: null,
            SelectedSlotTime: null,
            SlotDates: [],
            SlotTimes: [],
            RedirectToLogin: false,
            RedirectToBookingSummary: false,
        }
    }

    componentDidMount() {
        var userData = JSON.parse(LocalStorageHelper.getUser());
        if (userData !== null && userData.UserId > 0) {
            var svcData = JSON.parse(LocalStorageHelper.getService());
            var proData = JSON.parse(LocalStorageHelper.getProvider());
            if (svcData !== null && proData !== null) {
                this.setState({
                    UserId: userData.UserId,
                    ServiceId: svcData.ServiceId,
                    ProviderId: proData.UserId,
                    ProviderName: proData.FullName,
                })

                let slotRequestParam = {
                    UserId: proData.UserId,
                    ServiceId: svcData.ServiceId,
                    CDate: this.state.CDate
                };
                this.getProviderSlots(slotRequestParam);
            }
        }
        else {
            let winlocation = window.location.pathname;
            console.log(window.location.href);
            LocalStorageHelper.SetLastRedirectPath(winlocation);
            this.setState({ RedirectToLogin: true })
        }
    }

    getProviderSlots = (param) => {
        let fbLink = '';
        let twitLink = '';
        let instaLink = '';
        let linkedinLink = '';
        let cvFileUrl = '';
        axios.post(ApiURL.GetProviderSlots, param).then(response => {
            let respData = response.data;
            if (respData.StatusCode === 200) {
                let dataResult = respData.DataResult;
                //console.log(dataResult);
                let slotDates = this.state.CDate !== null ? this.state.SlotDates : dataResult.SlotDates;
                let slotTimes = this.state.CDate !== null ? dataResult.SlotTimes : [];
                if (dataResult != null) {
                    if (dataResult.SocialLink.length > 0) {
                        let socialLinks = dataResult.SocialLink.split(',');
                        for (var i = 0; i < socialLinks.length; i++) {
                            if (/facebook/.test(socialLinks[i]) === true)
                                fbLink = socialLinks[i];
                            else if (/twitter/.test(socialLinks[i]) === true)
                                twitLink = socialLinks[i];
                            else if (/instagram/.test(socialLinks[i]) === true)
                                instaLink = socialLinks[i];
                            else if (/linkedin/.test(socialLinks[i]) === true)
                                linkedinLink = socialLinks[i];
                        }
                    }

                    if (dataResult.CVFileName !== null) {
                        const providerCVFileURL = ApiURL.BaseProviderDocURL + "/" + dataResult.UserId.toString() + "/" + dataResult.CVFileName;
                        cvFileUrl = providerCVFileURL;
                    }

                    this.setState({
                        ProviderId: dataResult.UserId,
                        ProviderName: dataResult.FullName,
                        ProfileImage: dataResult.ProfileImage,
                        Gender: dataResult.Gender,
                        Experience: dataResult.Experience,
                        Expertise: dataResult.Expertise,
                        LanguageKnown: dataResult.LanguageKnown,
                        SocialLink: dataResult.SocialLink,
                        YouTubeLink: dataResult.YouTubeLink,
                        CVFileName: dataResult.CVFileName,
                        CVFileURL: cvFileUrl,
                        SlotDates: slotDates,
                        SlotTimes: slotTimes
                    })
                }
            }
            else if (respData.StatusCode === 500) {
                cogoToast.error("Internal Server Error ! Please Try Again.", { position: 'bottom-center' });
            }

        }).catch(error => {
            cogoToast.error("Request Failed! Please Try Again.", { position: 'bottom-center' })
        })
    }

    getProfileImageURL = (profileImageName) => {
        let profImageName = profileImageName || "p1.jpg";
        return ApiURL.ProviderImageURL + '/' + profImageName;
    }

    selectSlotDate = (sdItem) => {
        console.log(sdItem);
        let slotRequestParam = {
            UserId: this.state.ProviderId,
            ServiceId: this.state.ServiceId,
            CDate: sdItem.CDate
        };
        this.setState({
            ...this.state,
            CDate: sdItem.CDate
        }, () => this.getProviderSlots(slotRequestParam));

    }

    selectLoginTime = (slotTime) => {
        console.log(slotTime);
        this.setState({
            ...this.state,
            SelectedSlotTime: slotTime
        })
    }

    selectProviderSlot = (e) => {
        e.preventDefault();
        if (window.confirm('Do you want to save the selected Service Provider Slot?')) {
            var { ProviderId, CDate, SelectedSlotTime } = this.state;
            if (CDate === null) {
                cogoToast.error("Please select Available Date!", { position: 'bottom-center' });
            }
            else if (SelectedSlotTime === null) {
                cogoToast.error("Please select Login Time!", { position: 'bottom-center' });
            }
            else {

                this.setState({ btn: "Processing.." });

                let param = {
                    UserId: this.state.UserId,
                    ServiceId: this.state.ServiceId,
                    AppointmentDate: this.state.CDate,
                    ProviderId: ProviderId,
                    StartTime: SelectedSlotTime.StartTime,
                    EndTime: SelectedSlotTime.EndTime
                };

                axios.post(ApiURL.SelectProviderSlot, param).then(response => {
                    let respData = response.data;
                    if (respData.StatusCode === 200) {
                        let dataResult = respData.DataResult;
                        if (dataResult === 1) {
                            cogoToast.success("Provider Slot selected", { position: 'bottom-center' });
                            this.setState({
                                ...this.state,
                                btn: "Proceed",
                                RedirectToBookingSummary: true
                            })
                        }
                        else {
                            this.setState({ ...this.state, btn: "Proceed" })
                            cogoToast.error("Sorry! Slot booking interrupted ! Please Try Again.", { position: 'bottom-center' });
                        }
                    }
                    else if (respData.StatusCode === 500) {
                        this.setState({ ...this.state, btn: "Proceed" })
                        cogoToast.error("Internal Server Error ! Please Try Again.", { position: 'bottom-center' });
                    }

                }).catch(error => {
                    this.setState({ ...this.state, btn: "Proceed" })
                    cogoToast.error("Request Failed! Please Try Again.", { position: 'bottom-center' })
                })
            }
        }

    }

    pageRedirectToLogin = () => {
        if (this.state.RedirectToLogin === true) {
            return <Redirect to="/login" />
        }
    }

    pageRedirectToBookingSummary = () => {
        if (this.state.RedirectToBookingSummary === true) {
            return <Redirect to="/booking-summary" />
        }
    }


    render() {
        const providerImgURL = this.getProfileImageURL(this.state.ProfileImage);
        const slotDates = this.state.SlotDates;
        const slotDateView = (slotDates !== null && slotDates.length > 0) ?
            (slotDates.map((sdItem, i) => {
                return (
                    <div className="col-xl-3" key={i} onClick={(e) => this.selectSlotDate(sdItem)}>
                        <div className={this.state.CDate === sdItem.CDate ? "login-time-wrap selected" : "login-time-wrap"}>
                            <i className="bi bi-calendar-date"></i>
                            <span>{sdItem.DayName}</span>
                            <h6>{sdItem.CDate}</h6>
                        </div>
                    </div>
                )
            })) :
            (<div></div>)


        const slotTimes = this.state.SlotTimes;
        const slotTimeView = (slotTimes.length > 0) ?
            (slotTimes.map((stItem, i) => {
                return (
                    <>
                        {
                            (stItem.IsBooked !== true) ?
                                <div className="col-xl-3" key={i}
                                    onClick={(e) => this.selectLoginTime(stItem)}>
                                    <div className={
                                        (this.state.SelectedSlotTime !== null &&
                                            this.state.SelectedSlotTime.LoginTime === stItem.LoginTime) ?
                                            "login-time-wrap selected" :
                                            "login-time-wrap"}
                                    >
                                        <h6>{stItem.LoginTime}</h6>
                                    </div>
                                </div>
                                :
                                <div className="col-xl-3" key={i}>
                                    <div className="login-time-wrap disabled">
                                        <h6>{stItem.LoginTime}</h6>
                                    </div>
                                </div>
                        }
                    </>

                )
            })) :
            (<div></div>)


        return (
            <>
                <section className="banner-inner">
                    <div className="container">
                        <nav aria-label="breadcrumb">
                            <ol className="breadcrumb">
                                <li className="breadcrumb-item"><NavLink to="/">Home</NavLink></li>
                                <li className="breadcrumb-item active" aria-current="page">Provider Profile</li>
                            </ol>
                        </nav>
                    </div>
                </section>
                <section className="provider-profle-sec">
                    <div className="container">
                        <div className="row">
                            <div className="col-xl-2">
                                <div className="card-img-box shadow pro-img">
                                    <img src={providerImgURL} className="img-fluid img-thumbnail" alt="..." />
                                </div>
                            </div>
                            <div className="col-xl-5">
                                <div className="provider-main-info">
                                    <h4>{this.state.ProviderName}</h4>
                                    <p>
                                        <i className="fa fa-check-circle-o" style={{ fontSize: '20px', color: '#05BB63' }}></i> &nbsp;
                                        Background Verified
                                    </p>
                                    <div class="provider-personal-info">
                                        <p><b>Gender</b> : {this.state.Gender}</p>
                                        <p><b>Experience</b> : {this.state.Experience}</p>
                                    </div>
                                    <p><b>Language Known</b> : {this.state.LanguageKnown}</p>
                                    <p><b>Description</b> : {this.state.Expertise}</p>
                                    <p>
                                        <b>Curriculum Vitae (CV)</b> :
                                        {
                                            (this.state.CVFileName !== '') &&
                                            <a href={this.state.CVFileURL} target='__blank'>
                                                &nbsp;<i className="fa fa-file-pdf-o" aria-hidden="true"></i> {this.state.CVFileName}
                                            </a>
                                        }
                                    </p>
                                    <p>
                                        {
                                            (this.state.FaceBookLink.length > 0) &&
                                            <li className="list-group-item d-flex justify-content-between align-items-center flex-wrap">
                                                <a href={this.state.FaceBookLink} target='__blank'>
                                                    <i className="fa fa-facebook-square" aria-hidden="true"></i>
                                                </a>
                                            </li>
                                        }
                                        {
                                            (this.state.TwitterLink.length > 0) &&
                                            <li className="list-group-item d-flex justify-content-between align-items-center flex-wrap">
                                                <a href={this.state.TwitterLink} target='__blank'>
                                                    <i class="fa fa-twitter-square" aria-hidden="true"></i>
                                                </a>
                                            </li>
                                        }
                                        {
                                            (this.state.InstaLink.length > 0) &&
                                            <li className="list-group-item d-flex justify-content-between align-items-center flex-wrap">
                                                <a href={this.state.InstaLink} target='__blank'>
                                                    <i class="fa fa-instagram-square" aria-hidden="true"></i>
                                                </a>
                                            </li>
                                        }
                                        {
                                            (this.state.linkedInLink !== '') &&
                                            <li className="list-group-item d-flex justify-content-between align-items-center flex-wrap">
                                                <a href={this.state.linkedInLink} target='__blank'>
                                                    <i class="fa fa-linkedin-square" aria-hidden="true"></i>
                                                </a>
                                            </li>
                                        }

                                    </p>
                                </div>
                            </div>
                            <div class="col-xl-5">
                                <div class="embed-responsive embed-responsive-16by9">
                                    <iframe
                                        width="300"
                                        height="180"
                                        src={`https://www.youtube.com/embed/${this.state.YouTubeLink}`}
                                        frameBorder="0"
                                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                                        allowFullScreen
                                        title="Embedded youtube"
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <section className="user-address select-date">
                    <div className="container">
                        <h6 className="my-3">Choose Available Dates</h6>
                        <div className="row">

                            {slotDateView}

                        </div>
                        <div className={this.state.CDate === null ? "hide-Login-Time-section" : ""}>
                            <h6 className="my-3">Choose Login Time</h6>
                            <div className="row">

                                {slotTimeView}

                            </div>
                        </div>
                    </div>
                </section>
                <section className="slot-lwr">
                    <div className="container">
                        <div className="pro-btn">
                            <Link to={"#"} className="pr-btn" onClick={this.selectProviderSlot}>{this.state.btn}</Link>
                        </div>
                    </div>
                </section >
                {this.pageRedirectToLogin()}
                {this.pageRedirectToBookingSummary()}
            </>
        );
    }
}

export default ProviderProfile;