import React, { Component } from 'react';
import MainHeader from '../../components/common/provider/MainHeader';
import FooterDesktop from '../../components/common/FooterDesktop';
import Legal from '../../components/common/Legal';
import Unauthorized from '../../components/common/provider/Unauthorized';
import MobileHeader from '../../components/common/MobileHeader';

class UnauthorizedPage extends Component {
    render() {
        return (
            <>
                <div className="desktop">
                    <MainHeader />
                </div>
                <div className="mobile">
                    <MobileHeader />
                </div>

                <Unauthorized />

                <div className="desktop">
                    <FooterDesktop />
                </div>
                
                <Legal />
            </>
        );
    }
}

export default UnauthorizedPage;