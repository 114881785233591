import React, { Component } from 'react';
import FooterDesktop from '../components/common/FooterDesktop';
import Legal from '../components/common/Legal';
import MainHeader from '../components/common/MainHeader';
import CustomerRegistration from '../components/Customer/CustomerRegistration';
import MobileHeader from '../components/common/MobileHeader';

class CustomerRegistrationPage extends Component {
    render() {
        return (
            <>
                <div className="desktop">
                    <MainHeader />
                </div>
                <div className="mobile">
                    <MobileHeader />
                </div>

                <CustomerRegistration />

                <div className="desktop">
                    <FooterDesktop />
                </div>
                
                <Legal />
            </>
        );
    }
}

export default CustomerRegistrationPage;