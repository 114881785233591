import React, { Component } from 'react';
import { NavLink, Link } from 'react-router-dom';

class BookingTermsAndCondition extends Component {
    render() {
        return (
            <>
                <section className="banner-inner">
                    <div className="container">
                        <nav aria-label="breadcrumb">
                            <ol className="breadcrumb">
                                <li className="breadcrumb-item"><NavLink to="/">Home</NavLink></li>
                                <li className="breadcrumb-item active" aria-current="page">Booking Terms & Conditions</li>
                            </ol>
                        </nav>
                    </div>
                </section>
                <section className="site-container">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12 col-md-12 col-sm-12">
                                <div className="odr-confrm-wrap">
                                    <div style={{ marginBottom: "20px" }}>
                                        <div>
                                            <center>
                                                <h3><strong>Booking Terms & Conditions</strong></h3>
                                            </center>
                                            <p>
                                                Please read booking terms and conditions carefully before using <a href="https://www.myboogy.co.uk" target="__blank">https://www.myboogy.co.uk</a> for booking a service through S2P Services Limited (‘MyBoogy”, we, us).
                                                These terms will tell you how we provide services to our customers and what to do in case there is any problem and other important information.
                                            </p>
                                        </div>
                                        <p>
                                            The Booking terms and conditions must be read along with the website terms and conditions & Cancellation policy.
                                        </p>
                                        <p>
                                            Please don`t proceed with booking If any of the Booking terms and conditions is not acceptable to you.
                                        </p>
                                    </div>

                                    <div style={{ marginBottom: "20px" }}>
                                        <h5><strong>1. Summary</strong></h5>
                                        <p>
                                            If you're ready to move on from us, terminating your contract is simple. All it takes is a moment's notice - simply stop using the Facilitation Services and prepare for life without them! If you have an account associated with our Website, contact customer service via email, phone  for further assistance.
                                        </p>
                                    </div>

                                    <div style={{ marginBottom: "20px" }}>
                                        <h6><strong>For any clarifications or enquiry you can contact us: </strong></h6>
                                        <div>
                                            <ul type='circle'>
                                                <li>By email at - contact-us@myboogy.co.uk</li>
                                                <li>By phone at - 020 - 45421750</li>
                                                <li>By post at – S2P Services Limited, 200 Brook Drive, Green Park, Reading, Berkshire, RG2 6UB, United Kingdom.</li>
                                            </ul>
                                        </div>
                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </>
        );
    }
}

export default BookingTermsAndCondition;