import React, { Component } from 'react';
import ApiURL from "../../api/ApiURL";
import axios from "axios";
import cogoToast from 'cogo-toast';
import LocalStorageHelper from '../../LocalStorageHelper/LocalStorageHelper';

class ManageService extends Component {
    constructor(props) {
        super(props);
        this.state = {
            ProviderId: 0,
            ServiceData: [],
            checkedServices: [],
            isCheckAll: false
        };
        this.MegaMenu = this.MegaMenu.bind(this);
    }

    //componentDidMount Event
    componentDidMount() {
        var _self = this;
        window.scroll(0, 0);
        this.getAllProviderDataList();
        var userData = JSON.parse(LocalStorageHelper.getUser());
        if (userData !== null) {
            this.setState({ ProviderId: userData.UserId }, () => _self.getRegisteredServiceList())
        }
    }

    MegaMenu() {
        var acc = document.getElementsByClassName("card");
        var accNum = acc.length;
        var i;
        for (i = 0; i < accNum; i++) {
            acc[i].addEventListener("click", function () {
                this.classList.toggle("active");
                var cardBody = this.children[0].nextElementSibling;
                //console.log(this.children[0].innerText);
                cardBody.classList.toggle("collapse");
            })
        }
    }

    getAllProviderDataList = () => {
        var _self = this;
        axios.get(ApiURL.GetAllProviderDataList).then(response => {
            let respData = response.data;
            if (respData.StatusCode === 200) {
                var dataResult = respData.DataResult;
                let svcData = dataResult.Services;
                this.setState({ ServiceData: svcData }, () => _self.MegaMenu())
            }
        })
    }

    getRegisteredServiceList = () => {
        var _self = this;
        axios.get(ApiURL.GetRegisteredServices(_self.state.ProviderId)).then(response => {
            let respData = response.data;
            if (respData.StatusCode === 200) {
                var dataResult = respData.DataResult;
                console.log(dataResult);
                _self.checkedService(dataResult);
            }
        })
    }

    checkedService = (regServices) => {
        let chkServices = this.state.checkedServices;
        for (let i = 0; i < regServices.length; i++) {
            let id = regServices[i].ServiceId;
            chkServices.push(id);
        }
        this.setState({
            ...this.state,
            checkedServices: chkServices
        })
    }

    handleClick = e => {
        let chkServices = this.state.checkedServices;
        const { id, checked } = e.target;
        if (!checked) {
            chkServices = this.state.checkedServices.filter(item => item !== parseInt(id));
        }
        else {
            chkServices.push(parseInt(id));
        }
        //this.props.handleChange(chkServices);
        this.setState({
            ...this.state,
            checkedServices: chkServices
        })
    }

    saveData = () => {
        var _self = this;
        if (window.confirm('Do you want to save the Selected Services?')) {

            if (this.state.checkedServices.length === 0) {
                cogoToast.warn("Please select atleast one service!", { position: 'bottom-center' });
            }
            else {
                //console.log(this.state.checkedServices);
                var param = {
                    ProviderId: this.state.ProviderId,
                    ServiceId: this.state.checkedServices.toString()
                };
                axios.post(ApiURL.UpdateProviderServices, param).then(response => {
                    let respData = response.data;
                    if (respData.StatusCode === 200) {
                        cogoToast.success("Provider services updated successfully!", { position: 'bottom-center' });
                        var userData = JSON.parse(LocalStorageHelper.getUser());
                        if (userData !== null) {
                            this.setState({ ProviderId: userData.UserId }, () => _self.getRegisteredServiceList())
                        }
                    }
                    else if (respData.StatusCode === 500) {                        
                        cogoToast.error("Internal Server Error ! Please Try Again.", { position: 'bottom-center' });
                    }
                })
            }

        }
    }

    render() {

        const svcList = this.state.ServiceData;
        const myServiceView = svcList.map((parentService, i) => {
            return <div className="card" key={parentService.ServiceMasterId.toString()}>
                <div className="card-header">
                    {parentService.ServiceMasterName}
                </div>
                <div className="card-body collapse">
                    <div className="sub-service-wrap">
                        <div className="custom-control custom-checkbox">
                            {parentService.ChildServices.map((childService, index) => {
                                return <div key={childService.ServiceId}>
                                    <input
                                        type="checkbox"
                                        className="styled-checkbox"
                                        id={childService.ServiceId.toString()}
                                        value={childService.ServiceName}
                                        checked={this.state.checkedServices.includes(childService.ServiceId)}
                                        onChange={this.handleClick}
                                    />
                                    <label
                                        htmlFor={childService.ServiceId}
                                        className="lbl-txt">
                                        {childService.ServiceName}
                                    </label>

                                </div>
                            })}
                        </div>

                    </div>
                </div>
            </div>
        })

        return (
            <>
                <section className="provider-reg-sec">
                    <div className="container">
                        <div className="provider-reg-info">
                            <h3>Select Services</h3>
                            <p>Select the services you offer.</p>
                        </div>
                        <div className="provider-reg-wrap">
                            <div className="accordion">
                                {myServiceView}
                            </div>
                            <div className="row">
                                <div className="col-xl-12">
                                    <div className="btn-submit-wrap d-flex justify-content-end mt-4">
                                        <input type="button" className="btn-frm-submit" value="Save" onClick={this.saveData} />
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>
                </section>
            </>
        );
    }
}

export default ManageService;