import React, { Component, Fragment } from 'react';
import FooterDesktop from '../components/common/FooterDesktop';
import MainHeader from '../components/common/MainHeader';
import Purchase from "../components/Others/Purchase";
import MobileHeader from '../components/common/MobileHeader';
import Legal from '../components/common/Legal';

class PurchasePage extends Component {
    constructor(props) {
        super(props);
        this.state = {
            showMobileNavStatus: false
        }
    }

    componentDidMount() {
        window.scroll(0, 0);
        this.hideMobileNav();
    }

    showMobileNav = () => {
        this.setState({ showMobileNavStatus: true })
    }

    hideMobileNav = () => {
        this.setState({ showMobileNavStatus: false })
    }

    render() {
        return (
            <Fragment>
                
                <div className="desktop">
                    <MainHeader />
                </div>
                <div className="mobile">
                    <MobileHeader />
                </div>

                <Purchase />

                <div className="desktop">
                    <FooterDesktop />
                </div>
                
                <Legal />

            </Fragment>
        );
    }
}

export default PurchasePage;