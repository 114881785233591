import React, { Component, Fragment } from 'react';
import FooterDesktop from '../components/common/FooterDesktop';
import MainHeader from '../components/common/MainHeader';
//import MobileHeader from '../components/common/MobileHeader';
import Cancellation from '../components/Others/Cancellation';
import MobileHeader from '../components/common/MobileHeader';
import Legal from '../components/common/Legal';

class CancellationPage extends Component {
    constructor(props) {
        super(props);
        this.state = {
            showMobileNavStatus: false
        }
    }

    componentDidMount() {
        window.scroll(0, 0);
    }

    render() {
        return (
            <Fragment>
                <div className="desktop">
                    <MainHeader />
                </div>
                <div className="mobile">
                    <MobileHeader />
                </div>

                <Cancellation />

                <div className="desktop">
                    <FooterDesktop />
                </div>
                
                <Legal />
            </Fragment>
        );
    }
}

export default CancellationPage;
