import React, { Component } from 'react';
import MainHeader from '../components/common/MainHeader';
import FooterDesktop from '../components/common/FooterDesktop';
import Legal from '../components/common/Legal';
import BookingConfirmation from '../components/Booking/BookingConfirmation';
import MobileHeader from '../components/common/MobileHeader';

class BookingConfirmationPage extends Component {
    componentDidMount() {
        window.scroll(0, 0);
    }

    render() {
        return (
            <>
                <div className="desktop">
                    <MainHeader />
                </div>
                <div className="mobile">
                    <MobileHeader />
                </div>

                <BookingConfirmation />

                <div className="desktop">
                    <FooterDesktop />
                </div>

                <Legal />
            </>
        );
    }
}

export default BookingConfirmationPage;