import React, { Component } from 'react';
import ApiURL from "../../api/ApiURL";
import Slider from 'react-slick';

class Testimonial extends Component {
    constructor(props) {
        super(props);
        this.state = {
            TestimonialSliderData: []
        }
    }

    componentDidMount() {
        const testimonialSliderDataList = [
            {
                ClientImageName: "p1.jpg",
                Feedback: "It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout. It is a long established fact that a reader willbe  distracted by the readable content of a page when looking at its layout.",
                ServiceName: "Domestic Cleaning",
                ClientName: "Mark Landers",
                Rating: 5
            },
            {
                ClientImageName: "p1.jpg",
                Feedback: "I decided to go with cooks from MyBoogy and boy am I glad that I did! All the processes, starting from booking their services to receiving a confirmation call were extremely well coordinated. They reached my place on time and the session was great.I decided to go with cooks from MyBoogy and boy am I glad that I did! All the processes, starting from booking their services to receiving a confirmation call were extremely well coordinated. They reached my place on time and the session was great.",
                ServiceName: "Domestic Cooking",
                ClientName: "Abhijit Bannerjee",
                Rating: 5
            },
            {
                ClientImageName: "p1.jpg",
                Feedback: "Hello, I am Steve. I booked a driving lesson with MyBoogy and found the whole experience to be systematic and easy. I received my lesson from an experienced and qualified driving instructor. I highly recommend the services for the learners.",
                ServiceName: "Driving Instructor",
                ClientName: "Steve Smith",
                Rating: 5
            },
            {
                ClientImageName: "p1.jpg",
                Feedback: "I am employed in a job where relocation is a constant phenomenon. Oneof my friends told meabout the services from MyBoogy. My Man with Van guy arrived on time and made all of it a tad easier.",
                ServiceName: "Man with Van",
                ClientName: "James McCarthy",
                Rating: 5
            },
            {
                ClientImageName: "p1.jpg",
                Feedback: "It's my busy schedule that does not allow me to get near the kitchen every day. The lady from MyBoogy had standards. She made me a comfort meal that met my nutrition criteria and tasted nice.  She saves my day.",
                ServiceName: "Domestic Cooking",
                ClientName: "Lisa Stanley",
                Rating: 5
            },

        ];

        this.setState({ TestimonialSliderData: testimonialSliderDataList })
    }

    render() {

        const settings = {
            dots: false,
            infinite: true,
            autoplay: true,
            autoplaySpeed: 3000,
            speed: 500,
            slidesToShow: 3,
            slidesToScroll: 1,
            arrows: false,
            responsive: [
                {
                    breakpoint: 1024,
                    settings: {
                        slidesToShow: 4,
                        slidesToScroll: 1,
                        infinite: true,
                        dots: true
                    }
                },
                {
                    breakpoint: 600,
                    settings: {
                        slidesToShow: 2,
                        slidesToScroll: 1,
                        initialSlide: 2
                    }
                },
                {
                    breakpoint: 480,
                    settings: {
                        slidesToShow: 1,
                        slidesToScroll: 1
                    }
                }
            ]
        };

        const sliderList = this.state.TestimonialSliderData;
        const MyView = sliderList.length > 0 ?
            (sliderList.map((sliderItem, i) => {
                const sliderImgURL = ApiURL.ClientTestimonialImageURL + "/" + sliderItem.ClientImageName;
                return (<div className="swiper-slide" key={i}>
                    <div className="testimonial-box">
                        <div className="testi-client">
                            <div className="row">
                                <div className="col-md-4">
                                    <img src={sliderImgURL} alt="..." />
                                </div>
                                <div className="col-md-8 vertical-center">
                                    <div className="person">{sliderItem.ServiceName}</div>
                                    <div className="rating">
                                        <i className='bx bxs-star'></i>
                                        <i className='bx bxs-star'></i>
                                        <i className='bx bxs-star'></i>
                                        <i className='bx bxs-star'></i>
                                        <i className='bx bxs-star'></i>
                                    </div>

                                </div>
                            </div>
                        </div>
                        <div className="feedback">
                            <p>{
                                sliderItem.Feedback.length > 250 ?
                                    sliderItem.Feedback.slice(1, 245) + '...' :
                                    sliderItem.Feedback
                            } - <span className="person">{sliderItem.ClientName}</span>
                            </p>
                        </div>
                    </div>
                </div>
                )
            })) :
            (<div></div>)

        return (
            <>
                <section id="testimonials">
                    <div className="container">
                        <div className="testimonials-heading">
                            <h4>Client Speak</h4>
                            <h2>Testimonials</h2>
                        </div>
                        <div className="swiper slide-testimonials">
                            <Slider {...settings}>
                                {MyView}
                            </Slider>
                        </div>
                    </div>
                </section>
            </>
        );
    }
}

export default Testimonial;