import React, { Component } from 'react';
import { Link, NavLink, Redirect } from 'react-router-dom';
import ApiURL from "../../api/ApiURL";
import LocalStorageHelper from '../../LocalStorageHelper/LocalStorageHelper';

class BookingConfirmation extends Component {
    constructor(props) {
        super(props);
        this.state = {
            UserId: 0,
            BookingId: 0,
            BookingNo: '',
            RedirectToLogin: false,
        }
    }

    componentDidMount() {
        var userData = JSON.parse(LocalStorageHelper.getUser());
        if (userData !== null && userData.UserId > 0) {
            var bookingData = JSON.parse(LocalStorageHelper.getBooking());
            console.log(bookingData);
            if (bookingData !== null) {
                this.setState({
                    ...this.state,
                    UserId: userData.UserId,
                    ServiceId: bookingData.BookingId,
                    BookingNo: bookingData.BookingNo,
                })
            }
        }
        else {
            let winlocation = window.location.pathname;
            console.log(window.location.href);
            LocalStorageHelper.SetLastRedirectPath(winlocation);
            this.setState({ RedirectToLogin: true })
        }
    }

    pageRedirectToLogin = () => {
        if (this.state.RedirectToLogin === true) {
            return <Redirect to="/login" />
        }
    }


    render() {
        const bookingConfirmImgURL = ApiURL.BaseImageURL + "/tick.png";
        return (
            <>
                <section className="banner-inner">
                    <div className="container">
                        <nav aria-label="breadcrumb">
                            <ol className="breadcrumb">
                                <li className="breadcrumb-item"><NavLink to="/">Home</NavLink></li>
                                <li className="breadcrumb-item active" aria-current="page">Booking Confirmation</li>
                            </ol>
                        </nav>
                    </div>
                </section>
                <section className="odr-confrm">
                    <div className="container">
                        <div className="row">
                            <div className="odr-confrm-wrap text-center">
                                <img src={bookingConfirmImgURL} alt="Check" />
                                <h3>Booking is confirmed!</h3>
                                <h5>Your Booking Id is : {this.state.BookingNo}</h5>
                                <Link to="/">Back to Home</Link>
                            </div>
                        </div>
                    </div>
                </section>
                {this.pageRedirectToLogin()}
            </>
        );
    }
}

export default BookingConfirmation;