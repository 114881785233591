import React, { Component } from 'react';
import { NavLink } from 'react-router-dom';
import ApiURL from "../../api/ApiURL";
import axios from "axios";
import cogoToast from 'cogo-toast';
import LocalStorageHelper from '../../LocalStorageHelper/LocalStorageHelper';
import Modal from 'react-responsive-modal';
import { ModalBody, ModalHeader, ModalTitle, ModalFooter } from 'react-bootstrap';
/* import { HotTable } from '@handsontable/react';
import Handsontable from 'handsontable'; 
import 'handsontable/dist/handsontable.full.css';
import { CustomBorders } from 'handsontable/plugins';*/

class ManageServiceRate extends Component {
    //constructor lifecycle event handler
    constructor(props) {
        super(props);
        this.state = {
            btn: 'Load Service Data',
            btnUpdate: 'Save Data',
            recordsTake: 0,
            ProviderId: 0,
            ServiceMasterId: 0,
            masterServiceData: [],
            ServiceId: 0,
            ServiceName: '',
            ServiceRate: 0,
            serviceRateData: [],
            isModalOpen: false,
        };
        this.hotTable = React.createRef();
    }

    //componentDidMount lifecycle event handler
    componentDidMount() {
        var self = this;
        var userData = JSON.parse(LocalStorageHelper.getUser());
        if (userData !== null) {
            this.setState({ ProviderId: userData.UserId }, () =>
                self.GetRegisteredMasterServicesByProvider()
            )
        }
    }

    //Get Master Service List
    GetRegisteredMasterServicesByProvider = () => {
        axios.get(ApiURL.GetRegisteredMasterServicesByProvider(this.state.ProviderId)).then(response => {
            let respData = response.data;
            if (respData.StatusCode === 200) {
                let dataResult = respData.DataResult;
                //console.log(dataResult);
                this.setState({ masterServiceData: dataResult })
            }
            else if (respData.StatusCode === 500) {
                cogoToast.error("Internal Server Error ! Please Try Again.", { position: 'bottom-center' });
            }

        }).catch(error => {
            cogoToast.error("Request Failed! Please Try Again.", { position: 'bottom-center' })
        })
    }

    //Get Service List Handler
    onServiceMasterChangeHandler = (e) => {
        this.setState({ ServiceMasterId: e.target.value })
    }

    //Fetch Provider Service Slot Data
    getProviderServiceRateData = (e) => {
        //e.preventDefault();
        this.setState({ btn: "Processing.." });
        let param = {
            ProviderId: this.state.ProviderId,
            ServiceMasterId: this.state.ServiceMasterId
        };

        axios.post(ApiURL.GetServiceProviderRates, param).then(response => {
            let respData = response.data;
            if (respData.StatusCode === 200) {
                let dataResult = respData.DataResult;
                console.log(dataResult);
                this.setState({
                    ...this.state,
                    btn: "Load Service Data",
                    serviceRateData: dataResult
                })
            }
            else if (respData.StatusCode === 500) {
                this.setState({ ...this.state, btn: "Load Service Data" })
                cogoToast.error("Internal Server Error ! Please Try Again.", { position: 'bottom-center' });
            }

        }).catch(error => {
            this.setState({ ...this.state, btn: "Load Service Data" })
            cogoToast.error("Request Failed! Please Try Again.", { position: 'bottom-center' })
        })
    }

    //Save Source Data
    saveData = () => {
        var self=this;
        if (window.confirm('Do you want to save the Selected Service Rate?')) {
            var {ServiceId,ServiceName, ServiceRate}=this.state;
            /*console.log(ServiceId)
            console.log(ServiceName)
            console.log(ServiceRate) */
            self.setState({ btnUpdate: "Processing.." });

             var serviceRates = [];
             serviceRates.push({ServiceId, ServiceRate });

            let param = {
                ProviderId: self.state.ProviderId,
                ServiceRates: serviceRates
            };

            axios.post(ApiURL.UpdateServiceProviderRates, param).then(response => {
                let respData = response.data;
                if (respData.StatusCode === 200) {
                    self.setState({ 
                        ...self.state, 
                        btnUpdate: "Save Data", 
                        isModalOpen:false 
                    },(e)=>self.getProviderServiceRateData(e))
                    cogoToast.success("Service Provider rates updated successfully!", { position: 'bottom-center' });
                }
                else if (respData.StatusCode === 500) {
                    self.setState({ ...self.state, btnUpdate: "Save Data", isModalOpen:false })
                    cogoToast.error("Internal Server Error ! Please Try Again.", { position: 'bottom-center' });
                }

            }).catch(error => {
                self.setState({ ...self.state, btnUpdate: "Save Data", isModalOpen:false })
                cogoToast.error("Request Failed! Please Try Again.", { position: 'bottom-center' })
            }) 
        }
    }

    selectServiceRate = (svc) => {
        var serviceRates = this.state.serviceRateData;
        console.log('serviceRates: ', serviceRates)
        //var svc = serviceRates.filter(a => a.ServiceId = svcId)[0];
        console.log('svc: ', svc);
        if (svc !== null) {
            this.setState({
                ...this.state,
                ServiceId: svc.ServiceId,
                ServiceName: svc.ServiceName,
                ServiceRate: svc.ServiceRate,
                isModalOpen: true
            });
        }

    }

    onCloseModal = () => {
        this.setState({ isModalOpen: false });
    }

    render() {
        var self = this;
        var { masterServiceData, serviceRateData, isModalOpen } = self.state;

        const ServiceRateListView = serviceRateData.length > 0 &&
            (
                serviceRateData.map((svc, i) => {
                    return (
                        <tr key={i}>
                            <td data-th="Service Category">
                                {svc.ServiceMasterName}
                            </td>
                            <td data-th="Service Name">
                                {svc.ServiceName}
                            </td>
                            <td data-th="Updated Date">
                                {svc.UpdatedDate}
                            </td>
                            <td data-th="Service Rate (£)">
                                {svc.ServiceRate}
                            </td>
                            <td data-th="Edit">
                                <button className="btn btn-warning" onClick={() => this.selectServiceRate(svc)}>
                                    <i className="fa fa-edit" ></i>&nbsp; Edit
                                </button>
                            </td>
                        </tr>
                    )
                })
            );

        return (
            <>
                <section className="banner-inner">
                    <div className="container">
                        <nav aria-label="breadcrumb">
                            <ol className="breadcrumb">
                                <li className="breadcrumb-item"><NavLink to="/provider-home">Home</NavLink></li>
                                <li className="breadcrumb-item active" aria-current="page">Manage Service Rate</li>
                            </ol>
                        </nav>
                    </div>
                </section>
                <section id="provider-panel">
                    <div className="container-fluid">
                        <div className="heading">
                            <h3>Manage <span>Service Rate</span></h3>
                            <h6 className="section-sub-title"><span>List of Service Rate(s)</span>, you may manage</h6>
                            <br/>
                        </div>
                        <div className="row">
                            <div className="col-xl-3">&nbsp;</div>
                            <div className="col-xl-4">
                                <select
                                    name="MasterServices"
                                    className="form-control"
                                    value={this.state.ServiceMasterId}
                                    onChange={this.onServiceMasterChangeHandler}
                                >
                                    <option value="">Select Master Service</option>
                                    {
                                        (masterServiceData && masterServiceData.length > 0) && masterServiceData.map((ms) => {
                                            return (<option key={ms.ServiceMasterId} value={ms.ServiceMasterId}> {ms.ServiceMasterName}</option>);
                                        })
                                    }
                                </select>
                            </div>
                            <div className="col-xl-2">
                                <input type="button" className="btn-load-slot-data" value={this.state.btn} onClick={this.getProviderServiceRateData} />
                            </div>
                            <div className="col-xl-3">&nbsp;</div>
                        </div>
                        <div className="row mt-4">
                            <div className="col-xl-1"> &nbsp;</div>
                            <div className="col-lg-10 col-md-10 col-sm-10 center-block">

                                {
                                    (serviceRateData.length > 0) &&
                                    <table className="rwd-table">
                                        <thead>
                                            <tr>
                                                <th>Service Category</th>
                                                <th>Service Name</th>
                                                <th>Updated Date</th>
                                                <th>Service Rate (£)</th>
                                                <th>Edit</th>
                                            </tr>
										</thead>
										<tbody>
                                            {ServiceRateListView}
										</tbody>
                                    </table>
                                }

                            </div>
                            <div className="col-xl-1"> &nbsp;</div>
                        </div>

                    </div>
                </section >
                <Modal open={isModalOpen} onClose={this.onCloseModal}>
                    <ModalHeader>
                        <ModalTitle>Provider Rate Details</ModalTitle>
                    </ModalHeader>
                    <ModalBody>
                        <div className="row">
                            <div className="col-sm-12">
                                <div className="form-group">
                                    <label>Service Name</label >
                                    <input
                                        type='text'
                                        name='serviceName'
                                        className='form-control input-sm'
                                        readOnly={true}
                                        defaultValue={this.state.ServiceName}
                                    />
                                </div>
                                <div className="form-group">
                                    <label>Service Rate (<i className="fa fa-gbp"></i>)</label >
                                    <input
                                        type='text'
                                        name='serviceRate'
                                        className='form-control input-sm'
                                        style={{ textAlign: 'right' }}
                                        defaultValue={this.state.ServiceRate}
                                        onChange={(e)=>this.setState({ServiceRate: e.target.value})}
                                    />
                                </div>
                                <div className="float-end">
                                    <button
                                        type="button"
                                        className="py-2 rounded-3 w-100 btn btn-success d-flex my-4"
                                        onClick={this.saveData}>
                                        <i className="fa fa-save btn-fa-icon"></i>&nbsp;
                                        {this.state.btnUpdate}
                                    </button>
                                </div>
                            </div>
                        </div>
                    </ModalBody>
                </Modal>

            </>
        );
    }
}

export default ManageServiceRate;