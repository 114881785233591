import React, { Component } from 'react';
import { NavLink } from "react-router-dom";
import ApiURL from "../../api/ApiURL";
import axios from "axios";
import cogoToast from 'cogo-toast';
import LocalStorageHelper from '../../LocalStorageHelper/LocalStorageHelper';

class ListByCategory extends Component {

    bookService = (e, serviceId) => {
        e.preventDefault();
        var _self = this;
        var param = {
            ServiceId: serviceId,
            ApplicationType: 'W',
            UserId: 0,
            GuestId: 0,
        };

        if (JSON.parse(LocalStorageHelper.getUser()) !== null) {
            let userData = JSON.parse(LocalStorageHelper.getUser());
            if (userData.UserId > 0) {
                param.UserId = userData.UserId;
            }
            if (userData.GuestId > 0) {
                param.GuestId = userData.GuestId;
            }

        }
        else {
            var guestId = Math.floor(100000 + Math.random() * 900000);
            param.GuestId = guestId;
            let userData = {
                GuestId: guestId,
                UserId: 0
            };
            LocalStorageHelper.setUser(JSON.stringify(userData));
            _self.setState({ ..._self.state, GuestId: guestId });
        }

        this.addServiceToCart(param);
    }

    addServiceToCart = (param) => {
        axios.post(ApiURL.AddServiceItemToCart, param).then(response => {
            let respData = response.data;
            if (respData.StatusCode === 200) {
                let dataResult = respData.DataResult;
                if (dataResult === -3) {
                    cogoToast.error('Selected Service not available !', { position: 'bottom-center' });
                }
                if (dataResult === -2) {
                    cogoToast.error('Selected Service already exists into the cart !', { position: 'bottom-center' });
                }
                if (dataResult === -1) {
                    cogoToast.error('Customer Id not provided !', { position: 'bottom-center' });
                }
                else if (dataResult === 0) {
                    cogoToast.error('Selected Service could not be added into the cart !', { position: 'bottom-center' });
                }
                else if (dataResult > 0) {
                    cogoToast.success('Selected Service successfully added into the cart !', { position: 'bottom-center' });
                }
            }
            else if (respData.StatusCode === 500) {
                cogoToast.error("Internal Server Error ! Please Try Again.", { position: 'bottom-center' });
            }

        }).catch(error => {
            cogoToast.error("Request Failed! Please Try Again.", { position: 'bottom-center' })
        })
    }

    render() {

        const Category = this.props.Category;
        const ServiceDataList = this.props.ServiceData;
        const MyView = ServiceDataList.length > 0 &&
            (
                ServiceDataList.map((serviceItem, i) => {
                    const serviceImgURL = ApiURL.PopularServiceImageURL + "/" + serviceItem.ImageName;
                    return (
                        <div className="col-lg-3 col-md-6 col-sm-12" key={i}>
                            <div className="card-design">
                                <div className="card-img-box">
                                    <img src={serviceImgURL} alt="..." />
                                </div>
                                <div className="card-info">
                                    <h6>{serviceItem.ServiceName}</h6>
                                    <p>{serviceItem.Description}</p>
                                    <div className="card-footer">
                                        <NavLink
                                            to={'#'}
                                            className="small-btn"
                                            onClick={(e) => this.bookService(e, serviceItem.ServiceId)}>
                                            Book Now
                                        </NavLink>
                                    </div>
                                </div>
                            </div>
                        </div >
                    )
                })
            )
            

        return (
            <>
                <section className="banner-inner">
                    <div className="container">
                        <nav aria-label="breadcrumb">
                            <ol className="breadcrumb">
                                <li className="breadcrumb-item"><NavLink to="/">Home</NavLink></li>
                                <li className="breadcrumb-item active" aria-current="page">{Category}</li>
                            </ol>
                        </nav>
                    </div>
                </section>
                <section id="popular-services">
                    <div className="container">
                        <div className="heading">
                            <h3>Category <span>Services</span></h3>
                            {
                                (ServiceDataList.length > 0) ?
                                    <h6 className="section-sub-title">Some of our <span>{Category}</span> services, you may like</h6>
                                    :
                                    <h6 className="section-sub-title">Sorry! <span>{Category}</span> services not found.</h6>
                            }

                        </div>
                        <div className="row">
                            {MyView}
                        </div>
                    </div>
                </section>
            </>
        );
    }
}

export default ListByCategory;