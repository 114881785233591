import React, { Component } from 'react';
import { NavLink } from 'react-router-dom';
import PersonalInformation from './PersonalInformation';
import CoverageArea from './CoverageArea';
import ProviderService from './ProviderService';
import WorkPermit from './WorkPermit';
import axios from "axios";
import ApiURL from "../../api/ApiURL";
import cogoToast from "cogo-toast";
import validation from '../../validation/validation';
import SuccessRegistration from './SuccessRegistration';

class ProviderRegistration extends Component {
    constructor(props) {
        super(props);
        this.state = {
            step: 1,
            btn: "Finish",
            FirstName: '',
            LastName: '',
            Email: '',
            Mobile: '',
            AddressLine1: '',
            AddressLine2: '',
            PostalCode: '',
            SelectedPostCodeIds: [],
            SelectedServiceIds: [],
            WorkPermitFile: null,
            DBSCheckReportFile: null
        }
    }
    componentDidMount() {
        window.scroll(0, 0);
    }

    shouldComponentUpdate(nextProps, nextState) {
        // Rendering the component only if passed props value is changed

        if (nextProps.value === this.state.value) {
            return true;
        } else {
            return false;
        }
    }

    // Handle Change Personal Information
    personalInfoHandleChange = (e) => {
        this.setState({ ...this.state, [e.target.name]: e.target.value });
    }

    // Handle Change Coverage Area Code
    coverageAreaHandleChange = (covPostCodeIds) => {
        this.setState({ ...this.state, SelectedPostCodeIds: covPostCodeIds })
    }

    // Handle Change Provider Service
    serviceHandleChange = (serviceIds) => {
        this.setState({ ...this.state, SelectedServiceIds: serviceIds })
    }

    // Handle Change Work Permit
    selectFileHandleChange = (elementId, file) => {
        /* console.log(elementId);
        console.log(file); */
        if (elementId === 'file-WorkPermit') {
            this.setState({ ...this.state, WorkPermitFile: file })
        }
        else if (elementId === 'file-DBSChecked') {
            this.setState({ ...this.state, DBSCheckReportFile: file })
        }
    }

    //Handle input validation
    personalInformationValidation = () => {
        let isValid = true;
        let firstName = this.state.FirstName;
        let lastName = this.state.LastName;
        let email = this.state.Email;
        let mobileNo = this.state.Mobile;
        let addressLine1 =this.state.AddressLine1;
        let postalCode= this.state.PostalCode;

        if (firstName.length === 0) {
            cogoToast.error("First Name Required!", { position: 'bottom-center' });
            isValid = false;
        }
        else if (lastName.length === 0) {
            cogoToast.error("Last Name Required!", { position: 'bottom-center' });
            isValid = false;
        }
        else if (email.length === 0) {
            cogoToast.error("Email Required!", { position: 'bottom-center' });
            isValid = false;
        }
        else if (email.length > 0 && !(validation.EmailRegx).test(email)) {
            cogoToast.error("Invalid Email.", { position: 'bottom-center' });
            isValid = false;
        }
        else if (mobileNo.length === 0) {
            cogoToast.error("Mobile Number Required!", { position: 'bottom-center' });
            isValid = false;
        }
        else if (mobileNo.length !== 0 && !(validation.MobileRegx).test(mobileNo)) {
            cogoToast.error("Invalid Mobile No.", { position: 'bottom-center' });
            isValid = false;
        }
        else if (addressLine1.length === 0) {
            cogoToast.error("Address Line 1 Required!", { position: 'bottom-center' });
            isValid = false;
        }
        else if (postalCode.length === 0) {
            cogoToast.error("Postal Code Required!", { position: 'bottom-center' });
            isValid = false;
        }
        return isValid;
    }

    postCodeValidation = () => {
        let isValid = true;
        let selectedPostCodeIds = this.state.SelectedPostCodeIds;
        if (selectedPostCodeIds.length === 0) {
            cogoToast.error("Postal Code List Item Required!", { position: 'bottom-center' });
            isValid = false;
        }
        return isValid;
    }

    serviceValidation = () => {
        let isValid = true;
        let selectedServiceIds = this.state.SelectedServiceIds;
        if (selectedServiceIds.length === 0) {
            cogoToast.error("Service List Item Required!", { position: 'bottom-center' });
            isValid = false;
        }
        return isValid;
    }

    workPermitValidation = () => {
        let isValid = true;
        let workPermitFileName = this.state.WorkPermitFile?.name;
        let dbsCheckReportFileName = this.state.DBSCheckReportFile?.name;
        if (workPermitFileName === undefined) {
            cogoToast.error("Work Permit File Required!", { position: 'bottom-center' });
            isValid = false;
        }
        else if (dbsCheckReportFileName === undefined) {
            cogoToast.error("DBS Check Report File Required.", { position: 'bottom-center' });
            isValid = false;
        }
        return isValid;
    }

    // proceed to the next step
    nextStep = () => {
        let isValid = true;
        let { step } = this.state;
        switch (step) {
            case 1:
                isValid = this.personalInformationValidation();
                if (isValid === true) step = step + 1;
                break;
            case 2:
                isValid = this.postCodeValidation();
                if (isValid === true) step = step + 1;
                break;
            case 3:
                isValid = this.serviceValidation();
                if (isValid === true) step = step + 1;
                break;
            case 4:
                isValid = this.workPermitValidation();
                if (isValid === true) this.signUp(step);
                break;
            default: break;

        }
        this.setState({ ...this.state, step });
    }

    signUp = (step) => {
        this.setState({ btn: "Processing.." });
        let firstName = this.state.FirstName;
        let lastName = this.state.LastName;
        let email = this.state.Email;
        let mobile = this.state.Mobile;
        let addressLine1 = this.state.AddressLine1;
        let addressLine2 = this.state.AddressLine2;
        let postalCode = this.state.PostalCode;
        let selectedPostCodeIds = this.state.SelectedPostCodeIds.toString();
        let selectedServiceIds = this.state.SelectedServiceIds.toString();
        let wpFile = this.state.WorkPermitFile;
        let dbsChkRptFile = this.state.DBSCheckReportFile;
        let appType = 'W';

        const formData = new FormData()
        formData.append('FirstName', firstName);
        formData.append('LastName', lastName);
        formData.append('Email', email);
        formData.append('Mobile', mobile);
        formData.append('AddressLine1', addressLine1);
        formData.append('AddressLine2', addressLine2);
        formData.append('PostCode', postalCode);
        formData.append('CoverageAreaPostCodes', selectedPostCodeIds);
        formData.append('ServiceIds', selectedServiceIds);
        formData.append('WorkPermitFile', wpFile);
        formData.append('DBSCheckReportFile', dbsChkRptFile);
        formData.append('ApplicationType', appType);

        /* const config = {
            headers: {'content-type': 'multipart/form-data',},
        }; */

        axios.post(ApiURL.ProviderRegistration, formData).then(response => {
            let respData = response.data;
            if (respData.StatusCode === 200) {
                let dataResult = respData.DataResult;
                if (dataResult === -1) {
                    cogoToast.error('User already exists !', { position: 'bottom-center' });
                }
                else if (dataResult === 0) {
                    cogoToast.error('User could not be added !', { position: 'bottom-center' });
                }
                else if (dataResult === 1) {
                    step = step + 1;
                    this.setState({ ...this.state, step });
                }

            }
            else if (respData.StatusCode === 400) {
                this.setState({ btn: "Finish" });
                cogoToast.error(respData.Message, { position: 'bottom-center' });
            }
            else if (respData.StatusCode === 500) {
                this.setState({ btn: "Finish" });
                cogoToast.error("Internal Server Error ! Please Try Again.", { position: 'bottom-center' });
            }

        }).catch(error => {
            this.setState({ btn: "Finish" });
            cogoToast.error("Request Failed! Please Try Again.", { position: 'bottom-center' })
        })
    }


    getCurrentWizard = (currentStep) => {
        switch (currentStep) {

            case 1: return (<PersonalInformation
                nextStep={this.nextStep}
                handleChange={this.personalInfoHandleChange}
            />);
            case 2: return (<CoverageArea
                nextStep={this.nextStep}
                handleChange={this.coverageAreaHandleChange}
            />);
            case 3: return (<ProviderService
                nextStep={this.nextStep}
                handleChange={this.serviceHandleChange}
            />);
            case 4: return (<WorkPermit
                nextStep={this.nextStep}
                handleChange={this.selectFileHandleChange}
                btn={this.state.btn}
            />);
            case 5: return (<SuccessRegistration />);
            default: break;
        }
    }

    render() {
        return (
            <>
                <section className="banner-inner">
                    <div className="container">
                        <nav aria-label="breadcrumb">
                            <ol className="breadcrumb">
                                <li className="breadcrumb-item"><NavLink to="/">Home</NavLink></li>
                                <li className="breadcrumb-item active" aria-current="page">Provider Registration</li>
                            </ol>
                        </nav>
                    </div>
                </section>
                {this.getCurrentWizard(this.state.step)}

            </>
        );
    }
}

export default ProviderRegistration;