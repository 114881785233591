import React, { Component } from 'react';
import ApiURL from "../../api/ApiURL";
import axios from "axios";
import cogoToast from 'cogo-toast';
import FooterDesktop from "../../components/common/FooterDesktop";
import Legal from "../../components/common/Legal";
import MainHeader from "../../components/common/provider/MainHeader";
import ManageSlot from '../../components/ProviderPanel/ManageSlot';
import MobileHeader from '../../components/common/MobileHeader';

class ManageSlotPage extends Component {
    constructor(props) {
        super(props);
        this.state = {
            checkUserType: props.checkUserType
        }
    }
    render() {
        return (
            <>
                <div className="desktop">
                    <MainHeader checkUserType={this.state.checkUserType} />
                </div>
                <div className="mobile">
                    <MobileHeader />
                </div>


                <ManageSlot />


                <div className="desktop">
                    <FooterDesktop />
                </div>

                <Legal />
            </>
        );
    }
}

export default ManageSlotPage;