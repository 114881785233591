import React, { Component } from 'react';
import { NavLink } from 'react-router-dom';
import ApiURL from "../../api/ApiURL";
import axios from "axios";
import cogoToast from 'cogo-toast';
import LocalStorageHelper from '../../LocalStorageHelper/LocalStorageHelper';
import { HotTable } from '@handsontable/react';
import 'handsontable/dist/handsontable.full.css';

const colHeaders = ["Login Time =>", "6:00", "7:00", "8:00", "9:00", "10:00",
    "11:00", "12:00", "13:00", "14:00", "15:00", "16:00", "17:00",
    "18:00", "19:00", "20:00"];

class ManageSlot extends Component {

    //constructor lifecycle event handler
    constructor(props) {
        super(props);
        this.state = {
            btn: 'Load Slot Data',
            btnUpdate: 'Save Data',
            recordsTake: 0,
            ProviderId: 0,
            ServiceMasterId: 0,
            masterServiceData: [],
            ServiceId: 0,
            serviceData: [],
            slotData: [],
            slotColumns: [],
            columnHeaders: colHeaders,
            // headerStyle
        };
        this.hotTable = React.createRef();
    }

    //componentDidMount lifecycle event handler
    componentDidMount() {
        var self = this;
        //console.log(this.hotTable.current.hotInstance.getSourceData());
        this.GetRegisteredMasterServicesByProvider();
        var userData = JSON.parse(LocalStorageHelper.getUser());
        if (userData !== null) {
            this.setState({ ProviderId: userData.UserId }, () =>
                self.GetRegisteredMasterServicesByProvider()
            )
        }
    }

    //Get Master Service List
    GetRegisteredMasterServicesByProvider = () => {
        axios.get(ApiURL.GetRegisteredMasterServicesByProvider(this.state.ProviderId)).then(response => {
            let respData = response.data;
            if (respData.StatusCode === 200) {
                let dataResult = respData.DataResult;
                //console.log(dataResult);
                this.setState({ masterServiceData: dataResult })
            }
            else if (respData.StatusCode === 500) {
                cogoToast.error("Internal Server Error ! Please Try Again.", { position: 'bottom-center' });
            }

        }).catch(error => {
            cogoToast.error("Request Failed! Please Try Again.", { position: 'bottom-center' })
        })
    }

    //Get Service List Handler
    onServiceMasterChangeHandler = (e) => {
        this.setState({ ServiceMasterId: e.target.value })
    }

    //Fetch Provider Service Slot Data
    getProviderServiceSlotData = (e) => {
        e.preventDefault();
        this.setState({ btn: "Processing.." });
        let param = {
            ProviderId: this.state.ProviderId,
            ServiceMasterId: this.state.ServiceMasterId
        };

        axios.post(ApiURL.GetProviderServiceSlotCalendar, param).then(response => {
            let respData = response.data;
            if (respData.StatusCode === 200) {
                let dataResult = respData.DataResult;
                console.log(dataResult);
                this.setState({
                    ...this.state,
                    btn: "Load Slot Data",
                    slotColumns: dataResult.columnNames,
                    slotData: dataResult.SlotsOccupancies
                }, () => {
                    console.log("slotData", this.state.slotData);
                })
            }
            else if (respData.StatusCode === 500) {
                this.setState({ ...this.state, btn: "Load Slot Data" })
                cogoToast.error("Internal Server Error ! Please Try Again.", { position: 'bottom-center' });
            }

        }).catch(error => {
            this.setState({ ...this.state, btn: "Load Slot Data" })
            cogoToast.error("Request Failed! Please Try Again.", { position: 'bottom-center' })
        })
    }

    //Custom cell renderer
    checkbox_renderer = (instance, td, row, col, prop, value, cellProperties) => {
        var chkbox = document.createElement("INPUT");
        chkbox.setAttribute("type", "checkbox");
        chkbox.setAttribute("id", (row + "_" + prop));
        if (value) {
            chkbox.setAttribute("checked", value);
        }
        else {
            chkbox.removeAttribute("checked");
        }

        chkbox.addEventListener('change', function () {
            if (!value) {
                switch (prop) {
                    case "T_1":
                        instance.setDataAtRowProp(row, "T_1", true);
                        break;
                    case "T_2":
                        instance.setDataAtRowProp(row, "T_2", true);
                        break;
                    case "T_3":
                        instance.setDataAtRowProp(row, "T_3", true);
                        break;
                    case "T_4":
                        instance.setDataAtRowProp(row, "T_4", true);
                        break;
                    case "T_5":
                        instance.setDataAtRowProp(row, "T_5", true);
                        break;
                    case "T_6":
                        instance.setDataAtRowProp(row, "T_6", true);
                        break;
                    case "T_7":
                        instance.setDataAtRowProp(row, "T_7", true);
                        break;
                    case "T_8":
                        instance.setDataAtRowProp(row, "T_8", true);
                        break;
                    case "T_9":
                        instance.setDataAtRowProp(row, "T_9", true);
                        break;
                    case "T_10":
                        instance.setDataAtRowProp(row, "T_10", true);
                        break;
                    case "T_11":
                        instance.setDataAtRowProp(row, "T_11", true);
                        break;
                    case "T_12":
                        instance.setDataAtRowProp(row, "T_12", true);
                        break;
                    case "T_13":
                        instance.setDataAtRowProp(row, "T_13", true);
                        break;
                    case "T_14":
                        instance.setDataAtRowProp(row, "T_14", true);
                        break;
                    case "T_15":
                        instance.setDataAtRowProp(row, "T_15", true);
                        break;
                }
                chkbox.setAttribute("checked", "checked");
            }
            else {
                switch (prop) {
                    case "T_1":
                        instance.setDataAtRowProp(row, "T_1", false);
                        break;
                    case "T_2":
                        instance.setDataAtRowProp(row, "T_2", false);
                        break;
                    case "T_3":
                        instance.setDataAtRowProp(row, "T_3", false);
                        break;
                    case "T_4":
                        instance.setDataAtRowProp(row, "T_4", false);
                        break;
                    case "T_5":
                        instance.setDataAtRowProp(row, "T_5", false);
                        break;
                    case "T_6":
                        instance.setDataAtRowProp(row, "T_6", false);
                        break;
                    case "T_7":
                        instance.setDataAtRowProp(row, "T_7", false);
                        break;
                    case "T_8":
                        instance.setDataAtRowProp(row, "T_8", false);
                        break;
                    case "T_9":
                        instance.setDataAtRowProp(row, "T_9", false);
                        break;
                    case "T_10":
                        instance.setDataAtRowProp(row, "T_10", false);
                        break;
                    case "T_11":
                        instance.setDataAtRowProp(row, "T_11", false);
                        break;
                    case "T_12":
                        instance.setDataAtRowProp(row, "T_12", false);
                        break;
                    case "T_13":
                        instance.setDataAtRowProp(row, "T_13", false);
                        break;
                    case "T_14":
                        instance.setDataAtRowProp(row, "T_14", false);
                        break;
                    case "T_15":
                        instance.setDataAtRowProp(row, "T_15", false);
                        break;
                }
                chkbox.removeAttribute("checked");
            }
        })
        td.innerHTML = "";
        td.style.textAlign = 'center';
        td.appendChild(chkbox);
        return td;
    }

    //Deselect column after click on input
    doNotSelectColumn = (event, coords) => {
        if (coords.row === -1) {
            event.stopImmediatePropagation();
            this.hotTable.current.hotInstance.deselectCell();
        }
    }

    //Save Source Data
    saveData = () => {
        this.setState({ ...this.state, btnUpdate: "Updating.." });
        var hotData = this.hotTable.current.hotInstance.getSourceData();
        console.log(hotData);
        var updatedSlotDateTime = [];
        for (var i = 0; i < hotData.length; i++) {
            const dataRow = hotData[i];
            var timeSlotIds = [];
            const dynamicColumns = Object.keys(dataRow).map(key => {
                var colNameFirstChar = key.split('_')[0].toUpperCase();
                var timeSlotId = parseInt(key.split('_')[1]);
                if (colNameFirstChar === 'T' && dataRow[key] === true) {
                    timeSlotIds.push(timeSlotId);
                }
            })

            updatedSlotDateTime.push({
                SlotDate: dataRow.SlotDate,
                SlotId: timeSlotIds.toString()
            })

        } //End of for loop

        var slotRequestParam = {
            ProviderId: this.state.ProviderId,
            ServiceMasterId: this.state.ServiceMasterId,
            SlotData: updatedSlotDateTime,
            SlotTable: null
        };

        console.log(slotRequestParam);

        axios.post(ApiURL.UpdateProviderServiceSlotCalendar, slotRequestParam).then(response => {
            let respData = response.data;
            if (respData.StatusCode === 200) {
                var status = respData.DataResult;
                if (status === 1) {
                    this.setState({ ...this.state, btnUpdate: "Save Data" })
                    cogoToast.success("Success! Provider Service Slot Calendar updated.", { position: 'bottom-center' });
                }
                else {
                    this.setState({ ...this.state, btnUpdate: "Save Data" })
                    cogoToast.error("Unexpected Error ! Please Try Again.", { position: 'bottom-center' });
                }
            }
            else if (respData.StatusCode === 500) {
                this.setState({ ...this.state, btnUpdate: "Save Data" })
                cogoToast.error("Internal Server Error ! Please Try Again.", { position: 'bottom-center' });
            }

        }).catch(error => {
            this.setState({ ...this.state, btnUpdate: "Save Data" })
            cogoToast.error("Request Failed! Please Try Again.", { position: 'bottom-center' })
        })
    }

    render() {
        var self = this;
        var { masterServiceData, serviceData, slotData } = self.state;
        return (
            <>
                <section className="banner-inner">
                    <div className="container">
                        <nav aria-label="breadcrumb">
                            <ol className="breadcrumb">
                                <li className="breadcrumb-item"><NavLink to="/provider-home">Home</NavLink></li>
                                <li className="breadcrumb-item active" aria-current="page">Manage Availability</li>
                            </ol>
                        </nav>
                    </div>
                </section>
                <section id="provider-panel">
                    <div className="container-fluid">
                        <div className="heading">
                            <h3>Publish <span>Availability</span></h3>
                            <hr />
                        </div>
                        <div className="row">
                            <div className="col-xl-3"> &nbsp;</div>
                            <div className="col-xl-4">
                                <select
                                    name="MasterServices"
                                    className="form-control"
                                    value={this.state.ServiceMasterId}
                                    onChange={this.onServiceMasterChangeHandler}
                                >
                                    <option value="">Select Master Service</option>
                                    {
                                        (masterServiceData && masterServiceData.length > 0) && masterServiceData.map((ms) => {
                                            return (<option key={ms.ServiceMasterId} value={ms.ServiceMasterId}> {ms.ServiceMasterName}</option>);
                                        })
                                    }
                                </select>
                            </div>
                            <div className="col-xl-2">
                                <input type="button" className="btn-load-slot-data" value={this.state.btn} onClick={(e) => this.getProviderServiceSlotData(e)} />
                            </div>
                            <div className="col-xl-3"> &nbsp;</div>
                        </div>
                        <div className="row mt-4">
                            <div className="col-xl-1">&nbsp;</div>
                            <div className="col-lg-10 col-md-10 col-sm-10 center-block">
                                {
                                    (slotData.length > 0) &&
                                    <HotTable
                                        ref={this.hotTable}
                                        settings={{
                                            licenseKey: "non-commercial-and-evaluation", // for non-commercial use only
                                            data: this.state.slotData,
                                            rowHeaders: false,
                                            colHeaders: this.state.columnHeaders,
                                            columns: this.state.slotColumns,
                                            //columnSorting: true,
                                            filters: false,
                                            //dropdownMenu:['filter_by_value','filter_action_bar'],
                                            hiddenColumns: {
                                                columns: [1, 2],
                                                indicators: false
                                            },
                                            colWidths: [140, 1, 1, 50, 50, 50, 50, 50, 50, 50, 50, 50, 50, 50, 50, 50],
                                            fixedColumnsStart: 1,
                                            className: "htCenter",
                                            //width: "100%",
                                            //height: "300",
                                            stretchH: 'all',
                                            cells: function (row, col, prop) {
                                                var cellProperties = {};
                                                if (col > 0) {
                                                    cellProperties.renderer = self.checkbox_renderer
                                                }
                                                return cellProperties;
                                            },
                                            /* afterGetColHeader: (col, TH) => {
                                                // Apply the custom header style
                                                TH.style = this.state.headerStyle;
                                            }, */
                                            beforeOnCellMouseDown: self.doNotSelectColumn
                                        }}
                                    />
                                }


                                {/* <div class="table-responsive">
                                    <table class="table">
                                        <thead>
                                            <tr>
                                                <th scope="col">Login Time</th>
                                                <th scope="col">6:00</th>
                                                <th scope="col">7:00</th>
                                                <th scope="col">8:00</th>
                                                <th scope="col">9:00</th>
                                                <th scope="col">10:00</th>
                                                <th scope="col">11:00</th>
                                                <th scope="col">12:00</th>
                                                <th scope="col">13:00</th>
                                                <th scope="col">14:00</th>
                                                <th scope="col">15:00</th>
                                                <th scope="col">16:00</th>
                                                <th scope="col">17:00</th>
                                                <th scope="col">18:00</th>
                                                <th scope="col">19:00</th>
                                                <th scope="col">20:00</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr>
                                                <th scope="row">1</th>
                                                <td>Cell</td>
                                                <td>Cell</td>
                                                <td>Cell</td>
                                                <td>Cell</td>
                                                <td>Cell</td>
                                                <td>Cell</td>
                                                <td>Cell</td>
                                                <td>Cell</td>
                                                <td>Cell</td>
                                                <td>Cell</td>
                                                <td>Cell</td>
                                                <td>Cell</td>
                                                <td>Cell</td>
                                                <td>Cell</td>
                                                <td>Cell</td>
                                            </tr>
                                            <tr>
                                                <th scope="row">2</th>
                                                <td>Cell</td>
                                                <td>Cell</td>
                                                <td>Cell</td>
                                                <td>Cell</td>
                                                <td>Cell</td>
                                                <td>Cell</td>
                                                <td>Cell</td>
                                                <td>Cell</td>
                                                <td>Cell</td>
                                                <td>Cell</td>
                                                <td>Cell</td>
                                                <td>Cell</td>
                                                <td>Cell</td>
                                                <td>Cell</td>
                                                <td>Cell</td>
                                            </tr>
                                            <tr>
                                                <th scope="row">3</th>
                                                <td>Cell</td>
                                                <td>Cell</td>
                                                <td>Cell</td>
                                                <td>Cell</td>
                                                <td>Cell</td>
                                                <td>Cell</td>
                                                <td>Cell</td>
                                                <td>Cell</td>
                                                <td>Cell</td>
                                                <td>Cell</td>
                                                <td>Cell</td>
                                                <td>Cell</td>
                                                <td>Cell</td>
                                                <td>Cell</td>
                                                <td>Cell</td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
 */}


                            </div>
                            <div className="col-xl-1">&nbsp;</div>
                        </div>
                        <div className="row">
                            <div className="col-xl-1">&nbsp;</div>
                            <div className="col-lg-10 col-md-10 col-sm-10">
                                <div className="float-end">
                                    {
                                        (slotData.length > 0) &&
                                        <button
                                            type="button"
                                            className="py-2 rounded-3 w-100 btn btn-success d-flex my-4"
                                            onClick={this.saveData}
                                        >
                                            <i className="fa fa-save btn-fa-icon"></i>&nbsp;
                                            {this.state.btnUpdate}
                                        </button>

                                    }
                                </div>
                            </div>
                            <div className="col-xl-1"> &nbsp;</div>
                        </div>
                    </div>
                </section>

            </>
        );
    }
}

export default ManageSlot;