import React, { Component, Fragment } from 'react';
import { withRouter } from "react-router-dom";
import { Link, NavLink } from 'react-router-dom';
import 'bootstrap/dist/css/bootstrap.min.css';
import * as signalR from '@microsoft/signalr';
import axios from "axios";
import ApiURL from "../../api/ApiURL";
import cogoToast from 'cogo-toast';
import LocalStorageHelper from '../../LocalStorageHelper/LocalStorageHelper';
import MyContext from '../../contextProvider/contextProvider';

class MainHeader extends Component {
    // Access context using static contextType
    static contextType = MyContext;
    constructor(props) {
        super(props);
        let connection = new signalR.HubConnectionBuilder().withUrl(ApiURL.BaseURL + "/notificationsHub", {
            skipNegotiation: true,
            transport: signalR.HttpTransportType.WebSockets
        }).build();

        this.state = {
            UserId: '',
            GuestId: '',
            DisplayName: '',
            Mobile: '',
            Email: '',
            LoggedIn: false,
            CartListCount: 0,
            ServiceData: [],
            SearchKey: '',
            ShowSearchList: false,
            ServiceBooked: false,
            ShowSearchBar: true,
            SignalRConnection: connection
        }
    }

    componentDidMount() {
        var _self = this;

        if (window.location.href.indexOf("login") > -1) {
            _self.setState({ ..._self.state, ShowSearchBar: false })
        }
        else if (window.location.href.indexOf("verifyotp") > -1) {
            _self.setState({ ..._self.state, ShowSearchBar: false })
        }
        else if (window.location.href.indexOf("customer-reg") > -1) {
            _self.setState({ ..._self.state, ShowSearchBar: false })
        }
        else if (window.location.href.indexOf("provider-reg") > -1) {
            _self.setState({ ..._self.state, ShowSearchBar: false },
                () => console.log(_self.state.ShowSearchBar))
        }

        /* Configure signalR HubConnection */
        /* let connection = new signalR.HubConnectionBuilder().withUrl(ApiURL.BaseURL + "/notificationsHub", {
            skipNegotiation: true,
            transport: signalR.HttpTransportType.WebSockets
        }).build(); */

        let connection =this.state.SignalRConnection;        
        connection.start().then(() => console.log("connected!")).catch(err => console.log(err));
        //connection.on("Connected", () => console.log('Hub Connected...'));
        connection.on("ReceiveHubMessage", function (data, statusCode) {
            if (statusCode === "Succeeded" && parseInt(data) !== _self.state.CartListCount) {
                let listItemCount = parseInt(data);
                _self.setState({
                    ..._self.state,
                    CartListCount: listItemCount
                })
            }
        }); 

        /* Check User Authentication */
        var userData = JSON.parse(LocalStorageHelper.getUser());
        //console.log('checkUserAuth', userData);
        if (userData !== null) {
            let userLoggedInState = (userData.UserId > 0) ? true : false;
            _self.setState({
                ..._self.state,
                UserId: userData.UserId,
                GuestId: userData.GuestId,
                DisplayName: userData.DisplayName,
                Mobile: userData.Mobile,
                Email: userData.Email,
                LoggedIn: userLoggedInState
            }, () => _self.getCartListCount());
        }
    }

    componentWillUnmount(){
        let connection =this.state.SignalRConnection;
        connection.stop().then(() => console.log("Connection stopped successfully!")).catch(err => console.log(err));
        //this.setState({ ...this.state, SignalRConnection: null })        
    }

    setGuestUserId = () => {
        var _self = this;
        //guestId = JSON.parse(LocalStorageHelper.getGuestId());
        var userData = JSON.parse(LocalStorageHelper.getUser());
        console.log(userData);
        if (userData !== null) {
            let userLoggedInState = (userData.UserId > 0) ? true : false;
            _self.setState({
                ..._self.state,
                UserId: userData.UserId,
                GuestId: userData.GuestId,
                LoggedIn: userLoggedInState
            }, () => {
                console.log(_self.state);
            })
        }
    }

    signOut = () => {
        LocalStorageHelper.removeGuestId();
        LocalStorageHelper.removeUser();
        LocalStorageHelper.removeService();
        LocalStorageHelper.removeProvider();
        LocalStorageHelper.removeBooking();
        LocalStorageHelper.removeLastRedirectPath();
        this.props.history.push('/');
    }

    onChangeHandler = (e) => {
        var _self = this;
        let searchKey = e.target.value;
        setTimeout(function () {

            _self.searchService(searchKey);

        }, 500)
    }

    searchService = (searchKey) => {
        var _self = this;
        if (searchKey.length > 2) {
            _self.getServicesByDBSearch(searchKey);
        }
        else {
            _self.setState({ SearchKey: searchKey, ShowSearchList: false })
        }
    }

    getServicesByDBSearch = (searchKey) => {
        var _self = this;
        var param = {
            UserId: _self.state.UserId,
            GuestId: _self.state.GuestId,
            SearchKeyword: searchKey
        };

        axios.post(ApiURL.SearchService, param).then(response => {
            let respData = response.data;
            if (respData.StatusCode === 200) {
                let dataResult = respData.DataResult;
                this.setState({
                    ServiceData: dataResult.SearchServices,
                    ShowSearchList: true
                }, () => {
                    console.log('ServiceData :: ', this.state.ServiceData);
                })
            }
            else if (respData.StatusCode === 500) {
                cogoToast.error("Internal Server Error ! Please Try Again.", { position: 'bottom-center' });
            }

        }).catch(error => {
            cogoToast.error("Request Failed! Please Try Again.", { position: 'bottom-center' })
        })
    }

    bookService = (e, serviceId) => {
        e.preventDefault();
        var _self = this;
        var param = {
            ServiceId: serviceId,
            ApplicationType: 'W',
            UserId: 0,
            GuestId: 0,
        };

        if (JSON.parse(LocalStorageHelper.getUser()) !== null) {
            let userData = JSON.parse(LocalStorageHelper.getUser());
            if (userData.UserId > 0) {
                param.UserId = userData.UserId;
            }
            if (userData.GuestId > 0) {
                param.GuestId = userData.GuestId;
            }

        }
        else {
            var guestId = Math.floor(100000 + Math.random() * 900000);
            param.GuestId = guestId;
            let userData = {
                GuestId: guestId,
                UserId: 0
            };
            LocalStorageHelper.setUser(JSON.stringify(userData));
            _self.setState({ ..._self.state, GuestId: guestId });
        }

        this.addServiceToCart(param);
    }

    addServiceToCart = (param) => {
        var _self = this;
        axios.post(ApiURL.AddServiceItemToCart, param).then(response => {
            let respData = response.data;
            if (respData.StatusCode === 200) {
                let dataResult = respData.DataResult;
                if (dataResult === -3) {
                    cogoToast.error('Selected Service not available !', { position: 'bottom-center' });
                }
                if (dataResult === -2) {
                    cogoToast.error('Selected Service already exists into the cart !', { position: 'bottom-center' });
                }
                if (dataResult === -1) {
                    cogoToast.error('Customer Id not provided !', { position: 'bottom-center' });
                }
                else if (dataResult === 0) {
                    cogoToast.error('Selected Service could not be added into the cart !', { position: 'bottom-center' });
                }
                else if (dataResult > 0) {
                    cogoToast.success('Selected Service successfully added into the cart !', { position: 'bottom-center' });
                    //_self.getCartListCount();
                    //_self.selectAddedService(param.ServiceId);
                }
            }
            else if (respData.StatusCode === 500) {
                cogoToast.error("Internal Server Error ! Please Try Again.", { position: 'bottom-center' });
            }

        }).catch(error => {
            cogoToast.error("Request Failed! Please Try Again.", { position: 'bottom-center' })
        })

    }

    getCartListCount = () => {
        var _self = this;
        var param = {
            UserId: _self.state.UserId,
            GuestId: _self.state.GuestId,
        };
        axios.post(ApiURL.GetCartListCount, param).then(response => {
            let respData = response.data;
            if (respData.StatusCode === 200) {
                _self.setState({ ..._self.state, CartListCount: respData.DataResult });
            }
            else {
                cogoToast.error("getCartListCount Request Failed! Try Again", { position: 'bottom-center' });
            }
        }).catch(error => {
            cogoToast.error("getCartListCount Catch Request Failed! Try Again :: " + error, { position: 'bottom-center' })
        });
    }

    selectAddedService = (serviceId) => {
        var serviceDataList = this.state.ServiceData;
        var svcData = serviceDataList.filter((item) => item.ServiceId === serviceId)[0];
        if (svcData !== null) {
            svcData.IsSelected = true;
        }
        this.setState({
            ...this.state,
            ServiceBooked: true,
            ServiceData: serviceDataList
        });
    }


    render() {

        var userLoggedInSection = <div></div>
        userLoggedInSection = (this.state.LoggedIn === true) ?
            (
                <div className="btn-group">
                    <button className="btn  btn-sm dropdown-toggle" type="button" data-bs-toggle="dropdown" aria-expanded="false" title="User Login Box">
                        <i className="fa fa-user-o"></i>
                    </button>
                    <ul className="dropdown-menu dropdown-main">
                        <span className="account-manage">This account is manage by MyBoogy</span>
                        <div className="drop-user-info d-flex align-items-center">
                            <div className="user-image-wrap">
                                <span><i className="fa fa-user-o"></i></span>
                            </div>
                            <div className="user-identity-wrap">
                                <h5>{this.state.DisplayName}</h5>
                                <h6>{this.state.Mobile}</h6>
                                <h6>{this.state.Email}</h6>
                            </div>
                        </div>
                        <ul className="list-group user-drop-list">
                            <li className="list-group-item"><i className="fa fa-cog"></i> <Link to={"/"} className="manage-txt">Manage your profile</Link></li>
                            <li className="list-group-item"><i className="fa fa-cog"></i> <Link to={"/booking-history"} className="manage-txt">Booking History</Link></li>
                            <li className="list-group-item bdr-none"><i className="fa fa-sign-out" ></i> <Link to={"/"} onClick={this.signOut}>Sign out</Link></li>
                        </ul>
                    </ul>
                </div>
            ) : <div className="login-btn"><NavLink to="/login">Login</NavLink></div>


        /* Search Service Data */
        const serviceDataList = this.state.ServiceData;
        const SearchServiceView = serviceDataList.length > 0 ?
            (
                serviceDataList.map((svc, i) => {
                    return (
                        <div key={svc.ServiceId}>
                            {
                                (i === 0) &&
                                <>
                                    <h6>Showing Search Results</h6>
                                    <h6 className="clear-all">Clear All</h6>
                                </>
                            }

                            <div key={svc.ServiceId} className="search-reasult-wrap d-flex">
                                <div className="search-reasult-service d-flex">
                                    <h4>{svc.ServiceName}</h4>
                                </div>
                                {
                                    (svc.IsSelected)
                                        ? <Link to={"#"} className="search-service-btn btn_selected">
                                            <i className="fa fa-check"></i> Selected
                                        </Link>
                                        : <Link to={"#"}
                                            className="search-service-btn"
                                            onClick={(e) => this.bookService(e, svc.ServiceId)}>
                                            Book Now
                                        </Link>
                                }

                            </div>
                        </div>
                    )
                })
            ) :
            (<div>
                <h6>No Result(s) Found!</h6>
            </div>);

        return (
            <Fragment>
                <header>
                    <div className="custom-navbar">
                        <div className="brand"><NavLink to="/">MyBoogy</NavLink></div>
                        {
                            (this.state.ShowSearchBar === true) &&
                            <div className="main-search-form">
                                <div className="form-wrapper">
                                    <input type="text" className="search-service"
                                        placeholder="Search your service"
                                        onKeyUp={this.onChangeHandler} />
                                    <button className="search-btn" title="Search"><i className='bx bx-search'></i></button>
                                    <div className={(this.state.ShowSearchList === true) ?
                                        "search-holder-wrap" :
                                        "hide-search-holder-wrap"}
                                    >
                                        {SearchServiceView}
                                    </div>

                                </div>
                            </div>
                        }

                        <div className="nav-link-btn">
                            <div className="join-as"><Link to={"/provider-reg"}>Join as a Professional</Link></div>
                            {userLoggedInSection}
                            <div className="login-btn">
                                <Link to={"/wishlist"}>
                                    <i className="fa fa-shopping-cart"></i>
                                    {
                                        (this.state.CartListCount > 0) &&
                                        <span>{this.state.CartListCount}</span>
                                    }
                                </Link>
                            </div>
                        </div>
                        <div className="nav-btn"><i className='bx bx-menu-alt-right' ></i></div>

                    </div>
                </header>
            </Fragment >
        );
    }
}

export default withRouter(MainHeader);
