import React, { Component } from 'react';
import ApiURL from "../../api/ApiURL";
import { NavLink, Redirect } from 'react-router-dom';
import axios from "axios";
import cogoToast from 'cogo-toast';
import LocalStorageHelper from '../../LocalStorageHelper/LocalStorageHelper';

class BestOffer extends Component {
    constructor(props) {
        super(props);
        this.state = {
            GuestId: '',
            LoggedIn: false,
            CartListCount: 0,
            ServiceData: [],
            ServiceBooked: false,
            PageRefreshStatus: false
        }
    }
    

    bookService = (e, serviceId) => {
        e.preventDefault();
        var _self = this;
        var param = {
            ServiceId: serviceId,
            ApplicationType: 'W',
            UserId: 0,
            GuestId: 0,
        };

        if (JSON.parse(LocalStorageHelper.getUser()) !== null) {
            let userData = JSON.parse(LocalStorageHelper.getUser());
            if (userData.UserId > 0) {
                param.UserId = userData.UserId;
            }
            if (userData.GuestId > 0) {
                param.GuestId = userData.GuestId;
            }

        }
        else {
            var guestId = Math.floor(100000 + Math.random() * 900000);
            param.GuestId = guestId;
            let userData = {
                GuestId: guestId,
                UserId: 0
            };
            LocalStorageHelper.setUser(JSON.stringify(userData));
            _self.setState({ ..._self.state, GuestId: guestId });
        }

        this.addServiceToCart(param);
    }

    addServiceToCart = (param) => {
        axios.post(ApiURL.AddServiceItemToCart, param).then(response => {
            let respData = response.data;
            if (respData.StatusCode === 200) {
                let dataResult = respData.DataResult;
                if (dataResult === -3) {
                    cogoToast.error('Selected Service not available !', { position: 'bottom-center' });
                }
                if (dataResult === -2) {
                    cogoToast.error('Selected Service already exists into the cart !', { position: 'bottom-center' });
                }
                if (dataResult === -1) {
                    cogoToast.error('Customer Id not provided !', { position: 'bottom-center' });
                }
                else if (dataResult === 0) {
                    cogoToast.error('Selected Service could not be added into the cart !', { position: 'bottom-center' });
                }
                else if (dataResult > 0) {
                    cogoToast.success('Selected Service successfully added into the cart !', { position: 'bottom-center' });
                }
            }
            else if (respData.StatusCode === 500) {
                cogoToast.error("Internal Server Error ! Please Try Again.", { position: 'bottom-center' });
            }

        }).catch(error => {
            cogoToast.error("Request Failed! Please Try Again.", { position: 'bottom-center' })
        })

    }    
    
    pageRefresh = () => {
        if (this.state.PageRefreshStatus === true) {
            let winlocation = window.location.pathname;
            return <Redirect to={winlocation} />
        }
    }

    render() {

        const bestOfferDataList = this.props.BestOffers;
        const MyView = bestOfferDataList.length > 0 ?
            (
                bestOfferDataList.map((offerItem, i) => {
                    const offerImgURL = ApiURL.BsetOfferImageURL + "/" + offerItem.ImageName;
                    return (
                        <div className="col-lg-3 col-md-6 col-sm-12 p-2" key={offerItem.ServiceId}>
                            <div className="card-design">
                                <div className="card-img-box">
                                    <img src={offerImgURL} alt="..." />
                                </div>
                                <div className="card-info">
                                    <h6>{offerItem.ServiceName}</h6>
                                    <p>{offerItem.Description}</p>
                                    <div className="card-footer">
                                        <NavLink
                                            to={'#'}
                                            className="small-btn"
                                            onClick={(e) => this.bookService(e, offerItem.ServiceId)}>
                                            Book Now
                                        </NavLink>
                                    </div>
                                </div>
                            </div>
                        </div>
                    )
                })
            ) :
            (<div></div>)

        return (
            <>
                <section id="offer">
                    <div className="container">
                        <div className="heading">
                            <h3>Best <span>Offers</span></h3>
                        </div>
                        <div className="row">
                            {MyView}
                        </div>
                    </div>
                </section>
                {this.pageRefresh()}
            </>
        );
    }
}

export default BestOffer;