import React from "react";
import ReactDOM from "react-dom";
import App from "./App";
import axios from "axios";
import "./assets/css/style.css";
import "./assets/css/mobile-nav.css";
import "./assets/css/responsive.css";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import * as serviceWorker from "./serviceWorker";
import LocalStorageHelper from "./LocalStorageHelper/LocalStorageHelper";


axios.interceptors.request.use((request) => {
  const token = LocalStorageHelper.getAccessToken();
  if (token) {
      request.headers['Authorization'] = 'Bearer ' + token;
  }
  // config.headers['Content-Type'] = 'application/json';
  return request;
},
(error) => {
  Promise.reject(error);
});

axios.interceptors.response.use((response) => {
  return response;
},
(error) => {
  if (error.response.status === 401 || error.response.status === 403) {
      console.log(error);
      window.location.href = "/unauthorize";
      return Promise.reject(error);
  }
})

ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.register();
