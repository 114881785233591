import React, { Component } from 'react';
import { Link, NavLink } from 'react-router-dom';
import ApiURL from '../../api/ApiURL';
import axios from "axios";
import cogoToast from 'cogo-toast';

class FooterDesktop extends Component {
    constructor(props) {
        super(props);
        this.state = {
            recordsTake: 8,
            MasterServiceData: [],
        }
    }
    componentDidMount() {
        //this.getMasterServiceList();
    }

    getMasterServiceList = () => {
        axios.get(ApiURL.GetMasterServiceList(this.state.recordsTake)).then(response => {
            let respData = response.data;
            if (respData.StatusCode === 200) {
                let dataResult = respData.DataResult;
                //console.log(dataResult);
                this.setState({ MasterServiceData: dataResult })
            }
            else if (respData.StatusCode === 500) {
                cogoToast.error("Internal Server Error ! Please Try Again.", { position: 'bottom-center' });
            }

        }).catch(error => {
            cogoToast.error("Request Failed! Please Try Again.", { position: 'bottom-center' })
        })
    }

    fbLinkClick = (e) => {
        e.preventDefault();
        var url = 'https://www.facebook.com/profile.php?id=100090982122591';
        window.open(url, '_blank');

    }

    render() {

        const scrollTopImgURL = ApiURL.BaseImageURL + "/scroll-top.png";
        const masterServiceList = this.state.MasterServiceData;
        const MasterServiceView = masterServiceList.length > 0 ?
            masterServiceList.map((item, i) => {
                return <li key={i}>
                    <Link to={"/service-by-category/" + item.ServiceMasterName}>
                        <i className='bx bx-chevron-right'></i>
                        {item.ServiceMasterName}
                    </Link>
                </li>
            }) :
            (<li><span>...</span></li>);


        return (
            <>
                <footer>
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-4 col-md-6 col-sm-12 footer-items">
                                <div className="footer-brand"><Link to="/">MyBoogy</Link></div>
                                <div className="about-company">
                                    <p>Connect with the service providers near you. MyBoogy is a safe environment for both home service providers and customers.</p>

                                    <form action="">
                                        <div className="form-area">
                                            <input type="email" placeholder="Email Address" />
                                            <button className="send-btn">Send</button>
                                        </div>
                                    </form>
                                </div>
                            </div>
                            <div className="col-lg-2 col-md-6 col-sm-12 footer-items">
                                <h4>Quick Links</h4>
                                <ul className="footer-list">
                                    <li><NavLink to="/about">About Us</NavLink></li>
                                    <li><NavLink to="/contact">Contact Us</NavLink></li>
                                    {/* <li><NavLink to="/customer-reg">Customer Signup</NavLink></li> */}
                                    <li><NavLink to="/btc">Terms & Conditions</NavLink></li>
                                </ul>
                            </div>
                            <div className="col-lg-2 col-md-6 col-sm-12 footer-items">
                                <h4>Quick Links</h4>
                                <ul className="footer-list">
                                    <li><NavLink to="/policy">Privacy Policy</NavLink></li>
                                    <li><NavLink to="/cookies-policy">Cookies Policy</NavLink></li>
                                    <li><NavLink to="/cancellation">Cancellation Policy</NavLink></li>
                                    <li><NavLink to="/">Advice for Providers</NavLink></li>
                                </ul>
                            </div>
                            <div className="col-lg-4 col-md-6 col-sm-12 footer-items">
                                <h4>Contact us</h4>
                                <ul className="footer-contact">
                                    <li><i className='bx bx-phone-call'></i> <Link to="tel:1800123456">+44 2045421750</Link></li>
                                    <li><i className="fa fa-envelope-o"></i> <Link to="mailto:contact-us@myboogy.co.uk">contact-us@myboogy.co.uk</Link> </li>
                                    <li><i className='bx bx-map'></i> 200 Brook Drive, Green Park, Reading, UK, RG2 6UB</li>
                                </ul>
                                <div className="social-site">
                                    <NavLink to={"#"} onClick={this.fbLinkClick}><i className='bx bxl-facebook'></i></NavLink>
                                    <NavLink to={"#"}><i className='bx bxl-twitter'></i></NavLink>
                                    <NavLink to={"#"}><i className='bx bxl-instagram'></i></NavLink>
                                </div>
                                <div className="scroll-top"><img src={scrollTopImgURL} alt="Scroll Top" /></div>

                            </div>
                        </div>
                    </div>
                </footer>

            </>
        );
    }
}

export default FooterDesktop;