import React, { Component } from 'react';
import { NavLink } from 'react-router-dom';
class About extends Component {
    render() {
        return (
            <>
                <section className="banner-inner">
                    <div className="container">
                        <nav aria-label="breadcrumb">
                            <ol className="breadcrumb">
                                <li className="breadcrumb-item"><NavLink to="/">Home</NavLink></li>
                                <li className="breadcrumb-item active" aria-current="page">About</li>
                            </ol>
                        </nav>
                    </div>
                </section>
                <section className="odr-confrm">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12 col-md-12 col-sm-12">
                                <div className="odr-confrm-wrap">
                                    <div style={{ marginBottom: "20px" }}>
                                        <div>
                                            <center>
                                                <h3><strong>About Us</strong></h3>
                                            </center>
                                            <p>
                                                We understand the crucial importance of reliable services in catering to a wide range of needs. At MyBoogy, our primary mission is to promptly address and fulfill your requirements. Whether your request is minor or complex, we are dedicated to delivering top-notch service and exceeding your expectations. From the simplest tasks to the most demanding errands, count on us for efficient and punctual solutions.
                                                Our mobility is accompanied by a diverse team of professionals, including Domestic Cooks, Household Organizers, Home Cleaners, Man with Van, and Driving Instructors. What truly sets MyBoogy apart is our commitment to a systematic approach. We aim to create an organized environment that ensures your needs are met with precision and care. So, if you have errands that feel overwhelming or simply lack the time to handle them yourself, look no further – we are here to fetch and facilitate your requests. Don't hesitate; let us make your life easier today!
                                            </p>
                                        </div>
                                    </div>
                                    <div style={{ marginBottom: "20px" }}>
                                        <h5><strong>Our Mission:</strong></h5>
                                        <p>Human Resources is immensely powerful. Our mission is to bring immensely powerful human resources under a flagship to offer the best service there is. </p>
                                    </div>

                                    <div style={{ marginBottom: "20px" }}>
                                        <h5><strong>Our Vision:</strong></h5>
                                        <p>In today's date, the service industry is still unorganised. We empower the service providers and customers with technology to maintain transparent and efficient services.</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </>
        );
    }
}

export default About;
