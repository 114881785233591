import React, { Component } from 'react';

class WorkPermit extends Component {
    constructor(props) {
        super(props);
        this.state = {           
            CheckedWorkPermit: false,
            DBSChecked: false,            
        }
    }

    onChangeHandler = e => {
        let { id, checked } = e.target;
        if (id === "WorkPermit") {
            if (!checked) {
                document.getElementById("file-WorkPermit").value = "";
                this.props.handleChange("file-WorkPermit", null);
            }
            this.setState({ ...this.state, CheckedWorkPermit: checked })
        }
        else if (id === "DBSChecked") {
            if (!checked) {
                document.getElementById("file-DBSChecked").value = "";
                this.props.handleChange("file-DBSChecked", null);
            }            
            this.setState({ ...this.state, DBSChecked: checked })
        }

    }

    onSelectFileHandler = (e) => {
        let fileId=e.target.id;
        let file=e.target.files[0];
        this.props.handleChange(fileId, file);
    }

    render() {
        return (
            <>
                <section className="provider-reg-sec">
                    <div className="container">
                        <div className="provider-reg-info">
                            <h3>Upload Documents</h3>
                            <p>This would be changed to DBS checked `yes` or `no`. When the user selects `yes` then upload file option will be there.</p>
                        </div>
                        <div className="provider-reg-wrap">
                            <div className="row">
                                <div className="col-xl-12">
                                    <div className="custom-control custom-checkbox">
                                        <input className="styled-checkbox-w" id="WorkPermit" type="checkbox" onChange={this.onChangeHandler} />
                                        <label htmlFor="WorkPermit" className="text-white">I have the UK work permit</label>
                                    </div>
                                    <div className="file-uploder-wrap">
                                        <input
                                            type="file"
                                            id="file-WorkPermit"
                                            className="file-lbl"
                                            disabled={!this.state.CheckedWorkPermit ? "disabled" : ""}
                                            accept="image/*,.pdf"
                                            onChange={this.onSelectFileHandler}
                                        />

                                    </div>
                                </div>
                                <div className="col-xl-12">
                                    <div className="custom-control custom-checkbox">
                                        <input className="styled-checkbox-w" id="DBSChecked" type="checkbox" onChange={this.onChangeHandler} />
                                        <label htmlFor="DBSChecked" className="text-white">DBS Checked</label>
                                    </div>
                                    <div className="file-uploder-wrap">
                                        <input
                                            type="file"
                                            id="file-DBSChecked"
                                            className="file-lbl"
                                            disabled={!this.state.DBSChecked ? "disabled" : ""}
                                            accept="image/*,.pdf"
                                            onChange={this.onSelectFileHandler}
                                        />
                                    </div>
                                </div>
                                
                                <div className="col-xl-12">
                                    <div className="btn-submit-wrap d-flex justify-content-end">
                                        <input type="button" className="btn-frm-submit" value={this.props.btn} onClick={this.props.nextStep} />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </>
        );
    }
}

export default WorkPermit;