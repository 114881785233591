import React, { Component } from 'react';
import ApiURL from "../../api/ApiURL";
import { Link } from "react-router-dom";

class ServicesWeOffer extends Component {

    componentDidMount() {
        /* const serviceDataList = [
            { ImageName: "service-icon.png", Title: "Cleaning Services 1" },
            { ImageName: "service-icon.png", Title: "Cleaning Services 2" },
            { ImageName: "service-icon.png", Title: "Cleaning Services 3" },
            { ImageName: "service-icon.png", Title: "Cleaning Services 4" },
            { ImageName: "service-icon.png", Title: "Cleaning Services 5" },
            { ImageName: "service-icon.png", Title: "Cleaning Services 6" },
            { ImageName: "service-icon.png", Title: "Cleaning Services 7" },
            { ImageName: "service-icon.png", Title: "Cleaning Services 8" }
        ];

        this.setState({ HomeServiceData: serviceDataList }) */
    }

    render() {

        const serviceDataList = this.props.ServicesWeOffer;
        const MyView = serviceDataList.length > 0 ?
            (
                serviceDataList.map((item, i) => {
                    const serviceImgURL = ApiURL.HomeServiceImageURL + "/" + item.ImageName;
                    return (<div className="col-lg-3 col-md-3 col-sm-6 col-6" key={item.ServiceMasterId}>
                        <Link to={"service-by-category/" + item.ServiceMasterName}>
                            <div className="service-item">
                                <div className="service-icon"><img src={serviceImgURL} alt="..." /></div>
                                <div className="service-title"><h6>{item.ServiceMasterName}</h6></div>
                            </div>
                        </Link>
                    </div>)
                })
            ) :
            (<div></div>)

        return (
            <>
                <section id="services">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-4 col-md-12">
                                <div className="services-info">
                                    <h3>Services</h3>
                                    <h2>We Offer</h2>
                                    <p>You can choose from the domestic services you need. We have cooks, cleaners and driving instructors in our network.</p>
                                </div>
                            </div>

                            <div className="col-lg-8 col-md-12 col-sm-6">
                                <div className="row all-services">
                                    {MyView}
                                </div>
                            </div>

                        </div>
                    </div>
                </section>
            </>
        );
    }
}

export default ServicesWeOffer;