import React, { Component } from 'react';
import { Link, NavLink, Redirect } from 'react-router-dom';
import * as signalR from '@microsoft/signalr';
import axios from "axios";
import ApiURL from "../../api/ApiURL";
import cogoToast from 'cogo-toast';
import LocalStorageHelper from '../../LocalStorageHelper/LocalStorageHelper';

class Cart extends Component {
    constructor(props) {
        super(props);
        let connection = new signalR.HubConnectionBuilder().withUrl(ApiURL.BaseURL + "/notificationsHub", {
            skipNegotiation: true,
            transport: signalR.HttpTransportType.WebSockets
        }).build();

        this.state = {
            //CartServiceData: [],
            //CartListCount: 0,
            RedirectToLogin: false,
            RedirectToDeliveryAddress: false,
            PageRefreshStatus: false,
            SignalRConnection: connection
        }        
    }

    componentDidMount() {
        var _self = this;

        /* Get signalR HubConnection */
        let connection =this.state.SignalRConnection;
        connection.start().then(() => console.log("connected!")).catch(err => console.log(err));
        //connection.on("Connected", () => console.log('Hub Connected...'));
        connection.on("ReceiveHubMessage", function (data, statusCode) {
             if (statusCode === "Succeeded" && data !== _self.props.CartListCount) {
               _self.props.getCartDetails();                
            } 
        });
    }

    componentWillUnmount(){
        let connection =this.state.SignalRConnection;
        connection.stop().then(() => console.log("Connection stopped successfully!")).catch(err => console.log(err));
        //this.setState({ ...this.state, SignalRConnection: null })
    }

    removeService = (e, svc) => {
        e.preventDefault();
        if (window.confirm("Are you sure to remove the selected service from wishlist?")) {
            var userData = JSON.parse(LocalStorageHelper.getUser());
            console.log(userData);
            if (userData !== null) {
                var param = {
                    UserId: userData.UserId,
                    GuestId: userData.GuestId,
                    ServiceId: svc.ServiceId
                };

                axios.post(ApiURL.RemoveCartItem, param).then(response => {
                    let respData = response.data;
                    if (respData.StatusCode === 200) {
                        var dataResult = respData.DataResult;
                        console.log(dataResult);
                        if (dataResult.RemoveItemStatus === 1) {
                            cogoToast.success("Selected Cart Service item deleted !", { position: 'bottom-center' });
                        }
                        else {
                            cogoToast.error("removeService() Request Failed! Please Try Again.", { position: 'bottom-center' })
                        }
                    }
                    else if (respData.StatusCode === 500) {
                        cogoToast.error("Internal Server Error ! Please Try Again.", { position: 'bottom-center' });
                    }

                }).catch(error => {
                    cogoToast.error("removeService() Request Failed! Please Try Again.", { position: 'bottom-center' })
                })
            }
        }
    }

    redirectToDeliveryAddressPage = (e, svc) => {
        e.preventDefault();
        var userData = JSON.parse(LocalStorageHelper.getUser());
        if (userData !== null && userData.UserId > 0) {
            let svcData = {
                ServiceId: svc.ServiceId,
                ServiceName: svc.ServiceName
            }
            LocalStorageHelper.setService(JSON.stringify(svcData));
            this.setState({ RedirectToDeliveryAddress: true })
        }
        else {
            let winlocation = window.location.pathname;
            console.log(window.location.href);
            LocalStorageHelper.SetLastRedirectPath(winlocation);
            this.setState({ RedirectToLogin: true })
        }
    }

    pageRedirectToDeliveryAddress = () => {
        if (this.state.RedirectToDeliveryAddress === true) {
            return (
                <Redirect to="/service-delilvery-address" />
            )
        }
    }

    pageRedirectToLogin = () => {
        if (this.state.RedirectToLogin === true) {
            return <Redirect to="/login" />
        }
    }

    pageRefresh = () => {
        if (this.state.PageRefreshStatus === true) {
            let winlocation = window.location.pathname;
            return <Redirect to={winlocation} />
        }
    }

    render() {

        const serviceDataList = this.props.CartServiceData;
        const CartServiceView = serviceDataList.length > 0 &&
            (
                serviceDataList.map((svc, i) => {
                    return (
                        <div key={svc.ServiceId} className="sub-survices-wrap d-flex">
                            <div className="sub-survices-con-wrap d-flex">
                                <span className="icn-circle"><i className="fa fa-check"></i></span>
                                <div className="sub-survices-txt-wrap">
                                    <h6>{svc.ServiceName}</h6>
                                    <p>{svc.ServiceDescription}</p>
                                </div>
                            </div>
                            <div className="sub-survices-btn-wrap">

                                <button type="button" className="btn btn-success btn-sm mr-2" onClick={(e) => this.redirectToDeliveryAddressPage(e, svc)}>
                                    <i className="fa fa-star"></i> Manage Booking
                                </button>
                                <button type="button" className="btn btn-success btn-sm" onClick={(e) => this.removeService(e, svc)}>
                                    <i className="fa fa-trash"></i> Remove
                                </button>                                
                            </div>
                        </div>
                    )
                })
            );



        return (
            <>
                <section className="banner-inner">
                    <div className="container">
                        <nav aria-label="breadcrumb">
                            <ol className="breadcrumb">
                                <li className="breadcrumb-item"><NavLink to="/">Home</NavLink></li>
                                <li className="breadcrumb-item active" aria-current="page">Wishlist</li>
                            </ol>
                        </nav>
                    </div>
                </section>

                <section id="wish-list">
                    <div className="container">
                        <div className="heading">
                            <h3>Wishlist <span>Services</span></h3>
                            {
                                (this.props.CartServiceData.length > 0) ?
                                    <h6 className="section-sub-title"><span>List of wishlist services</span>, you may manage for booking</h6>
                                    :
                                    <h6 className="section-sub-title"><span>Sorry! List is empty.</span> service(s) not found.</h6>
                            }
                        </div>
                    </div>
                </section>


                <section className="sub-survices">
                    <div className="container">
                        <div className="sub-box mb-3">
                            <div className="row">
                                <div className="col-xl-12 col-lg-12">

                                    {CartServiceView}

                                </div>
                                <div className="col-xl-12 col-lg-12"></div>
                            </div>

                        </div>
                    </div>
                </section>
                {this.pageRedirectToDeliveryAddress()}
                {this.pageRedirectToLogin()}
                {this.pageRefresh()}
            </>
        );
    }
}

export default Cart;